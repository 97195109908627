import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormSwitch from '@material-ui/core/Switch';

import { connect } from 'react-redux';

import SideNavigation from '../../components/SideNavigation';

import ProductCategoryList from './categories';
import ProductFactoryList from './factories';
import CategoryForm from './category';

class ProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.parseData(props.data);
  }
  where = null;

  componentWillReceiveProps({ data, productCategories: { action: pc } }) {
    if (data !== this.props.data) {
      this.where = data ? { productId: data.id } : null;
      this.setState(this.parseData(data));
    }
    if (pc === 'CREATED' || pc === 'UPDATED') {
      this.handleClose();
    }
  }

  parseData = data=>{
    const { name = '', shortName = '', price = '', active = true } = data || {};
    return {
      name,
      shortName,
      price,
      active
    };
  };

  handleChange = event=>{
    const { name, value, checked, type } = event.target;
    this.setState({
      [name]: type === 'checkbox' ? checked : value
    });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { onSubmit } = this.props;
    onSubmit(this.state);
  };

  handleClose = ()=>{
    const { history, match } = this.props;
    history.push(match.url);
  };

  render() {
    const { classes, loading, match } = this.props;
    const { name, shortName, price, active } = this.state;

    return (
      <Fragment>
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={12} md={8} lg={6} xl={4}>
              <TextField
                label="Nome"
                name="name"
                value={name}
                onChange={this.handleChange}
                disabled={loading}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Abreviação"
                name="shortName"
                value={shortName}
                onChange={this.handleChange}
                disabled={loading}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Preço"
                value={price}
                onChange={this.handleChange}
                name="price"
                type="number"
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <FormControlLabel
                control={
                  <FormSwitch
                    checked={active}
                    name="active"
                    onChange={this.handleChange}
                    disabled={loading}
                  />
                }
                label="Ativo"
              />
            </Grid>
          </Grid>

          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              type="submit"
            >
              Salvar
            </Button>
          </div>
        </form>

        <ProductCategoryList where={this.where} match={match}>
          <Divider className={classes.divider} />
        </ProductCategoryList>

        <ProductFactoryList where={this.where}>
          <Divider className={classes.divider} />
        </ProductFactoryList>

        <SideNavigation open={!match.isExact} onClose={this.handleClose}>
          <Switch>
            <Route path={`${match.path}/categorias/:categoryId`} component={CategoryForm} />
          </Switch>
        </SideNavigation>
      </Fragment>
    );
  }
};

const mapStateToProps = ({ productCategories })=>{
  return { productCategories };
};

export default connect(mapStateToProps)(ProductForm);