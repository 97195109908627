export const invalidateVariables = item=>({
  type: "INVALIDATE_VARIABLES",
  payload: { item }
});

export const getVariables = (offset, limit, filter = {}, params)=>({
  type: "GET_VARIABLES",
  payload: { offset, limit, where: filter, params }
});

export const getVariablesSuccess = data=>({
  type: "GET_VARIABLES_SUCCESS",
  payload: { data }
});

export const getVariablesFailed = error=>({
  type: "GET_VARIABLES_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter variáveis" }
});

export const getVariable = id=>({
  type: "GET_VARIABLE",
  payload: { id }
});

export const getVariableSuccess = data=>({
  type: "GET_VARIABLE_SUCCESS",
  payload: { data }
});

export const getVariableFailed = error=>({
  type: "GET_VARIABLE_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter variável" }
});

export const createVariable = variable=>({
  type: "CREATE_VARIABLE",
  payload: { variable }
});

export const createVariableSuccess = data=>({
  type: "CREATE_VARIABLE_SUCCESS",
  payload: { data }
});

export const createVariableFailed = error=>({
  type: "CREATE_VARIABLE_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar variável" }
});

export const updateVariable = (id, variable)=>({
  type: "UPDATE_VARIABLE",
  payload: { id, variable }
});

export const updateVariableSuccess = data=>({
  type: "UPDATE_VARIABLE_SUCCESS",
  payload: { data }
});

export const updateVariableFailed = error=>({
  type: "UPDATE_VARIABLE_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar variável" }
});

export const deleteVariable = id=>({
  type: "DELETE_VARIABLE",
  payload: { id }
});

export const deleteVariableSuccess = ()=>({
  type: "DELETE_VARIABLE_SUCCESS",
  payload: {}
});

export const deleteVariableFailed = error=>({
  type: "DELETE_VARIABLE_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar variável" }
});