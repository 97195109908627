import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {
  getFactories,
  getProductFactories,
  createProductFactory,
  deleteProductFactory,
  showSnackbar
} from '../../redux/actions';
import { connect } from 'react-redux';

import { map, get } from 'lodash';

import ProductBillingList from './billings';

const styles = theme=>({
  chipItem: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  buttons: {
    marginTop: theme.spacing.unit*3,
  },
  muted: {
    color: theme.palette.grey[600]
  },
  factory: {
    marginBottom: theme.spacing.unit,
  },
});

class ProductFactoryList extends PureComponent {
  state = {
    anchorEl: null,
  };

  componentWillMount() {
    this.props.getFactories(0, null, null);
    this.fetchData();
  }

  componentWillReceiveProps(props) {
    const { showSnackbar, productFactories, where } = props;
    const { loading, error, errorMessage, action } = productFactories;

    if (where !== this.props.where) {
      this.fetchData(props);
    }
    if (!loading) {
      if (error !== this.props.productFactories.error) {
        showSnackbar('error', errorMessage);
      } else if (action) {
        switch (action) {
          case 'CREATED':
            showSnackbar('success', `Local de produção adicionado com sucesso`);
          break;
          case 'DELETED':
            showSnackbar('success', `Local de produção removido com sucesso`);
          break;
          default:
            console.info('unknown action:', action);
          break;
        }
        this.fetchData(props);
      }
    }
  }

  fetchData = (props = this.props)=>{
    const { getProductFactories, where } = props;
    if (where) {
      getProductFactories(0, null, where);
    }
  };

  handleAddProductFactory = factoryId=>event=>{
    this.setState({ anchorEl: null });
    const { createProductFactory, where } = this.props;
    createProductFactory({ ...where, factoryId });
  };

  handleRemoveProductFactory = viewId=>event=>{
    event.preventDefault();
    const { deleteProductFactory } = this.props;
    deleteProductFactory(viewId);
  };

  handleClick = event=>{
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = ()=>{
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, factories, productFactories: { list, loading }, where, children } = this.props;
    const { anchorEl } = this.state;

    const currentFactories = map(list, 'factoryId');
    const factoryChoices = factories.list.filter(fa=>!currentFactories.includes(fa.id));

    if (!where) {
      return null;
    }

    return (
      <Fragment>
        {children}
        <Typography variant="subtitle1">Locais de produção</Typography>
        <Typography className={classes.muted}>Fábricas onde esse produto é produzido e seus respectivos locais de faturamento, selecione somente quando nem todos os locais estiverem disponíveis, se não selecionar nenhum todos estarão disponíveis</Typography>
        {
          list
            .map(pf=>{
              const factoryName = get(pf, 'Factory.name');
              const icms = get(pf, 'Factory.icms');
              const label = `${factoryName} (${icms}%)`;
              return (
                <div className={classes.factory} key={pf.id}>
                  <Chip
                    color="secondary"
                    label={label}
                    onDelete={this.handleRemoveProductFactory(pf.id)}
                    className={classes.chipItem}
                  />

                  <ProductBillingList productFactoryId={pf.id} />
                </div>
              );
            })
        }
        
        {!!factoryChoices.length&&(
          <div className={classes.buttons}>
            <Button
              disabled={loading}
              color="secondary"
              variant="outlined"
              aria-owns={anchorEl ? 'add-menu-fac' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              Adicionar local de produção
            </Button>
          </div>
        )}

        <Menu
          id="add-menu-fac"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {
            factoryChoices
              .map(({ id, name })=>(
                <MenuItem key={id} onClick={this.handleAddProductFactory(id)}>{name}</MenuItem>
              ))
          }
        </Menu>
      </Fragment>
    );
  }
};

ProductFactoryList.propTypes = {
  classes: PropTypes.object.isRequired,
  factories: PropTypes.object.isRequired,
  getFactories: PropTypes.func.isRequired,
  productFactories: PropTypes.object.isRequired,
  getProductFactories: PropTypes.func.isRequired,
  createProductFactory: PropTypes.func.isRequired,
  deleteProductFactory: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  where: PropTypes.object,
  children: PropTypes.node,
};

const mapStateToProps = ({ productFactories, factories })=>{
  return { productFactories, factories };
};

export default withStyles(styles)(connect(mapStateToProps, {
  getFactories,
  getProductFactories,
  createProductFactory,
  deleteProductFactory,
  showSnackbar
})(ProductFactoryList));