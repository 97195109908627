export const getProductFactories = (offset, limit, filter = {})=>({
  type: "GET_PRODUCT_FACTORIES",
  payload: { offset, limit, where: filter }
});

export const getProductFactoriesSuccess = data=>({
  type: "GET_PRODUCT_FACTORIES_SUCCESS",
  payload: { data }
});

export const getProductFactoriesFailed = error=>({
  type: "GET_PRODUCT_FACTORIES_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter medidas" }
});

export const getProductFactory = id=>({
  type: "GET_PRODUCT_FACTORY",
  payload: { id }
});

export const getProductFactorySuccess = data=>({
  type: "GET_PRODUCT_FACTORY_SUCCESS",
  payload: { data }
});

export const getProductFactoryFailed = error=>({
  type: "GET_PRODUCT_FACTORY_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter medida" }
});

export const createProductFactory = productFactory=>({
  type: "CREATE_PRODUCT_FACTORY",
  payload: { productFactory }
});

export const createProductFactorySuccess = data=>({
  type: "CREATE_PRODUCT_FACTORY_SUCCESS",
  payload: { data }
});

export const createProductFactoryFailed = error=>({
  type: "CREATE_PRODUCT_FACTORY_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar medida" }
});

export const updateProductFactory = (id, productFactory)=>({
  type: "UPDATE_PRODUCT_FACTORY",
  payload: { id, productFactory }
});

export const updateProductFactorySuccess = data=>({
  type: "UPDATE_PRODUCT_FACTORY_SUCCESS",
  payload: { data }
});

export const updateProductFactoryFailed = error=>({
  type: "UPDATE_PRODUCT_FACTORY_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar medida" }
});

export const deleteProductFactory = id=>({
  type: "DELETE_PRODUCT_FACTORY",
  payload: { id }
});

export const deleteProductFactorySuccess = ()=>({
  type: "DELETE_PRODUCT_FACTORY_SUCCESS",
  payload: {}
});

export const deleteProductFactoryFailed = error=>({
  type: "DELETE_PRODUCT_FACTORY_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar medida" }
});