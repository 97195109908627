import React from 'react';
import { get, transform } from 'lodash';
import moment from 'moment';

import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';

import CreatedIcon from '@material-ui/icons/AddCircleOutline';
import UpdatedIcon from '@material-ui/icons/Update';
import FinishedIcon from '@material-ui/icons/CheckCircle';
import ConfirmedIcon from '@material-ui/icons/Stars';
import CanceledIcon from '@material-ui/icons/Block';
import RejectedIcon from '@material-ui/icons/RemoveShoppingCart';
import ErrorIcon from '@material-ui/icons/Warning';

export const orderStatuses = {
  created: 'Criado',
  updated: 'Atualizado',
  finished: 'Finalizado',
  confirmed: 'Solicitado',
  canceled: 'Cancelado',
  refused: 'Rejeitado',
};

export const statuses = transform(orderStatuses, (result, name, id)=>{
  result.push({ id, name });
}, []);

const Configuration = {
  created: {
    Icon: CreatedIcon,
    variant: "outlined",
  },
  updated: {
    Icon: UpdatedIcon,
    variant: "outlined",
  },
  finished: {
    Icon: FinishedIcon,
  },
  confirmed: {
    Icon: ConfirmedIcon,
    color: "primary",
  },
  canceled: {
    Icon: CanceledIcon,
    color: "secondary",
  },
  refused: {
    Icon: RejectedIcon,
    color: "secondary",
  },
  ERR: {
    Icon: ErrorIcon,
    label: ''
  }
};

export const OrderStatus = ({ item })=>{
  const status = get(item, 'OrderStatus.status');
  const { Icon, ...props } = Configuration[status] || Configuration.ERR;
  const label = String(orderStatuses[status]).toUpperCase();
  const createdAt = get(item, 'createdAt');
  return (
    <Tooltip placement="top" title={createdAt?moment(createdAt).format('DD/MM/YYYY HH:mm'):''}>
      <Chip label={label} {...props} icon={<Icon fontSize="small" />} />
    </Tooltip>
  );
}