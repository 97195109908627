import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { ResponsivePie } from '@nivo/pie';
import get from 'lodash/get';

import blue from '@material-ui/core/colors/blue';
import red from '@material-ui/core/colors/red';
import purple from '@material-ui/core/colors/purple';

// import { orderStatuses } from '../components/OrderStatus';

import {
  getOrders,
  showSnackbar
} from '../redux/actions';
import { connect } from 'react-redux';

const groups = [
  {
    label: 'Em aberto',
    types: ['created', 'updated'],
    color: purple[100],
  },
  {
    label: 'Finalizado',
    types: ['finished'],
    color: purple[300],
  },
  {
    label: 'Solicitado',
    types: ['confirmed'],
    color: blue[400],
  },
  {
    label: 'Cancelado',
    types: ['canceled'],
    color: red[400],
  }
];

const styles = theme => ({
  chart: {
    height: 450,
    position: 'relative',
  },
  chartTooltip: {
    display: 'flex',
    alignItems: 'center',
    '& > strong': {
      marginLeft: theme.spacing.unit/2,
    }
  },
  chartTooltipColor: {
    width: 15,
    height: 15,
    marginRight: theme.spacing.unit,
  },
  chartTitle: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    textAlign: 'center',
    width: 120,
    height: 120,
    marginLeft: -60,
    marginTop: -105,
    fontSize: theme.typography.pxToRem(20),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& > strong': {
      fontSize: theme.typography.pxToRem(22),
    }
  },
});

class Dashboard extends Component {
  chartOptions = {
    margin: {
      top: 0,
      right: 0,
      bottom: 80,
      left: 0
    },
    innerRadius: .55,
    padAngle: 1,
    // enableRadialLabels: false,
    // enableSlicesLabels: false,
    colors: groups.map(g=>g.color),
    legends: [
      {
        anchor: 'bottom',
        direction: 'row',
        translateY: 56,
        itemWidth: 100,
        itemHeight: 18,
        symbolSize: 18,
        symbolShape: 'circle',
      }
    ],
    tooltip: ({ color, label, value })=>(
      <Typography component="div" className={this.props.classes.chartTooltip}>
        <div className={this.props.classes.chartTooltipColor} style={{backgroundColor: color}} />
        {label}:
        <strong>{value}</strong>
      </Typography>
    )
  };

  componentDidMount() {
    this.props.getOrders(0, null, null, { model: 'OrderStatus' });
  }

  componentWillReceiveProps(props) {
    const { showSnackbar, orders: { loading, errorMessage, error } } = props;

    if (!loading) {
      if (error) {
        showSnackbar('error', errorMessage);
      }
    }
  }

  render() {
    const { classes, orders: { list } } = this.props;
    const data = groups.map(group=>({
      id: group.label,
      label: group.label,
      value: list.filter(item=>group.types.includes(get(item, 'OrderStatus.status'))).length,
    }));

    return (
      <Grid container spacing={32}>
        <Grid xl={4} lg={6} xs={12} item>
          <div className={classes.chart}>
            <Typography className={classes.chartTitle}>
              <strong>{list.length}</strong> pedidos
            </Typography>
            <ResponsivePie data={data} {...this.chartOptions} />
          </div>
        </Grid>
      </Grid>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  orders: PropTypes.object.isRequired,
  getOrders: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = ({ orders })=>{
  return { orders };
};

export default withStyles(styles)(connect(mapStateToProps, { getOrders, showSnackbar })(Dashboard));