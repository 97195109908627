import React, { Fragment, PureComponent, cloneElement } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';

export default class SafeRemove extends PureComponent {
  state = {
    open: false
  };

  handleClick = event=>{
    this.setState({ open: true });
  };

  handleClose = event=>{
    this.setState({ open: false });
  };

  handleConfirm = event=>{
    this.setState({ open: false }, ()=>{
      this.props.onRemove(event);
    });
  };
  
  render() {
    const { open } = this.state;
    const { message, loading, children } = this.props;

    return (
      <Fragment>
        {cloneElement(children, {
          onClick: this.handleClick,
          disabled: !!loading
        })}
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="dialog-title"
          open={open}
        >
          {message&&(
            <DialogTitle id="dialog-title">{message}</DialogTitle>
          )}
          <DialogActions>
            <Button onClick={this.handleClose}>Cancelar</Button>
            <Button
              onClick={this.handleConfirm}
              color="secondary"
              variant="contained"
              disabled={loading}
            >
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
};

SafeRemove.propTypes = {
  children: PropTypes.element.isRequired,
  message: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};