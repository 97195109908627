import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
  getMarketsFailed,
  getMarketsSuccess,
  createMarketSuccess,
  createMarketFailed,
  deleteMarketSuccess,
  deleteMarketFailed,
  getMarketSuccess,
  getMarketFailed,
  updateMarketSuccess,
  updateMarketFailed,
} from './actions';

const Market = new Resource('Market', 'markets');

// SAGAS
function* fetchMarkets({ payload }) {
  const { offset, limit, where, params } = payload;
  try {
    const data = yield call(Market.get, { params: { offset, limit, where, ...params } });
    yield put(getMarketsSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getMarketsFailed({ message: error.message }));
  }
}

function* createMarket({ payload }) {
  const { market } = payload;
  try {
    yield call(Market.create, { payload: market });
    yield put(createMarketSuccess());
  } catch (error) {
    console.error("Error in saga", error);
    yield put(createMarketFailed(error));
  }
}

function* deleteMarket({ payload }) {
  const { id } = payload;
  try {
    yield call(Market.delete, { id });
    yield put(deleteMarketSuccess());
  } catch (error) {
    console.error("Error in saga", error);
    yield put(deleteMarketFailed(error));
  }
}

function* getMarket({ payload }) {
  const { id } = payload;
  try {
    const data = yield call(Market.getOne, { id });
    yield put(getMarketSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getMarketFailed(error));
  }
}

function* updateMarket({ payload }) {
  const { id, market } = payload;
  try {
    const data = yield call(Market.update, { id, payload: market });
    yield put(updateMarketSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(updateMarketFailed(error));
  }
}


// WATCH FOR ACTIONS
export function* watchFetchMarkets() {
  yield takeEvery("GET_MARKETS", fetchMarkets);
}

export function* watchGetMarket() {
  yield takeEvery("GET_MARKET", getMarket);
}

export function* watchCreateMarket() {
  yield takeEvery("CREATE_MARKET", createMarket);
}

export function* watchDeleteMarket() {
  yield takeEvery("DELETE_MARKET", deleteMarket);
}

export function* watchUpdateMarket() {
  yield takeEvery("UPDATE_MARKET", updateMarket);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchMarkets),
    fork(watchGetMarket),
    fork(watchCreateMarket),
    fork(watchDeleteMarket),
    fork(watchUpdateMarket),
  ]);
}
