import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { PriceLogResource } from '../../resources/priceLog';
import {
  getPriceLogsFailed,
  getPriceLogsSuccess,
  getPriceLogSuccess,
  getPriceLogFailed,
  recalculatePricesSuccess,
  recalculatePricesFailed,
  rebasePricesSuccess,
  rebasePricesFailed,
} from './actions';

const PriceLog = new PriceLogResource();

// SAGAS
function* fetchPriceLogs({ payload }) {
  const { offset, limit, where, params } = payload;
  try {
    const data = yield call(PriceLog.get, { params: { offset, limit, where, ...params } });
    yield put(getPriceLogsSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getPriceLogsFailed({ message: error.message }));
  }
}

function* getPriceLog({ payload }) {
  const { id } = payload
  try {
    const data = yield call(PriceLog.getOne, { id });
    yield put(getPriceLogSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getPriceLogFailed(error));
  }
}

function* recalculate({ payload }) {
  const { name, amount, filter } = payload
  try {
    const data = yield call(PriceLog.recalculate, { name, amount, filter });
    yield put(recalculatePricesSuccess(data))
  } catch (error) {
    console.log("Error in saga", error)
    yield put(recalculatePricesFailed(error))
  }
}

function* rebase({ payload }) {
  const { id } = payload
  try {
    const data = yield call(PriceLog.rebase, { id })
    yield put(rebasePricesSuccess(data))
  } catch (error) {
    console.log("Error in saga", error)
    yield put(rebasePricesFailed(error))
  }
}

// WATCH FOR ACTIONS
export function* watchFetchPriceLogs() {
  yield takeEvery("GET_PRICE_LOGS", fetchPriceLogs);
}

export function* watchGetPriceLog() {
  yield takeEvery("GET_PRICE_LOG", getPriceLog);
}

export function* watchRecalculatePrices() {
  yield takeLatest("RECALCULATE", recalculate);
}

export function* watchRebasePrices() {
  yield takeLatest("REBASE", rebase);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchPriceLogs),
    fork(watchGetPriceLog),
    fork(watchRecalculatePrices),
    fork(watchRebasePrices),
  ]);
}
