import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import omit from 'lodash/omit';
import get from 'lodash/get';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';

const styles = theme=>({
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  highlight: {
    fontWeight: theme.typography.fontWeightMedium,
    backgroundColor: theme.palette.action.selected,
  },
});

function renderInputComponent({ inputRef = ()=>{}, ref, ...other }) {
  return (
    <TextField
      fullWidth
      {...other}
      InputProps={{
        inputRef: node=>{
          ref(node);
          inputRef(node);
        },
      }}
    />
  );
}

class Autocomplete extends PureComponent {
  state = {
    label: '',
    suggestions: []
  };
  timer = null;

  handleSuggestionsFetchRequested = ({ value, reason })=>{
    const { delay, fetchData } = this.props;
    clearTimeout(this.timer);
    this.timer = setTimeout(()=>{
      fetchData(value)
        .then(suggestions=>{
          this.setState({ suggestions });
        });
    }, delay);
  };

  handleSuggestionsClearRequested = ()=>{
    this.setState({ suggestions: [] });
  };

  handleChange = event=>{
    const { value } = event.target;
    const { onChange } = this.props;

    this.setState({ label: value }, ()=>{
      if (!value) {
        onChange(null);
      }
    });
  };

  handleSelect = (event, { suggestion, suggestionValue })=>{
    const { onChange, fetchId } = this.props;
    this.setState({ label: suggestionValue }, ()=>{
      onChange( fetchId ? get(suggestion, fetchId) : suggestion );
    });
  };
  
  renderSuggestion = (suggestion, { query, isHighlighted })=>{
    const { classes } = this.props;
    const value = this.getSuggestionValue(suggestion);
    const matches = match(value, query);
    const parts = parse(value, matches);
  
    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map(part=>{
            const props = {};
            if (part.highlight) {
              props.className = classes.highlight;
            }
            return (
              <span key={part.text} {...props}>
                {part.text}
              </span>
            );
          })}
        </div>
      </MenuItem>
    );
  };
  
  getSuggestionValue = suggestion=>{
    const { fetchLabel } = this.props;

    if (typeof fetchLabel === 'function') {
      return fetchLabel(suggestion);
    }

    return get(suggestion, fetchLabel);
  };

  render() {
    const { suggestions, label } = this.state
    const { classes, ...other } = this.props
    const autosuggestProps = {
      renderInputComponent: renderInputComponent,
      suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion: this.renderSuggestion,
      onSuggestionSelected: this.handleSelect,
    };
    return (
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          ...omit(other, ['onChange', 'fetchData', 'fetchLabel', 'fetchId']),
          value: label,
          onChange: this.handleChange,
          inputRef: node=>{
            this.popperNode = node;
          }
        }}
        theme={{
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={options=>(
          <Popper
            className={classes.popper}
            anchorEl={this.popperNode}
            open={Boolean(options.children)}
          >
            <Paper
              square
              {...options.containerProps}
              style={{ width: this.popperNode ? this.popperNode.clientWidth : null }}
            >
              {options.children}
            </Paper>
          </Popper>
        )}
      />
    );
  }
};

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  fetchLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  fetchId: PropTypes.string,
  delay: PropTypes.number,
};

Autocomplete.defaultProps = {
  delay: 500,
  fetchLabel: 'name',
  fetchId: 'id',
};

export default withStyles(styles)(Autocomplete);