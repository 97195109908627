import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import datagridListSagas from './datagridList/saga';
import datagridItemSagas from './datagridItem/saga';
import usersSagas from './users/saga';
import unitsSagas from './units/saga';
import factoriesSagas from './factories/saga';
import billingsSagas from './billings/saga';
import marketsSagas from './markets/saga';
import marketTaxesSagas from './marketTaxes/saga';
import shippingsSagas from './shippings/saga';
import categoriesSagas from './categories/saga';
import measuresSagas from './measures/saga';
import priceLogsSagas from './priceLogs/saga';
import productsSagas from './products/saga';
import ordersSagas from './orders/saga';
import orderVariablesSagas from './orderVariables/saga';
import inputsSagas from './inputs/saga';
import variablesSagas from './variables/saga';
import comparesSagas from './compares/saga';
import compareVariablesSagas from './compareVariables/saga';
import viewsSagas from './views/saga';
import productBillingsSagas from './productBillings/saga';
import productValuesSagas from './productValues/saga';
import productCategoriesSagas from './productCategories/saga';
import productFactoriesSagas from './productFactories/saga';
import factoryBillingsSagas from './factoryBillings/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    datagridListSagas(),
    datagridItemSagas(),
    usersSagas(),
    unitsSagas(),
    factoriesSagas(),
    billingsSagas(),
    marketsSagas(),
    marketTaxesSagas(),
    shippingsSagas(),
    categoriesSagas(),
    measuresSagas(),
    ordersSagas(),
    orderVariablesSagas(),
    priceLogsSagas(),
    productsSagas(),
    inputsSagas(),
    variablesSagas(),
    comparesSagas(),
    compareVariablesSagas(),
    viewsSagas(),
    productBillingsSagas(),
    productValuesSagas(),
    productCategoriesSagas(),
    productFactoriesSagas(),
    factoryBillingsSagas(),
  ]);
}