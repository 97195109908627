import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import BackIcon from '@material-ui/icons/ArrowBack';

const styles = theme=>({
  dialog: {
    [theme.breakpoints.up('md')]: {
      left: 125
    },
    [theme.breakpoints.up('lg')]: {
      left: 250
    },
    [theme.breakpoints.up('xl')]: {
      left: 500
    },
    // transition: theme.transitions.create(['margin'], {
    //   easing: theme.transitions.easing.easeOut,
    //   // duration: theme.transitions.duration.shorter,
    // }),
  },
  dialogContent: {
    padding: theme.spacing.unit*3,
  },
});

const OVERLAP_WIDTH = 80;
const OPENEDS = [];
const TRIGGER_OPEN = (fn)=>{
  if (typeof fn !== 'function') {
    return;
  }
  OPENEDS.forEach((fn, index)=>{
    fn(OPENEDS.length-index);
  });
  const index = OPENEDS.push(fn) - 1;
  return ()=>{
    OPENEDS.splice(index, 1);
    OPENEDS.forEach((fn, index)=>{
      fn(OPENEDS.length-index-1);
    });
  };
};

const Transition = props=><Slide direction="left" {...props} timeout={150} />;

class SideNavigation extends Component {
  triggerClose = null;
  state = {
    index: 0,
  };

  componentWillMount() {
    if (this.props.open) {
      this.open();
    }
  }

  componentWillReceiveProps(props) {
    if (props.open !== this.props.open) {
      this[props.open?'open':'close']();
    }
  }

  componentWillUnmount() {
    this.close();
  }

  open = ()=>{
    this.triggerClose = TRIGGER_OPEN(index=>{
      this.setState({ index });
    });
  };

  close = ()=>{
    if (typeof this.triggerClose === 'function') {
      this.triggerClose();
      this.triggerClose = null;
    }
  };

  render() {
    const { classes, children, onClose, ...others } = this.props;
    const { index } = this.state;

    return (
      <Dialog
        scroll="body"
        {...others}
        TransitionComponent={Transition}
        fullScreen
        className={classes.dialog}
        style={{marginLeft: -index*OVERLAP_WIDTH}}
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent className={classes.dialogContent}>
          <IconButton onClick={onClose}>
            <BackIcon />
          </IconButton>
          {children}
        </DialogContent>
      </Dialog>
    );
  }
}

SideNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};

export default withStyles(styles)(SideNavigation);