import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
  getFactoryBillingsFailed,
  getFactoryBillingsSuccess,
  createFactoryBillingSuccess,
  createFactoryBillingFailed,
  deleteFactoryBillingSuccess,
  deleteFactoryBillingFailed,
  getFactoryBillingSuccess,
  getFactoryBillingFailed,
  updateFactoryBillingSuccess,
  updateFactoryBillingFailed,
} from './actions';

const FactoryBilling = new Resource('FactoryBilling', 'factoryBillings');

// SAGAS
function* fetchFactoryBillings({ payload }) {
  const { offset, limit, where, params } = payload;
  try {
    const data = yield call(FactoryBilling.get, { params: { offset, limit, where, ...params } });
    yield put(getFactoryBillingsSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getFactoryBillingsFailed({ message: error.message }));
  }
}

function* createFactoryBilling({ payload }) {
  const { factoryBilling } = payload;
  try {
    const data = yield call(FactoryBilling.create, { payload: factoryBilling });
    yield put(createFactoryBillingSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(createFactoryBillingFailed(error));
  }
}

function* deleteFactoryBilling({ payload }) {
  const { id } = payload;
  try {
    yield call(FactoryBilling.delete, { id });
    yield put(deleteFactoryBillingSuccess());
  } catch (error) {
    console.error("Error in saga", error);
    yield put(deleteFactoryBillingFailed(error));
  }
}

function* getFactoryBilling({ payload }) {
  const { id, params } = payload
  try {
    const data = yield call(FactoryBilling.getOne, { id, params });
    yield put(getFactoryBillingSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getFactoryBillingFailed(error));
  }
}

function* updateFactoryBilling({ payload }) {
  const { id, factoryBilling } = payload;
  try {
    const data = yield call(FactoryBilling.update, { id, payload: factoryBilling });
    yield put(updateFactoryBillingSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(updateFactoryBillingFailed(error));
  }
}


// WATCH FOR ACTIONS
export function* watchFetchFactoryBillings() {
  yield takeEvery("GET_FACTORY_BILLINGS", fetchFactoryBillings);
}

export function* watchGetFactoryBilling() {
  yield takeEvery("GET_FACTORY_BILLING", getFactoryBilling);
}

export function* watchCreateFactoryBilling() {
  yield takeEvery("CREATE_FACTORY_BILLING", createFactoryBilling);
}

export function* watchDeleteFactoryBilling() {
  yield takeEvery("DELETE_FACTORY_BILLING", deleteFactoryBilling);
}

export function* watchUpdateFactoryBilling() {
  yield takeEvery("UPDATE_FACTORY_BILLING", updateFactoryBilling);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchFactoryBillings),
    fork(watchGetFactoryBilling),
    fork(watchCreateFactoryBilling),
    fork(watchDeleteFactoryBilling),
    fork(watchUpdateFactoryBilling),
  ]);
}
