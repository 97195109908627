import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import login from './login';
import changePassword from './changePassword';
import recoverPassword from './recoverPassword';

export default class Auth extends Component {
  render() {
    return (
      <Switch>
        <Route path={`/alterar_senha`} component={changePassword} />
        <Route path={`/esqueci_senha`} component={recoverPassword} />
        <Route component={login} />
      </Switch>
    );
  }
};