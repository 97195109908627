import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
  getComparesFailed,
  getComparesSuccess,
  createCompareSuccess,
  createCompareFailed,
  deleteCompareSuccess,
  deleteCompareFailed,
  getCompareSuccess,
  getCompareFailed,
  updateCompareSuccess,
  updateCompareFailed,
} from './actions';

const Compare = new Resource('Compare', 'compares')

const include = [
  { model: 'CompareVariable' }
];

// SAGAS
function* fetchCompares({ payload }) {
  const { offset, limit, where } = payload;
  try {  
    const data = yield call(Compare.get, { params: { offset, limit, where, include } });
    yield put(getComparesSuccess(data));
  } catch (error) {
    console.error("Error in saga", error)
    yield put(getComparesFailed({ message: error.message }));
  }
}

function* createCompare({ payload }) {
  const { compare } = payload
  try {
    const data = yield call(Compare.create, { payload: compare })
    yield put(createCompareSuccess(data))
  } catch (error) {
    console.error("Error in saga", error)
    yield put(createCompareFailed(error))
  }
}

function* deleteCompare({ payload }) {
  const { id } = payload
  try {
    yield call(Compare.delete, { id })
    yield put(deleteCompareSuccess())
  } catch (error) {
    console.error("Error in saga", error)
    yield put(deleteCompareFailed(error))
  }
}

function* getCompare({ payload }) {
  const { id } = payload
  try {
    const data = yield call(Compare.getOne, { id, params: { include } })
    yield put(getCompareSuccess(data))
  } catch (error) {
    console.error("Error in saga", error)
    yield put(getCompareFailed(error))
  }
}

function* updateCompare({ payload }) {
  const { id, compare } = payload
  try {
    const data = yield call(Compare.update, { id, payload: compare })
    yield put(updateCompareSuccess(data))
  } catch (error) {
    console.error("Error in saga", error)
    yield put(updateCompareFailed(error))
  }
}


// WATCH FOR ACTIONS
export function* watchFetchCompares() {
  yield takeEvery("GET_COMPARES", fetchCompares);
}

export function* watchGetCompare() {
  yield takeEvery("GET_COMPARE", getCompare);
}

export function* watchCreateCompare() {
  yield takeEvery("CREATE_COMPARE", createCompare);
}

export function* watchDeleteCompare() {
  yield takeEvery("DELETE_COMPARE", deleteCompare);
}

export function* watchUpdateCompare() {
  yield takeEvery("UPDATE_COMPARE", updateCompare);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchCompares),
    fork(watchGetCompare),
    fork(watchCreateCompare),
    fork(watchDeleteCompare),
    fork(watchUpdateCompare),
  ]);
}
