const INIT_STATE = {
  loading: false,
  error: false,
  errorMessage: null,
  data: [],
  action: null,
  invalid: false
};

const reducer = (state = INIT_STATE, action)=>{
  switch (action.type) {
    case 'INVALIDATE_LIST_ITEM':
      return { ...state, invalid: true };

    case 'GET_LIST_ITEM':
      return { ...state, loading: true, error: false, errorMessage: null, action: null, data: [], invalid: false };

    case 'GET_LIST_ITEM_SUCCESS':
      return { ...state, loading: false, error: false, errorMessage: null, action: null, data: action.payload.data };

    case 'GET_LIST_ITEM_FAILED':
      return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

    case 'DELETE_LIST_ITEM':
      return { ...state, loading: true, error: false, errorMessage: null, action: 'DELETE' };

    case 'DELETE_LIST_ITEM_SUCCESS':
      return { ...state, loading: false, error: false, errorMessage: null, action: 'DELETE', invalid: true };

    case 'DELETE_LIST_ITEM_FAILED':
      return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: 'DELETE' };

    default:
      return state;
  }
};

export default reducer;