import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { connect } from 'react-redux';

import SideNavigation from '../../components/SideNavigation';

import FactoryBillingList from './billings';
import FactoryBillingForm from './billing';
import ShippingList from './shippings';
import ShippingForm from './shipping';

class FactoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.parseData(props.data);
  }

  componentWillReceiveProps({ data, shippings: { action: sa }, factoryBillings: { action: fba } }) {
    if (data !== this.props.data) {
      this.setState(this.parseData(data));
    }
    if (sa === 'CREATED' || sa === 'UPDATED' || fba === 'CREATED' || fba === 'UPDATED') {
      this.handleClose();
    }
  }

  parseData = data=>{
    const { name = '', icms = '' } = data || {};
    return {
      name,
      icms
    };
  };

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { onSubmit } = this.props;
    onSubmit(this.state);
  };

  handleClose = ()=>{
    const { history, match } = this.props;
    history.push(match.url);
  };

  render() {
    const { classes, loading, data, match } = this.props;
    const { name, icms } = this.state;

    return (
      <Fragment>
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Nome"
                name="name"
                value={name}
                onChange={this.handleChange}
                disabled={loading}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="ICMS"
                value={icms}
                onChange={this.handleChange}
                name="icms"
                type="number"
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>

          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              type="submit"
            >
              Salvar
            </Button>
          </div>
        </form>

        <FactoryBillingList data={data} match={match}>
          <Divider className={classes.divider} />
        </FactoryBillingList>

        <ShippingList data={data} match={match}>
          <Divider className={classes.divider} />
        </ShippingList>

        <SideNavigation open={!match.isExact} onClose={this.handleClose}>
          <Switch>
            <Route path={`${match.path}/faturamentos/:factoryBillingId`} component={FactoryBillingForm} />
            <Route path={`${match.path}/fretes/:shippingId`} component={ShippingForm} />
          </Switch>
        </SideNavigation>
      </Fragment>
    );
  }
};

const mapStateToProps = ({ shippings, factoryBillings })=>{
  return { shippings, factoryBillings };
};

export default connect(mapStateToProps)(FactoryForm);