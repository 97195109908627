const INIT_STATE = {
  loading: false,
  error: false,
  errorMessage: null,
	list: [],
	item: null,
	action: null
};

const reducer = (state = INIT_STATE, action)=>{
	switch (action.type) {
		case 'GET_BILLINGS':
			return { ...state, loading: true, error: false, errorMessage: null, action: null, list: [] };

		case 'GET_BILLINGS_SUCCESS':
			return { ...state, loading: false, error: false, errorMessage: null, list: action.payload.data, action: null };

		case 'GET_BILLINGS_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

		case 'GET_BILLING':
			return { ...state, loading: true, error: false, action: null };

		case 'GET_BILLING_SUCCESS':
			return { ...state, loading: false, error: false, item: action.payload.data, action: null };

		case 'GET_BILLING_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

		case 'CREATE_BILLING':
			return { ...state, loading: true, error: false, action: null }

		case 'CREATE_BILLING_SUCCESS':
			return { ...state, loading: false, error: false, action: 'CREATED' }

		case 'CREATE_BILLING_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null }

		case 'UPDATE_BILLING':
			return { ...state, loading: true, error: false, action: null }

		case 'UPDATE_BILLING_SUCCESS':
			return { ...state, loading: false, error: false, action: 'UPDATED' }

		case 'UPDATE_BILLING_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null }

		case 'DELETE_BILLING':
			return { ...state, loading: true, action: null }

		case 'DELETE_BILLING_SUCCESS':
			return { ...state, loading: false, error: false, action: 'DELETED' }

		case 'DELETE_BILLING_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null }

		default:
			return state;

	}
};

export default reducer;