const parseError = error=>(
  error.message || error.statusMessage || error.status || "Erro ao executar sua requisição"
);

export const resetItem = ()=>({
  type: "RESET_ITEM",
  payload: {}
});

export const getItem = (model, id, params)=>({
  type: "GET_ITEM",
  payload: { model, id, params }
});

export const getItemSuccess = data=>({
  type: "GET_ITEM_SUCCESS",
  payload: { data }
});

export const getItemFailed = error=>({
  type: "GET_ITEM_FAILED",
  payload: { message: parseError(error) }
});

export const createItem = (model, data)=>({
  type: "CREATE_ITEM",
  payload: { model, data }
});

export const createItemSuccess = data=>({
  type: "CREATE_ITEM_SUCCESS",
  payload: { data }
});

export const createItemFailed = error=>({
  type: "CREATE_ITEM_FAILED",
  payload: { message: parseError(error) }
});

export const updateItem = (model, id, data)=>({
  type: "UPDATE_ITEM",
  payload: { model, id, data }
});

export const updateItemSuccess = ()=>({
  type: "UPDATE_ITEM_SUCCESS",
  payload: {}
});

export const updateItemFailed = error=>({
  type: "UPDATE_ITEM_FAILED",
  payload: { message: parseError(error) }
});

export const deleteItem = (model, id)=>({
  type: "DELETE_ITEM",
  payload: { model, id }
});

export const deleteItemSuccess = ()=>({
  type: "DELETE_ITEM_SUCCESS",
  payload: {}
});

export const deleteItemFailed = error=>({
  type: "DELETE_ITEM_FAILED",
  payload: { message: parseError(error) }
});