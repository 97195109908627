const { REACT_APP_API_URL: API_URL } = process.env;

let ACCESS_TOKEN = localStorage.getItem('access_token');
if (!ACCESS_TOKEN) {
  ACCESS_TOKEN = sessionStorage.getItem("access_token");
}

const asyncRequest = async function(url, method, access_token, body, headers){
  const params = {
    method: method,
    headers: {
      ...headers
    }
  };

  if (body) {
    params.body = JSON.stringify(body);
    params.headers["Accept"] = "application/json";
    params.headers["Content-Type"] = "application/json";
  }

  if (access_token) {
    params.headers["Authorization"] = `Bearer ${access_token}`;
  }

  return fetch(url, params)
    .then(response=>{
      return new Promise((resolve, reject)=>{
        if (response.status===204) {
          return resolve();
        }
        response.json()
          .then(responseJson=>{
            if (response.status < 200 || response.status > 299) {
              responseJson.status = response.status;
              reject(responseJson);
            } else {
              resolve(responseJson);
            }
          });
      });
    });
}

export default class Auth {
  static get accessToken() {
    return ACCESS_TOKEN;
  }

  static async signInWithEmailAndPassword(email, password) {
    return asyncRequest(
      `${API_URL}/auth`,
      "POST",
      null,
      { email, password }
    );
  }

  static async signOut() {
    if (!ACCESS_TOKEN) {
      return Promise.reject();
    }
    return asyncRequest(
      `${API_URL}/auth`,
      "DELETE",
      ACCESS_TOKEN
    )
      .then(response=>{
        localStorage.removeItem('access_token');
        sessionStorage.removeItem('access_token');
        ACCESS_TOKEN = undefined;
        return response;
      });
  }

  static async getCurrentUser(access_token, remember) {
    if (!access_token) {
      if (!ACCESS_TOKEN) {
        return Promise.reject();
      }
      access_token = ACCESS_TOKEN;
    }
    return asyncRequest(
      `${API_URL}/auth/me`,
      "GET",
      access_token
    )
      .then(response=>{
        if (access_token) {
          if (remember) {
            localStorage.setItem('access_token', access_token);
          } else {
            sessionStorage.setItem('access_token', access_token);
          }
          ACCESS_TOKEN = access_token;
        }
        return response;
      });
  }

  static async recoverPassword(email) {
    return asyncRequest(
      `${API_URL}/auth/passwordRecovery`,
      "POST",
      null,
      { email, redirectUrl: `${window.location.origin}/alterar_senha` }
    );
  }

  static async changePassword(password, token) {
    if (!token) {
      return Promise.reject();
    }
    return asyncRequest(
      `${API_URL}/auth/changePassword`,
      "POST",
      token,
      { password }
    );
  }
}