export const getOrders = (offset, limit, filter = {}, include = [])=>({
  type: "GET_ORDERS",
  payload: { offset, limit, where: filter, include }
});

export const getOrdersSuccess = data=>({
  type: "GET_ORDERS_SUCCESS",
  payload: { data }
});

export const getOrdersFailed = error=>({
  type: "GET_ORDERS_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter orçamentos" }
});

export const getOrder = id=>({
  type: "GET_ORDER",
  payload: { id }
});

export const getOrderSuccess = data=>({
  type: "GET_ORDER_SUCCESS",
  payload: { data }
});

export const getOrderFailed = error=>({
  type: "GET_ORDER_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter orçamento" }
});

export const createOrder = order=>({
  type: "CREATE_ORDER",
  payload: { order }
});

export const createOrderSuccess = ()=>({
  type: "CREATE_ORDER_SUCCESS",
  payload: {}
});

export const createOrderFailed = error=>({
  type: "CREATE_ORDER_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar orçamento" }
});

export const updateOrder = (id, order)=>({
  type: "UPDATE_ORDER",
  payload: { id, order }
});

export const updateOrderSuccess = ()=>({
  type: "UPDATE_ORDER_SUCCESS",
  payload: {}
});

export const updateOrderFailed = error=>({
  type: "UPDATE_ORDER_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar orçamento" }
});

export const deleteOrder = id=>({
  type: "DELETE_ORDER",
  payload: { id }
});

export const deleteOrderSuccess = ()=>({
  type: "DELETE_ORDER_SUCCESS",
  payload: {}
});

export const deleteOrderFailed = error=>({
  type: "DELETE_ORDER_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar orçamento" }
});

export const finishOrder = id=>({
  type: "FINISH_ORDER",
  payload: { id }
})

export const finishOrderSuccess = order=>({
  type: "FINISH_ORDER_SUCCESS",
  payload: { order }
})

export const finishOrderFailed = error=>({
  type: "FINISH_ORDER_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar finalizar orçamento" }
})

export const cancelOrder = id=>({
  type: "CANCEL_ORDER",
  payload: { id }
})

export const cancelOrderSuccess = order=>({
  type: "CANCEL_ORDER_SUCCESS",
  payload: { order }
})

export const cancelOrderFailed = error=>({
  type: "CANCEL_ORDER_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar cancelar orçamento" }
})

export const calculateOrder = (id, inputs)=>({
  type: "CALCULATE_ORDER",
  payload: { id, inputs }
})

export const calculateOrderSuccess = order=>({
  type: "CALCULATE_ORDER_SUCCESS",
  payload: { order }
})

export const calculateOrderFailed = error=>({
  type: "CALCULATE_ORDER_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar calcular orçamento" }
})