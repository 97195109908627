export const getCompareVariables = (offset, limit, filter = {})=>({
  type: "GET_COMPARE_VARIABLES",
  payload: { offset, limit, where: filter }
});

export const getCompareVariablesSuccess = data=>({
  type: "GET_COMPARE_VARIABLES_SUCCESS",
  payload: { data }
});

export const getCompareVariablesFailed = error=>({
  type: "GET_COMPARE_VARIABLES_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter variáveis" }
});

export const getCompareVariable = id=>({
  type: "GET_COMPARE_VARIABLE",
  payload: { id }
});

export const getCompareVariableSuccess = data=>({
  type: "GET_COMPARE_VARIABLE_SUCCESS",
  payload: { data }
});

export const getCompareVariableFailed = error=>({
  type: "GET_COMPARE_VARIABLE_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter variável" }
});

export const createCompareVariable = variable=>({
  type: "CREATE_COMPARE_VARIABLE",
  payload: { variable }
});

export const createCompareVariableSuccess = data=>({
  type: "CREATE_COMPARE_VARIABLE_SUCCESS",
  payload: { data }
});

export const createCompareVariableFailed = error=>({
  type: "CREATE_COMPARE_VARIABLE_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar variável" }
});

export const updateCompareVariable = (id, variable)=>({
  type: "UPDATE_COMPARE_VARIABLE",
  payload: { id, variable }
});

export const updateCompareVariableSuccess = data=>({
  type: "UPDATE_COMPARE_VARIABLE_SUCCESS",
  payload: { data }
});

export const updateCompareVariableFailed = error=>({
  type: "UPDATE_COMPARE_VARIABLE_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar variável" }
});

export const deleteCompareVariable = id=>({
  type: "DELETE_COMPARE_VARIABLE",
  payload: { id }
});

export const deleteCompareVariableSuccess = ()=>({
  type: "DELETE_COMPARE_VARIABLE_SUCCESS",
  payload: {}
});

export const deleteCompareVariableFailed = error=>({
  type: "DELETE_COMPARE_VARIABLE_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar variável" }
});