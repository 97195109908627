import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link, Route, Switch } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import red from '@material-ui/core/colors/red';

import SideNavigation from '../../components/SideNavigation';
import SafeRemove from '../../components/SafeRemove';
import { sortByLetter } from './values';

import {
  getFactories,
  getCompares,
  deleteCompare,
  invalidateVariables,
  showSnackbar
} from '../../redux/actions';
import { connect } from 'react-redux';

import { get, find, map, sortBy, each, flatMap, filter, uniqBy, compact, uniq } from 'lodash';

import CompareForm from './compare';

const styles = theme=>({
  red: {
    color: red[600],
  },
  buttons: {
    marginTop: theme.spacing.unit*3,
  },
  muted: {
    color: theme.palette.grey[600]
  },
  responsiveTable: {
    overflowX: 'auto',
  },
  tableWrap: {
    '& td, & th': {
      whiteSpace: 'nowrap',
    },
  },
  factory: {
    backgroundColor: theme.palette.grey[300],
  },
});

const CompareData = ({ compareId = null, compares, keys, match, loading, classes, onRemove, index = 0, factoryId = null })=>(
  map(filter(compares, co=>co.compareId === compareId && co.factoryId === factoryId), compare=>(
    <Fragment key={compare.id}>
      <TableRow hover>
        <TableCell component="th" scope="row">
          <span style={{paddingLeft: index*15}}>
            {compare.name}
          </span>
        </TableCell>
        <TableCell>
          {!find(compares, co=>co.compareId === compare.id) && compare.id}
        </TableCell>
        {map(keys, (label, variableId)=>{
          const cv = find(compare.CompareVariables, { variableId: parseInt(variableId, 10) });
          return (
            <TableCell key={variableId}>{get(cv, 'formula')}</TableCell>
          );
        })}
        <TableCell align="right">
          {!compareId&&(
            <IconButton
              component={Link}
              color="primary"
              to={`${match.url}/comparacoes/${compare.id}/novo`}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          )}
          <IconButton
            component={Link}
            to={`${match.url}/comparacoes/${compare.id}`}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <SafeRemove
            loading={loading}
            onRemove={onRemove(compare.id)}
            message={`Remover comparação ${compare.name}?`}
          >
            <IconButton>
              <DeleteIcon className={classes.red} fontSize="small" />
            </IconButton>
          </SafeRemove>
        </TableCell>
      </TableRow>

      <CompareData
        compareId={compare.id}
        match={match}
        keys={keys}
        compares={compares}
        classes={classes}
        loading={loading}
        onRemove={onRemove}
        index={index+1}
      />
    </Fragment>
  ))
);

class CompareList extends PureComponent {
  componentWillMount() {
    this.props.getFactories(0, null, null);
    this.fetchData();
  }

  componentWillReceiveProps(props) {
    const { showSnackbar, compares, where, history, location, invalidateVariables } = props;
    const { loading, error, errorMessage, action, item, invalid } = compares;

    if (where !== this.props.where) {
      this.fetchData(props);
    }
    if (!loading) {
      if (error) {
        showSnackbar('error', errorMessage);
      } else if (action) {
        switch (action) {
          case 'CREATED':
            showSnackbar('success', `Comparação adicionada com sucesso`);
            const newPath = location.pathname.replace(/novo$/, item.id);
            history.replace(newPath);
          break;
          case 'UPDATED':
            showSnackbar('success', `Comparação atualizada com sucesso`);
          break;
          case 'DELETED':
            showSnackbar('success', `Comparação removida com sucesso`);
          break;
          default:
            console.info('unknown action:', action);
          break;
        }
        invalidateVariables();
        this.fetchData(props);
      } if (invalid) {
        this.fetchData(props);
      }
    }
  }

  fetchData = (props = this.props)=>{
    const { getCompares, where } = props;
    if (where) {
      getCompares(0, null, where);
    }
  };

  handleRemoveCompare = compareId=>event=>{
    event.preventDefault();
    const { deleteCompare } = this.props;
    deleteCompare(compareId);
  };

  handleClose = ()=>{
    const { history, match } = this.props;
    history.push(match.url);
  };

  render() {
    const { classes, compares: { list, loading }, factories, variables, where, children, match } = this.props;

    if (!where) {
      return null;
    }

    const keys = {};
    const variablesList = compact(map(uniqBy(flatMap(list, 'CompareVariables'), 'variableId'), cv=>find(variables.list, {id: cv.variableId})));
    each(sortBy(variablesList, sortByLetter), v=>{
      keys[v.id] = `${get(v, 'Letter.letter')} ${get(v, 'name')}`;
    });
    const factoryIds = uniq(map(list, 'factoryId')).sort().reverse();

    return (
      <Fragment>
        {children}
        <Typography variant="subtitle1">Comparações</Typography>
        <Typography className={classes.muted}>Conjunto de fórmulas a serem comparadas, a fórmula da variável é utilizada como critério de seleção</Typography>
        <div className={classes.responsiveTable}>
          <Table padding="dense">
            <TableHead>
              <TableRow>
                <TableCell>Nome</TableCell>
                <TableCell>Id</TableCell>
                {map(keys, (label, variableId)=>(
                  <TableCell key={variableId}>{label}</TableCell>
                ))}
                <TableCell/>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableWrap}>
              {map(factoryIds, factoryId=>{
                const factory = find(factories.list, {id: factoryId});

                return (
                  <Fragment key={factoryId}>
                    {factory&&(
                      <TableRow className={classes.factory}>
                        <TableCell component="th" scope="row" colSpan={Object.keys(keys).length+3}>
                          <Typography variant="overline">{factory.name}</Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    <CompareData
                      match={match}
                      keys={keys}
                      compares={list}
                      factoryId={factoryId}
                      classes={classes}
                      loading={loading}
                      onRemove={this.handleRemoveCompare}
                    />
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </div>
        
        <div className={classes.buttons}>
          <Button
            component={Link}
            to={`${match.url}/comparacoes/novo`}
            color="primary"
            type="submit"
            variant="outlined"
          >
            Adicionar comparação
          </Button>
        </div>

        <SideNavigation open={!match.isExact} onClose={this.handleClose}>
          <Switch>
            <Route path={`${match.path}/comparacoes/:parentId/:compareId`} exact component={CompareForm} />
            <Route path={`${match.path}/comparacoes/:compareId`} component={CompareForm} />
          </Switch>
        </SideNavigation>
      </Fragment>
    );
  }
};

CompareList.propTypes = {
  classes: PropTypes.object.isRequired,
  factories: PropTypes.object.isRequired,
  getFactories: PropTypes.func.isRequired,
  compares: PropTypes.object.isRequired,
  variables: PropTypes.object.isRequired,
  getCompares: PropTypes.func.isRequired,
  deleteCompare: PropTypes.func.isRequired,
  invalidateVariables: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  where: PropTypes.object,
  children: PropTypes.node,
};

const mapStateToProps = ({ compares, factories, variables })=>{
  return { compares, factories, variables };
};

export default withStyles(styles)(connect(mapStateToProps, { getFactories, getCompares, deleteCompare, invalidateVariables, showSnackbar })(CompareList));