import React from 'react';
import { get, map, sortBy } from 'lodash';

import Datagrid, { DatagridCell, EditButton, RemoveButton } from '../../components/Datagrid';
import { FabButton } from '../../components/FabToolbar';

import MarketForm from './form';

const TaxList = ({ item })=>(
  map(sortBy(item.MarketTaxes, ['Category.name', 'Factory.name']), mt=>{
    return (
      <span key={mt.id} style={{ marginRight: 12 }}>
        {get(mt, 'Category.name')}-{get(mt, 'Factory.name')}:<strong>{get(mt, 'value')}%</strong>
      </span>
    );
  })
);

const params = {
  include: {
    model: 'MarketTax',
    include: [{
      model: 'Category'
    }, {
      model: 'Factory'
    }]
  }
};

const MarketList = props=>(
  <Datagrid
    title="Mercados"
    name="Mercado"
    resource="markets"
    form={MarketForm}
    params={params}
    complex
  >
    <FabButton type="add" fab />
    <DatagridCell prop="id" label="Id" />
    <DatagridCell prop="name" label="Nome" />
    <DatagridCell label="Taxas">
      <TaxList />
    </DatagridCell>
    <DatagridCell numeric>
      <EditButton />
      <RemoveButton
        name={i=>`${get(i, 'name')}`}
      />
    </DatagridCell>
  </Datagrid>
);

export default MarketList;