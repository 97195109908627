import React, { cloneElement, Children } from 'react';
import TableCell from '@material-ui/core/TableCell';

import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

import green from '@material-ui/core/colors/green';

import { get, map, find } from 'lodash';

const formatContent = content=>{
  switch(content) {
    case true:
      content = <DoneIcon style={{color: green[800]}} />;
    break;
    case false:
      content = <ClearIcon style={{opacity: .1}} />;
    break;
    default:
    break;
  }
  return content;
}

const DatagridCell = props=>{
  const {
    numeric,
    children,
    prop,
    decorator,
    groupBy,
    append = '',
    prepend = '',
    item,
    keys,
    className,
    groupProp = 'value',
    ...other
  } = props;
  const content = get(item, prop);

  if (typeof keys === 'object' && Object.keys(keys).length) {
    return map(keys, (value, key)=>(
      <TableCell
        className={className}
        key={key}
        align={numeric ? "right" : "left"}
      >
        {get(find(content, {[groupBy]: parseInt(key, 10)}), groupProp)}
      </TableCell>
    ));
  }

  let view = formatContent(content);
  if (typeof decorator === 'function') {
    view = decorator(view||item);
  }
  
  return (
    <TableCell className={className} align={numeric ? "right" : "left"}>
      {!!view&&prepend}{view}{!!view&&append}
      {Children.map(children, (child, key)=>(
        cloneElement(child, { ...other, item, key })
      ))}
    </TableCell>
  );
};

export default DatagridCell;