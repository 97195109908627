import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
  getProductsFailed,
  getProductsSuccess,
  createProductSuccess,
  createProductFailed,
  deleteProductSuccess,
  deleteProductFailed,
  getProductSuccess,
  getProductFailed,
  updateProductSuccess,
  updateProductFailed,
} from './actions';

const Product = new Resource('Product', 'products')

// SAGAS
function* fetchProducts({ payload }) {
  const { offset, limit, where, params } = payload;
  try {
    const data = yield call(Product.get, { params: { offset, limit, where, ...params } });
    yield put(getProductsSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getProductsFailed({ message: error.message }));
  }
}

function* createProduct({ payload }) {
  const { product } = payload
  try {
    const data = yield call(Product.create, { payload: product });
    yield put(createProductSuccess(data));
  } catch (error) {
      console.error("Error in saga", error)
      yield put(createProductFailed(error))
  }
}

function* deleteProduct({ payload }) {
  const { id } = payload
  try {
    yield call(Product.delete, { id });
    yield put(deleteProductSuccess());
  } catch (error) {
    console.error("Error in saga", error);
    yield put(deleteProductFailed(error));
  }
}

function* getProduct({ payload }) {
  const { id } = payload
  try {
    const data = yield call(Product.getOne, { id });
    yield put(getProductSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getProductFailed(error));
  }
}

function* updateProduct({ payload }) {
  const { id, product } = payload
  try {
    const data = yield call(Product.update, { id, payload: product });
    yield put(updateProductSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(updateProductFailed(error));
  }
}

// WATCH FOR ACTIONS
export function* watchFetchProducts() {
  yield takeEvery("GET_PRODUCTS", fetchProducts);
}

export function* watchGetProduct() {
  yield takeEvery("GET_PRODUCT", getProduct);
}

export function* watchCreateProduct() {
  yield takeEvery("CREATE_PRODUCT", createProduct);
}

export function* watchDeleteProduct() {
  yield takeEvery("DELETE_PRODUCT", deleteProduct);
}

export function* watchUpdateProduct() {
  yield takeEvery("UPDATE_PRODUCT", updateProduct);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchProducts),
    fork(watchGetProduct),
    fork(watchCreateProduct),
    fork(watchDeleteProduct),
    fork(watchUpdateProduct),
  ]);
}
