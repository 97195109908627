import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
  getVariablesFailed,
  getVariablesSuccess,
  createVariableSuccess,
  createVariableFailed,
  deleteVariableSuccess,
  deleteVariableFailed,
  getVariableSuccess,
  getVariableFailed,
  updateVariableSuccess,
  updateVariableFailed,
} from './actions';

const Variable = new Resource('Variable', 'variables')

const include = [
  { model: 'Letter', required: true },
  { model: 'Compare' },
  { model: 'Variable', as: 'Variables', include: { model: 'Letter', required: true } }
];

// SAGAS
function* fetchVariables({ payload }) {
  const { offset, limit, where, params } = payload;
  try {
    const data = yield call(Variable.get, { params: { offset, limit, where, include, ...params } });
    yield put(getVariablesSuccess(data));
  } catch (error) {
    console.error("Error in saga", error)
    yield put(getVariablesFailed({ message: error.message }));
  }
}

function* createVariable({ payload }) {
  const { variable } = payload
  try {
    const data = yield call(Variable.create, { payload: variable })
    yield put(createVariableSuccess(data));
  } catch (error) {
    console.error("Error in saga", error)
    yield put(createVariableFailed(error))
  }
}

function* deleteVariable({ payload }) {
  const { id } = payload
  try {
    yield call(Variable.delete, { id })
    yield put(deleteVariableSuccess())
  } catch (error) {
    console.error("Error in saga", error)
    yield put(deleteVariableFailed(error))
  }
}

function* getVariable({ payload }) {
  const { id, params } = payload
  try {
    const data = yield call(Variable.getOne, { id, params: { include, ...params } })
    yield put(getVariableSuccess(data))
  } catch (error) {
    console.error("Error in saga", error)
    yield put(getVariableFailed(error))
  }
}

function* updateVariable({ payload }) {
  const { id, variable } = payload
  try {
    const data = yield call(Variable.update, { id, payload: variable })
    yield put(updateVariableSuccess(data))
  } catch (error) {
    console.error("Error in saga", error)
    yield put(updateVariableFailed(error))
  }
}


// WATCH FOR ACTIONS
export function* watchFetchVariables() {
  yield takeEvery("GET_VARIABLES", fetchVariables);
}

export function* watchGetVariable() {
  yield takeEvery("GET_VARIABLE", getVariable);
}

export function* watchCreateVariable() {
  yield takeEvery("CREATE_VARIABLE", createVariable);
}

export function* watchDeleteVariable() {
  yield takeEvery("DELETE_VARIABLE", deleteVariable);
}

export function* watchUpdateVariable() {
  yield takeEvery("UPDATE_VARIABLE", updateVariable);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchVariables),
    fork(watchGetVariable),
    fork(watchCreateVariable),
    fork(watchDeleteVariable),
    fork(watchUpdateVariable),
  ]);
}
