import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

import {
  getBillings,
  getFactoryBillings,
  deleteFactoryBilling,
  invalidateListItem,
  showSnackbar
} from '../../redux/actions';
import { connect } from 'react-redux';

import { map, get, sortBy } from 'lodash';

const styles = theme=>({
  chipItem: {
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
  buttons: {
    marginTop: theme.spacing.unit*2,
  },
  muted: {
    color: theme.palette.grey[600]
  },
});

class FactoryBillingList extends PureComponent {
  componentWillMount() {
    const { getBillings } = this.props;
    getBillings(0, null, null);
    this.fetchData();
  }

  componentWillReceiveProps(props) {
    const { showSnackbar, billings, factoryBillings, data, invalidateListItem } = props;
    const { loading, action, error, errorMessage } = factoryBillings;

    if (data !== this.props.data) {
      this.fetchData(props);
    }
    if (!billings.loading) {
      if (billings.error) {
        showSnackbar('error', billings.errorMessage);
      }
    }
    if (!loading) {
      if (error) {
        showSnackbar('error', errorMessage);
      } else if (action !== this.props.factoryBillings.action) {
        switch (action) {
          case 'CREATED':
            showSnackbar('success', `Faturamento adicionado com sucesso`);
          break;
          case 'DELETED':
            showSnackbar('success', `Faturamento removido com sucesso`);
          break;
          default:
            console.info('unknown action:', action);
          break;
        }
        invalidateListItem();
        this.fetchData(props);
      }
    }
  }

  fetchData = (props = this.props)=>{
    const { getFactoryBillings, data } = props;
    if (data && data.id) {
      getFactoryBillings(0, null, { factoryId: data.id });
    }
  };

  handleRemoveBilling = fBillingId=>event=>{
    event.preventDefault();
    const { deleteFactoryBilling } = this.props;
    deleteFactoryBilling(fBillingId);
  };

  render() {
    const { classes, billings, factoryBillings, data, children, match } = this.props;
    const currentBillings = map(factoryBillings.list, 'billingId');
    const billingChoices = billings.list.filter(b=>!currentBillings.includes(b.id));

    if (!data || !data.id) {
      return null;
    }

    return (
      <Fragment>
        {children}
        <Typography variant="subtitle1">Locais de faturamentos</Typography>
        <Typography className={classes.muted}>ICMS dos locais onde essa fábrica entrega</Typography>
        {
          sortBy(factoryBillings.list.map(factoryBilling=>{
            return {
              billing: billings.list.find(b=>b.id===factoryBilling.billingId),
              factoryBilling
            }
          }), 'billing.name')
            .map(({ factoryBilling: fBilling, billing })=>{
              const margin = get(fBilling, 'margin');
              const label = <span>
                {get(billing, 'name')} = <strong>{get(fBilling, 'icms')}%</strong>
                {margin&&` (${margin})`}
              </span>;
              return (
                <Chip
                  key={fBilling.id}
                  component={Link}
                  to={`${match.url}/faturamentos/${fBilling.id}`}
                  clickable
                  label={label}
                  onDelete={this.handleRemoveBilling(fBilling.id)}
                  className={classes.chipItem}
                />
              )
            })
        }

        {!!billingChoices.length&&(
          <div className={classes.buttons}>
            <Button
              component={Link}
              variant="outlined"
              to={`${match.url}/faturamentos/novo`}
              color="primary"
            >
              Adicionar local de faturamento
            </Button>
          </div>
        )}
      </Fragment>
    );
  }
};

FactoryBillingList.propTypes = {
  classes: PropTypes.object.isRequired,
  billings: PropTypes.object.isRequired,
  factoryBillings: PropTypes.object.isRequired,
  getBillings: PropTypes.func.isRequired,
  getFactoryBillings: PropTypes.func.isRequired,
  deleteFactoryBilling: PropTypes.func.isRequired,
  invalidateListItem: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  children: PropTypes.node,
};

const mapStateToProps = ({ billings, factoryBillings })=>{
  return { billings, factoryBillings };
};

export default withStyles(styles)(connect(mapStateToProps, {
  getBillings,
  getFactoryBillings,
  deleteFactoryBilling,
  invalidateListItem,
  showSnackbar
})(FactoryBillingList));