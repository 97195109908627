import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { createMarketTax, updateMarketTax, getMarketTax } from '../../redux/actions';
import { connect } from 'react-redux';

const styles = theme=>({
  buttons: {
    marginTop: theme.spacing.unit*3,
  },
});

class MarketTaxForm extends Component {
  state = {
    categoryId: '',
    factoryId: '',
    value: '',
  };

  constructor(props) {
    super(props);
    this.id = this.props.match.params.marketTaxId;
  }

  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps({ marketTaxes }) {
    const { loading, error, item } = marketTaxes;
    if (!loading && !error && item && this.id !== 'novo') {
      this.setState({
        categoryId: item.categoryId,
        factoryId: item.factoryId,
        value: item.value
      });
    }
  }

  fetchData = ()=>{
    if (this.id !== 'novo') {
      this.props.getMarketTax(this.id);
    }
  };

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { createMarketTax, updateMarketTax, match } = this.props;
    const { categoryId, factoryId, value } = this.state;
    if (this.id !== 'novo') {
      updateMarketTax(this.id, {
        categoryId,
        factoryId,
        value: parseFloat(value),
      });
    } else {
      createMarketTax({
        categoryId,
        factoryId,
        value: parseFloat(value),
        marketId: match.params.id
      });
    }
  };

  render() {
    const { classes, marketTaxes: { loading, list }, categories, factories } = this.props;
    const { categoryId, factoryId, value } = this.state;

    let categoryChoices = categories.list;
    let factoryChoices = factories.list;
    const isNew = this.id === 'novo';
    if (isNew) {
      const currentList = {};
      list.forEach(mt=>{
        const factories = currentList[mt.categoryId] || [];
        factories.push(mt.factoryId);
        currentList[mt.categoryId] = factories;
      });
      categoryChoices = categoryChoices
        .filter(cl=>{
          const list = currentList[cl.id];
          return !list || factories.list.find(fa=>!list.includes(fa.id))
        });
      const categoryList = currentList[categoryId];
      factoryChoices = factoryChoices.filter(fa=>!categoryList||!categoryList.includes(fa.id));
    }
    const invalidFactory = !!factoryId && !factoryChoices.find(fa=>fa.id===factoryId);
    
    return (
      <div className={classes.root}>
        <Typography variant="h4" component="h3" gutterBottom>
          {!isNew ? 'Editar' : 'Adicionar' } Taxa
        </Typography>
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Categoria"
                name="categoryId"
                value={categoryId}
                onChange={this.handleChange}
                disabled={!isNew}
                select
                fullWidth
              >
                {categoryChoices.map(ca=>(
                  <MenuItem key={ca.id} value={ca.id}>{ca.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Local de produção"
                name="factoryId"
                value={factoryId}
                onChange={this.handleChange}
                disabled={!isNew}
                error={invalidFactory}
                select
                fullWidth
              >
                {factoryChoices.map(fa=>(
                  <MenuItem key={fa.id} value={fa.id}>{fa.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Taxa"
                value={value}
                onChange={this.handleChange}
                name="value"
                type="number"
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading||invalidFactory}
              type="submit"
            >
              Salvar
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

MarketTaxForm.propTypes = {
  classes: PropTypes.object.isRequired,
  marketTaxes: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  factories: PropTypes.object.isRequired,
  createMarketTax: PropTypes.func.isRequired,
  updateMarketTax: PropTypes.func.isRequired,
  getMarketTax: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = ({ marketTaxes, categories, factories })=>{
  return { marketTaxes, categories, factories };
};

export default withStyles(styles)(connect(mapStateToProps, { createMarketTax, updateMarketTax, getMarketTax })(MarketTaxForm));