import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';

import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import red from '@material-ui/core/colors/red';

import SafeRemove from '../SafeRemove';

import { connect } from 'react-redux';

const styles = theme=>({
  red: {
    color: red[600],
  },
});

class RemoveButton extends PureComponent {
  render() {
    const { item, name, classes, size = "small", onDelete, datagridList: { loading } } = this.props;
    if (!get(item, 'id')) {
      return null;
    }
    return (
      <SafeRemove message={`Remover ${name(item)}?`} onRemove={onDelete} loading={loading}>
        <IconButton className={classes.red}>
          <DeleteIcon fontSize={size} />
        </IconButton>
      </SafeRemove>
    );
  }
};

RemoveButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  name: PropTypes.func.isRequired,
  item: PropTypes.object,
  size: PropTypes.string,
};

const mapStateToProps = ({ datagridList })=>{
  return { datagridList };
};

export default withStyles(styles)(connect(mapStateToProps)(RemoveButton));