const INIT_STATE = {
	loading: false,
	ready: false,
	success: false,
	accessToken: null,
	currentUser: null,
	error: false,
	errorMessage: null,
};

const reducer = (state = INIT_STATE, action)=>{
	switch (action.type) {
		case 'ACCESS_TOKEN_ALIVE':
			return { ...state, ready: true, currentUser: action.payload.currentUser };
		case 'ACCESS_TOKEN_DEAD':
			return { ...state, ready: true };
		case 'LOGIN_USER':
			return { ...state, loading: true, crendentials: action.payload.crendentials };
		case 'LOGIN_USER_SUCCESS':
			return { ...state, loading: false, error: false, currentUser: action.payload.currentUser };
		case 'LOGIN_USER_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message };
		case 'LOGOUT_USER':
			return { ...state, loading: false, error: false, accessToken: null, currentUser: null };
		case 'RECOVER_PASSWORD':
			return { ...state, loading: true, error: false };
		case 'RECOVER_PASSWORD_SUCCESS':
			return { ...state, loading: false, error: false }
		case 'RECOVER_PASSWORD_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message }
		case 'CHANGE_PASSWORD':
			return { ...state, success: false, loading: true, error: false };
		case 'CHANGE_PASSWORD_SUCCESS':
			return { ...state, success: true, loading: false, error: false }
		case 'CHANGE_PASSWORD_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message }
		default:
			return state;
	}
};

export default reducer;