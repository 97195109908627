import { POST, DELETE } from './utils/http-methods'
import { Resource } from './resource'

const FINISH = 'FINISH';
const CANCEL = 'CANCEL';
const CALCULATE = 'CALCULATE';

export class OrderResource extends Resource {
  constructor() {
    super('Order', 'orders');
    this.createEndpoint(FINISH, this._endpoints.BY_ID + '/finish');
    this.createEndpoint(CANCEL, this._endpoints.BY_ID + '/cancel');
    this.createEndpoint(CALCULATE, this._endpoints.BY_ID + '/calculate');
    this.createAction(FINISH, POST, FINISH);
    this.createAction(CANCEL, DELETE, CANCEL);
    this.createAction(CALCULATE, POST, CALCULATE);
  }

  finish = params=>this._fetchResource(FINISH, params);
  cancel = params=>this._fetchResource(CANCEL, params);
  calculate = params=>this._fetchResource(CALCULATE, params);
}