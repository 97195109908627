import React, { PureComponent, cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Route, Switch, withRouter } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import DatagridHeader from './DatagridHeader';
import DatagridItem from './DatagridItem';
import DatagridCell from './DatagridCell';
import EditButton from './EditButton';
import ShowButton from './ShowButton';
import RemoveButton from './RemoveButton';
import FabToolbar from '../FabToolbar';
import SideNavigation from '../SideNavigation';

import { connect } from 'react-redux';
import { getListItem, deleteListItem, resetItem, showSnackbar } from '../../redux/actions';
import { Resource } from '../../resources/resource';

import { flatMap, each, get, map } from 'lodash';

export { DatagridCell, EditButton, RemoveButton, ShowButton };

const styles = theme=>({
  root: {},
  paper: {},
  tableResponsive: {
    overflowX: 'auto',
  },
  tableWrap: {
    '& td': {
      whiteSpace: 'nowrap',
    },
  },
});

class Datagrid extends PureComponent {
  markedForDelete = null;

  constructor(props) {
    super(props);

    const { options, resource, name } = props;
    const { page = 0, perPage = 15 } = options;

    this.model = new Resource(name, resource);
    this.state = {
      count: 0,
      where: {},
      page,
      perPage,
    };
  }

  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps(props) {
    const { name, datagridList, showSnackbar, complex, params } = props;
    const { action, error, invalid, errorMessage, loading, data } = datagridList;
    if (!loading) {
      const count = parseInt(data.count, 10);
      if (count !== this.state.count) {
        this.setState({ count });
      }
      if (error && errorMessage !== this.props.datagridList.errorMessage) {
        showSnackbar('error', errorMessage);
      } else {
        if (action === 'DELETE') {
          showSnackbar('success', `${name} deletado com sucesso`);
        }
        if (invalid || this.props.params !== params) {
          if (!complex) {
            this.handleClose();
          }
          this.fetchData(props);
        }
      }
    }
  }

  handleChangePage = (event, page)=>{
    this.setState({ page }, this.fetchData);
  };

  handleChangeRowsPerPage = event=>{
    const perPage = event.target.value;
    this.setState({ perPage }, this.fetchData);
  };

  fetchData = (props = this.props)=>{
    const { getListItem, params } = props;
    const { page, perPage, where } = this.state;
    const offset = page * perPage;
    getListItem(this.model, offset, perPage, where, { order: [['id', 'DESC']], ...params});
  };

  handleRemove = itemId=>event=>{
    if (!itemId) {
      return;
    }
    const { deleteListItem } = this.props;
    deleteListItem(this.model, itemId);
  };

  handleClose = ()=>{
    const { history, match } = this.props;
    history.push(match.url);
  };

  render() {
    const { datagridList: { data }, classes, title, children, form, match, name, detailParams, resetItem, routes, readOnly } = this.props;
    const { page, perPage, count } = this.state;

    const content = [];
    const toolbar = [];
    const filters = [];

    Children.forEach(children, item=>{
      if (item) {
        const { prop, label, groupBy } = item.props;
        if (groupBy) {
          const keys = {};
          each(flatMap(data, prop), row=>{
            const key = get(row, groupBy);
            if (!keys[key]) {
              keys[key] = get(row, label);
            }
          });
          item = cloneElement(item, { keys });
        }

        if (item.props.filter) {
          filters.push(item);
        } else if (item.props.fab) {
          toolbar.push(item);
        } else {
          content.push(item);
        }
      }
    });

    return (
      <div className={classes.root}>
        {title&&(
          <Typography
            component="h2"
            variant="h4"
            gutterBottom
          >
            {title}
          </Typography>
        )}
        {filters}
        <Paper className={classes.paper}>
          <div className={classes.tableResponsive}>
            <Table className={classes.table} aria-labelledby={title} padding="dense">
              <DatagridHeader columns={content} data={data} />
              <TableBody className={classes.tableWrap}>
                {data.map(item=>(
                  <TableRow
                    hover
                    className={classes.row}
                    key={item.id}
                  >
                    {Children.map(content, (cell, key)=>(
                      cloneElement(cell, {
                        item,
                        onDelete: this.handleRemove(item.id),
                        key
                      })
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <Table className={classes.table}>
            <TableFooter>
              <TableRow>
                <TablePagination
                  labelRowsPerPage="Por página:"
                  rowsPerPageOptions={[5, 15, 30]}
                  rowsPerPage={perPage}
                  page={page}
                  count={count}
                  backIconButtonProps={{ 'aria-label': 'Página Anterior' }}
                  nextIconButtonProps={{ 'aria-label': 'Próxima Página' }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>

        {!!toolbar.length&&(
          <FabToolbar>
            {toolbar}
          </FabToolbar>
        )}

        {form&&(
          <SideNavigation
            open={!match.isExact}
            onClose={this.handleClose}
            onExited={resetItem}
          >
            <Switch>
              {map(routes, (props, index)=><Route key={index} {...props} />)}
              <Route path={`${match.path}/:id`} render={routeProps=>(
                <DatagridItem
                  {...routeProps}
                  readOnly={readOnly}
                  model={this.model}
                  component={form}
                  params={detailParams}
                  name={name}
                  onClose={this.handleClose}
                />
              )} />
            </Switch>
          </SideNavigation>
        )}
      </div>
    );
  }
};

Datagrid.propTypes = {
  classes: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  getListItem: PropTypes.func.isRequired,
  deleteListItem: PropTypes.func.isRequired,
  resetItem: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  name: PropTypes.string,
  title: PropTypes.string,
  options: PropTypes.object,
  params: PropTypes.object,
  detailParams: PropTypes.object,
  form: PropTypes.any,
  complex: PropTypes.bool,
  routes: PropTypes.array,
  readOnly: PropTypes.bool,
};

Datagrid.defaultProps = {
  name: 'Item',
  options: {},
};

const mapStateToProps = ({ datagridList })=>{
  return { datagridList };
};

export default withRouter(withStyles(styles)(connect(mapStateToProps, { getListItem, deleteListItem, resetItem, showSnackbar })(Datagrid)));