import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

export default class BillingForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.parseData(props.data);
  }

  componentWillReceiveProps({ data }) {
    if (data !== this.props.data) {
      this.setState(this.parseData(data));
    }
  }

  parseData = data=>{
    const { id, name = '' } = data || {};
    return {
      id,
      name
    };
  };

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { onSubmit } = this.props;
    onSubmit(this.state);
  }

  render() {
    const { classes, loading } = this.props;
    const { name } = this.state;
    
    return (
      <form autoComplete="off" onSubmit={this.handleSubmit}>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              label="Nome"
              name="name"
              value={name}
              onChange={this.handleChange}
              disabled={loading}
              fullWidth
            />
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Salvar
          </Button>
        </div>
      </form>
    );
  }
};