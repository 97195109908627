import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
  getFactoriesFailed,
  getFactoriesSuccess,
  createFactorySuccess,
  createFactoryFailed,
  deleteFactorySuccess,
  deleteFactoryFailed,
  getFactorySuccess,
  getFactoryFailed,
  updateFactorySuccess,
  updateFactoryFailed,
} from './actions';

const Factory = new Resource('Factory', 'factories');


// SAGAS
function* fetchFactories({ payload }) {
  const { offset, limit, where, params } = payload;
  try {
    const data = yield call(Factory.get, { params: { offset, limit, where, ...params } });
    yield put(getFactoriesSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getFactoriesFailed({ message: error.message }));
  }
}

function* createFactory({ payload }) {
  const { factory } = payload;
  try {
    yield call(Factory.create, { payload: factory });
    yield put(createFactorySuccess());
  } catch (error) {
    console.error("Error in saga", error);
    yield put(createFactoryFailed(error));
  }
}

function* deleteFactory({ payload }) {
  const { id } = payload;
  try {
    yield call(Factory.delete, { id });
    yield put(deleteFactorySuccess());
  } catch (error) {
    console.error("Error in saga", error);
    yield put(deleteFactoryFailed(error));
  }
}

function* getFactory({ payload }) {
  const { id } = payload;
  try {
    const data = yield call(Factory.getOne, { id });
    yield put(getFactorySuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getFactoryFailed(error));
  }
}

function* updateFactory({ payload }) {
  const { id, factory } = payload;
  try {
    const data = yield call(Factory.update, { id, payload: factory });
    yield put(updateFactorySuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(updateFactoryFailed(error));
  }
}


// WATCH FOR ACTIONS
export function* watchFetchFactories() {
  yield takeEvery("GET_FACTORIES", fetchFactories);
}

export function* watchGetFactory() {
  yield takeEvery("GET_FACTORY", getFactory);
}

export function* watchCreateFactory() {
  yield takeEvery("CREATE_FACTORY", createFactory);
}

export function* watchDeleteFactory() {
  yield takeEvery("DELETE_FACTORY", deleteFactory);
}

export function* watchUpdateFactory() {
  yield takeEvery("UPDATE_FACTORY", updateFactory);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchFactories),
    fork(watchGetFactory),
    fork(watchCreateFactory),
    fork(watchDeleteFactory),
    fork(watchUpdateFactory),
    fork(watchUpdateFactory),
  ]);
};
