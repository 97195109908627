import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { createProductCategory, updateProductCategory, getProductCategory } from '../../redux/actions';
import { connect } from 'react-redux';

const styles = theme=>({
  buttons: {
    marginTop: theme.spacing.unit*3,
  },
});

class ProductCategoryForm extends Component {
  state = {
    name: '',
    categoryId: ''
  };

  constructor(props) {
    super(props);
    this.id = this.props.match.params.categoryId;
  }

  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps({ productCategories }) {
    const { loading, error, item } = productCategories;
    if (!loading && !error && item && this.id !== 'novo') {
      this.setState({ name: item.name || '', categoryId: item.categoryId });
    }
  }

  fetchData = ()=>{
    if (this.id !== 'novo') {
      this.props.getProductCategory(this.id);
    }
  };

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { createProductCategory, updateProductCategory, match } = this.props;
    const { name, categoryId } = this.state;
    if (this.id !== 'novo') {
      updateProductCategory(this.id, { name: name || null, categoryId });
    } else {
      createProductCategory({ name: name || null, categoryId, productId: match.params.id });
    }
  };

  render() {
    const { classes, productCategories: { loading, list }, categories } = this.props;
    const { name, categoryId } = this.state;
    const currentCategories = list.map(pc=>pc.categoryId);
    const categoryChoices = categories.list.filter(b=>b.id===categoryId||!currentCategories.includes(b.id));
    
    return (
      <div className={classes.root}>
        <Typography variant="h4" component="h3" gutterBottom>
          {this.id !== 'novo' ? 'Editar' : 'Adicionar' } Categoria
        </Typography>
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Categoria"
                name="categoryId"
                value={categoryId}
                onChange={this.handleChange}
                select
                fullWidth
              >
                {categoryChoices.map(b=>(
                  <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Nome do produto"
                name="name"
                value={name}
                onChange={this.handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              type="submit"
            >
              Salvar
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

ProductCategoryForm.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  productCategories: PropTypes.object.isRequired,
  createProductCategory: PropTypes.func.isRequired,
  updateProductCategory: PropTypes.func.isRequired,
  getProductCategory: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = ({ productCategories, categories })=>{
  return { productCategories, categories };
};

export default withStyles(styles)(connect(mapStateToProps, { createProductCategory, updateProductCategory, getProductCategory })(ProductCategoryForm));