import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';

import { changePassword, showSnackbar } from '../../redux/actions';
import AppLogin from '../../components/AppLogin';

const styles = theme=>({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit*2,
  }
});

class ChangePassword extends Component {
  constructor(props) {
    super(props);

    const { location, history } = this.props;
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (!token) {
      history.replace('/');
    }

    this.state = {
      password: '',
      passwordConfirmation: '',
      token
    };
  }

  componentWillReceiveProps(nextProps) {
    const { showSnackbar, history } = this.props;
    const { loading, error, success, errorMessage } = nextProps.auth;
    if (!loading) {
      if (error) {
        showSnackbar('error', errorMessage);
      } else if (success) {
        showSnackbar('success', `Senha alterada com sucesso!`);
        history.push('/');
      }
    }
  }

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { password, passwordConfirmation, token } = this.state;
    const { changePassword, showSnackbar } = this.props;
    if (password !== passwordConfirmation) {
      return showSnackbar('error', `Os campos de senha e confirmação de senha não conferem.`)
    }
    changePassword(password, token);
  };

  render() {
    const { classes, loading } = this.props;
    const { password, passwordConfirmation } = this.state;
    
    return (
      <AppLogin title="Redefinir senha">
        <form className={classes.form} onSubmit={this.handleSubmit}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Nova senha</InputLabel>
            <Input
              id="password"
              name="password"
              type="password"
              onChange={this.handleChange}
              value={password}
              autoFocus
            />
          </FormControl>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="passwordConfirmation">Confirmação de senha</InputLabel>
            <Input
              id="passwordConfirmation"
              name="passwordConfirmation"
              type="password"
              onChange={this.handleChange}
              value={passwordConfirmation}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            Alterar
          </Button>
        </form>
      </AppLogin>
    );
  }
}

ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired,
  changePassword: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth })=>{
  return { auth };
};

export default withStyles(styles)(connect(mapStateToProps, { changePassword, showSnackbar })(ChangePassword));