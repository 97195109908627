export const checkSession = ()=>({
  type: "CHECK_ACCESS_TOKEN_ALIVE",
  payload: {}
});

export const checkSessionSuccess = payload=>({
  type: "ACCESS_TOKEN_ALIVE",
  payload
});

export const checkSessionError = ()=>({
  type: "ACCESS_TOKEN_DEAD",
  payload: {}
});

export const loginUser = crendentials=>({
  type: "LOGIN_USER",
  payload: { crendentials }
});

export const loginUserSuccess = payload=>({
  type: "LOGIN_USER_SUCCESS",
  payload
});

export const loginUserError = payload=>({
  type: "LOGIN_USER_FAILED",
  payload
});

export const logoutUser = ()=>({
  type: "LOGOUT_USER",
  payload: {}
});

export const recoverPassword = email=>({
  type: "RECOVER_PASSWORD",
  payload: { email }
});

export const recoverPasswordSuccess = payload=>({
  type: "RECOVER_PASSWORD_SUCCESS",
  payload
});

export const recoverPasswordError = payload=>({
  type: "RECOVER_PASSWORD_FAILED",
  payload
});

export const changePassword = (password, token)=>({
  type: "CHANGE_PASSWORD",
  payload: { password, token }
});

export const changePasswordSuccess = payload=>({
  type: "CHANGE_PASSWORD_SUCCESS",
  payload
});

export const changePasswordError = payload=>({
  type: "CHANGE_PASSWORD_FAILED",
  payload
});