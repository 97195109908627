import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  getItemSuccess,
  getItemFailed,
  createItemSuccess,
  createItemFailed,
  deleteItemSuccess,
  deleteItemFailed,
  updateItemSuccess,
  updateItemFailed,
} from './actions';

// SAGAS
function* getItem({ payload }) {
  const { model, id, params } = payload;
  try {
    const response = yield call(model.getOne, { id, params });
    yield put(getItemSuccess(response));
  } catch (error) {
    console.error("Error in getItem saga", error);
    yield put(getItemFailed(error));
  }
}

function* createItem({ payload }) {
  const { model, data } = payload;
  try {
    const response = yield call(model.create, { payload: data });
    yield put(createItemSuccess(response));
  } catch (error) {
    console.error("Error in createItem saga", error);
    yield put(createItemFailed(error));
  }
}

function* updateItem({ payload }) {
  const { model, id, data } = payload;
  try {
    const response = yield call(model.update, { id, payload: data });
    yield put(updateItemSuccess(response));
  } catch (error) {
    console.error("Error in updateItem saga", error);
    yield put(updateItemFailed(error));
  }
}

function* deleteItem({ payload }) {
  const { model, id } = payload;
  try {
    yield call(model.delete, { id });
    yield put(deleteItemSuccess());
  } catch (error) {
    console.error("Error in deleteItem saga", error);
    yield put(deleteItemFailed(error));
  }
}

// WATCH FOR ACTIONS
export function* watchGetItem() {
  yield takeEvery("GET_ITEM", getItem);
}

export function* watchCreateItem() {
  yield takeEvery("CREATE_ITEM", createItem);
}

export function* watchUpdateItem() {
  yield takeEvery("UPDATE_ITEM", updateItem);
}

export function* watchDeleteItem() {
  yield takeEvery("DELETE_ITEM", deleteItem);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchGetItem),
    fork(watchCreateItem),
    fork(watchUpdateItem),
    fork(watchDeleteItem),
  ]);
}
