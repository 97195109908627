import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { createFactoryBilling, updateFactoryBilling, getFactoryBilling } from '../../redux/actions';
import { connect } from 'react-redux';

const styles = theme=>({
  buttons: {
    marginTop: theme.spacing.unit*3,
  },
});

class FactoryBillingForm extends Component {
  state = {
    billingId: '',
    icms: '',
    margin: '',
  };

  constructor(props) {
    super(props);
    this.id = this.props.match.params.factoryBillingId;
  }

  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps({ factoryBillings }) {
    const { loading, error, item } = factoryBillings;
    if (!loading && !error && item && this.id !== 'novo') {
      this.setState({ billingId: item.billingId, icms: item.icms, margin: item.margin || '' });
    }
  }

  fetchData = ()=>{
    if (this.id !== 'novo') {
      this.props.getFactoryBilling(this.id);
    }
  };

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { createFactoryBilling, updateFactoryBilling, match } = this.props;
    const { billingId, icms, margin } = this.state;
    if (this.id !== 'novo') {
      updateFactoryBilling(this.id, {
        billingId,
        icms: parseFloat(icms),
        margin: parseFloat(margin)
      });
    } else {
      createFactoryBilling({
        billingId,
        icms: parseFloat(icms),
        margin: parseFloat(margin),
        factoryId: match.params.id
      });
    }
  };

  render() {
    const { classes, factoryBillings: { loading, list }, billings } = this.props;
    const { billingId, icms, margin } = this.state;
    const currentBillings = list.map(fb=>fb.billingId);
    const billingChoices = billings.list.filter(b=>b.id===billingId||!currentBillings.includes(b.id));

    return (
      <div className={classes.root}>
        <Typography variant="h4" component="h3" gutterBottom>
          {this.id !== 'novo' ? 'Editar' : 'Adicionar' } Faturamento
        </Typography>
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Local de faturamento"
                name="billingId"
                value={billingId}
                onChange={this.handleChange}
                select
                fullWidth
              >
                {billingChoices.map(b=>(
                  <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="ICMS"
                value={icms}
                onChange={this.handleChange}
                name="icms"
                type="number"
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Margem de transferência"
                value={margin}
                onChange={this.handleChange}
                name="margin"
                type="number"
                fullWidth
              />
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              type="submit"
            >
              Salvar
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

FactoryBillingForm.propTypes = {
  classes: PropTypes.object.isRequired,
  factoryBillings: PropTypes.object.isRequired,
  billings: PropTypes.object.isRequired,
  createFactoryBilling: PropTypes.func.isRequired,
  updateFactoryBilling: PropTypes.func.isRequired,
  getFactoryBilling: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = ({ factoryBillings, billings })=>{
  return { factoryBillings, billings };
};

export default withStyles(styles)(connect(mapStateToProps, { createFactoryBilling, updateFactoryBilling, getFactoryBilling })(FactoryBillingForm));