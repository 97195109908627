import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
  getProductCategoriesFailed,
  getProductCategoriesSuccess,
  createProductCategorySuccess,
  createProductCategoryFailed,
  deleteProductCategorySuccess,
  deleteProductCategoryFailed,
  getProductCategorySuccess,
  getProductCategoryFailed,
  updateProductCategorySuccess,
  updateProductCategoryFailed,
} from './actions';

const ProductCategory = new Resource('ProductCategory', 'productCategories');

const include = [
  {
    model: 'Category',
    required: true,
    include: {
      model: 'Measure',
      include: {
        model: 'Unit'
      }
    }
  },
];


// SAGAS
function* fetchProductCategories({ payload }) {
  const { offset, limit, where } = payload;
  try {
    const data = yield call(ProductCategory.get, { params: { offset, limit, where, include } });
    yield put(getProductCategoriesSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getProductCategoriesFailed({ message: error.message }));
  }
}

function* createProductCategory({ payload }) {
  const { productCategory } = payload
  try {
    const data = yield call(ProductCategory.create, { payload: productCategory });
    yield put(createProductCategorySuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(createProductCategoryFailed(error));
  }
}

function* deleteProductCategory({ payload }) {
  const { id } = payload
  try {
    yield call(ProductCategory.delete, { id });
    yield put(deleteProductCategorySuccess());
  } catch (error) {
    console.error("Error in saga", error);
    yield put(deleteProductCategoryFailed(error));
  }
}

function* getProductCategory({ payload }) {
  const { id } = payload
  try {
    const data = yield call(ProductCategory.getOne, { id, params: { include } });
    yield put(getProductCategorySuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getProductCategoryFailed(error));
  }
}

function* updateProductCategory({ payload }) {
  const { id, productCategory } = payload;
  try {
    const data = yield call(ProductCategory.update, { id, payload: productCategory });
    yield put(updateProductCategorySuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(updateProductCategoryFailed(error));
  }
}


// WATCH FOR ACTIONS
export function* watchFetchProductCategories() {
  yield takeEvery("GET_PRODUCT_CATEGORIES", fetchProductCategories);
}

export function* watchGetProductCategory() {
  yield takeEvery("GET_PRODUCT_CATEGORY", getProductCategory);
}

export function* watchCreateProductCategory() {
  yield takeEvery("CREATE_PRODUCT_CATEGORY", createProductCategory);
}

export function* watchDeleteProductCategory() {
  yield takeEvery("DELETE_PRODUCT_CATEGORY", deleteProductCategory);
}

export function* watchUpdateProductCategory() {
  yield takeEvery("UPDATE_PRODUCT_CATEGORY", updateProductCategory);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchProductCategories),
    fork(watchGetProductCategory),
    fork(watchCreateProductCategory),
    fork(watchDeleteProductCategory),
    fork(watchUpdateProductCategory),
  ]);
}
