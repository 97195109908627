export const getProducts = (offset, limit, filter = {}, params)=>({
  type: "GET_PRODUCTS",
  payload: { offset, limit, where: filter, params }
});

export const getProductsSuccess = data=>({
  type: "GET_PRODUCTS_SUCCESS",
  payload: { data }
});

export const getProductsFailed = error=>({
  type: "GET_PRODUCTS_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter produtos" }
});

export const getProduct = id=>({
  type: "GET_PRODUCT",
  payload: { id }
});

export const getProductSuccess = data=>({
  type: "GET_PRODUCT_SUCCESS",
  payload: { data }
});

export const getProductFailed = error=>({
  type: "GET_PRODUCT_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter produto" }
});

export const createProduct = product=>({
  type: "CREATE_PRODUCT",
  payload: { product }
});

export const createProductSuccess = data=>({
  type: "CREATE_PRODUCT_SUCCESS",
  payload: { data }
});

export const createProductFailed = error=>({
  type: "CREATE_PRODUCT_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar produto" }
});

export const updateProduct = (id, product)=>({
  type: "UPDATE_PRODUCT",
  payload: { id, product }
});

export const updateProductSuccess = data=>({
  type: "UPDATE_PRODUCT_SUCCESS",
  payload: { data }
});

export const updateProductFailed = error=>({
  type: "UPDATE_PRODUCT_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar produto" }
});

export const deleteProduct = id=>({
  type: "DELETE_PRODUCT",
  payload: { id }
});

export const deleteProductSuccess = ()=>({
  type: "DELETE_PRODUCT_SUCCESS",
  payload: {}
});

export const deleteProductFailed = error=>({
  type: "DELETE_PRODUCT_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar produto" }
});