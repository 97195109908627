import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { getPriceLogs, rebasePrices,  invalidateListItem, showSnackbar } from '../../redux/actions';
import { connect } from 'react-redux';

import { get, map, sortBy } from 'lodash';

const styles = theme=>({
  button: {
    marginBottom: theme.spacing.unit*3,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium
  },
  textMuted: {
    color: theme.palette.text.secondary,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  center: {
    alignItems: 'center',
  },
  noPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
});

class UpdatePricesList extends Component {
  state = {
    expanded: null,
  };

  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps(props) {
    const { showSnackbar, priceLogs, invalidateListItem } = props;
    const { loading, error, item, errorMessage, action } = priceLogs;

    if (!loading) {
      if (error !== this.props.priceLogs.error) {
        showSnackbar('error', errorMessage);
      } else if (action) {
        switch (action) {
          case 'UPDATED':
            showSnackbar('success', `Preços atualizados com sucesso`);
          break;
          default:
            console.info('unknown action:', action);
          break;
        }
        invalidateListItem();
        this.setState({ expanded: item.id });
        this.fetchData();
      }
    }
  }

  handleChange = panel=>(event, expanded)=>{
    this.setState({
      expanded: expanded ? panel : null,
    });
  };

  fetchData = ()=>{
    const { getPriceLogs } = this.props;
    getPriceLogs(0, null, null, {
      order: [['id', 'DESC']],
      include: [{
        model: 'User',
        include: 'Profile'
      }, {
        model: 'PriceLogProduct',
        include: 'Product'
      }]
    });
  };

  rebase = priceLogId=>event=>{
    this.props.rebasePrices(priceLogId);
  };

  render() {
    const { classes, priceLogs: { list, loading }, match } = this.props;
    const { expanded } = this.state;

    return (
      <div>
        <Button
          component={Link}
          to={{
            pathname: `${match.url}/atualizar`,
            state: {
              backUrl: match.url
            }
          }}
          variant="contained"
          color="primary"
          className={classes.button}
        >
          Nova atualização de preços
        </Button>
        <div>
          {
            list.map(priceLog=>{
              const author = get(priceLog, 'User.Profile') || {};
              return (
                <ExpansionPanel key={priceLog.id} expanded={expanded===priceLog.id} onChange={this.handleChange(priceLog.id)}>
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid className={classes.center} container spacing={16}>
                      <Grid xs={2} md={1} item>
                        <Typography className={classes.textMuted}>
                          {priceLog.id}
                        </Typography>
                      </Grid>
                      <Grid item>
                         <Typography variant="overline">{moment(priceLog.createdAt).format('DD/MM/YYYY')}</Typography>
                      </Grid>
                      <Grid xs={6} sm={4} item>
                        <Typography className={classes.heading}>
                          {priceLog.name}
                        </Typography>
                      </Grid>
                      <Grid xs={2} md={1} item>
                        <Typography className={classes.secondaryHeading}>
                          {priceLog.amount}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography>{author.firstName} {author.lastName}</Typography>
                      </Grid>
                    </Grid>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails className={classes.noPadding}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Produto</TableCell>
                          <TableCell align="right">Preço antes</TableCell>
                          <TableCell align="right">Preço depois</TableCell>
                        </TableRow>
                      </TableHead>
                      {map(sortBy(get(priceLog, 'PriceLogProducts'), 'Product.name'), ({ id, Product, before, after })=>{
                        return (
                          <TableBody key={id}>
                            <TableRow>
                              <TableCell>{get(Product, 'name')}</TableCell>
                              <TableCell align="right">{before}</TableCell>
                              <TableCell align="right">{after}</TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })}
                    </Table>
                  </ExpansionPanelDetails>
                  <ExpansionPanelActions>
                    <Button
                      size="small"
                      color="primary"
                      disabled={loading}
                      onClick={this.rebase(priceLog.id)}
                    >
                      Desfazer essa ação
                    </Button>
                  </ExpansionPanelActions>
                </ExpansionPanel>
              );
            })
          }
        </div>
      </div>
    );
  }
};

UpdatePricesList.propTypes = {
  classes: PropTypes.object.isRequired,
  priceLogs: PropTypes.object.isRequired,
  getPriceLogs: PropTypes.func.isRequired,
  rebasePrices: PropTypes.func.isRequired,
  invalidateListItem: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = ({ priceLogs })=>{
  return { priceLogs };
};

export default withStyles(styles)(connect(mapStateToProps, { getPriceLogs, rebasePrices, invalidateListItem, showSnackbar })(UpdatePricesList));