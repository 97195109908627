import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import { getProductValues, showSnackbar, invalidateListItem } from '../../redux/actions';
import { connect } from 'react-redux';

import { map, flatMap, uniqBy, find, reject, sortBy } from 'lodash';

import ProductValueForm from './value';

class ProductValueList extends PureComponent {
  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps(props) {
    const { showSnackbar, productValues, where, invalidateListItem } = props;
    const { loading, error, errorMessage, action } = productValues;

    if (where !== this.props.where) {
      this.fetchData(props);
    }
    if (!loading) {
      if (error) {
        showSnackbar('error', errorMessage);
      } else if (action) {
        switch (action) {
          case 'CREATED':
            showSnackbar('success', `Valor adicionado com sucesso`);
          break;
          case 'UPDATED':
            showSnackbar('success', `Valor atualizado com sucesso`);
          break;
          case 'DELETED':
            showSnackbar('success', `Valor removido com sucesso`);
          break;
          default:
            console.info('unknown action:', action);
          break;
        }
        invalidateListItem();
        this.fetchData(props);
      }
    }
  }

  fetchData = (props = this.props)=>{
    const { getProductValues, where } = props;
    if (where) {
      getProductValues(0, null, where);
    }
  };

  render() {
    const { productCategories, productValues, where, children } = this.props;

    const measures = flatMap(productCategories.list, 'Category.Measures');
    const units = map(uniqBy(measures, 'unitId'), 'Unit');

    const productUnits = reject(map(productValues.list, 'Unit'), u=>!!find(units, {id: u.id}));

    if (!where || !units.concat(productUnits).length) {
      return null;
    }

    return (
      <Fragment>
        {children}
        <Grid container spacing={16}>
          {sortBy(units, 'id').map(unit=>(
            <Grid key={unit.id} xs={12} sm={6} md={4} lg={3} xl={2} item>
              <ProductValueForm
                unit={unit}
                where={where}
                data={find(productValues.list, {unitId: unit.id})}
              />
            </Grid>
          ))}
          {sortBy(productUnits, 'id').map(unit=>(
            <Grid key={unit.id} xs={12} sm={6} md={4} lg={3} xl={2} item>
              <ProductValueForm
                unit={unit}
                where={where}
                data={find(productValues.list, {unitId: unit.id})}
                removable
              />
            </Grid>
          ))}
        </Grid>
      </Fragment>
    );
  }
};

ProductValueList.propTypes = {
  productCategories: PropTypes.object.isRequired,
  productValues: PropTypes.object.isRequired,
  getProductValues: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  invalidateListItem: PropTypes.func.isRequired,
  where: PropTypes.object,
  children: PropTypes.node,
};

const mapStateToProps = ({ productCategories, productValues })=>{
  return { productCategories, productValues };
};

export default connect(mapStateToProps, { getProductValues, showSnackbar, invalidateListItem })(ProductValueList);