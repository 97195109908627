import {
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  ACTION_METHOD,
  GET_MANY
} from './utils';
import _ from 'lodash';
import Auth from './auth';
const { REACT_APP_API_URL } = process.env;

export class Resource {
  constructor(name, pathname) {
    this.name = name;
    this.pathname = pathname;
    this.API_URL = REACT_APP_API_URL;
    this._endpoints = {
      BASE: `${this.API_URL}/${this.pathname}`,
      BY_ID: `${this.API_URL}/${this.pathname}/:id`
    };
    this._actionMethods = ACTION_METHOD;
    this._actions = {
      GET_ONE,
      GET_MANY,
      CREATE,
      UPDATE,
      DELETE
    };
    this._actionUrls = this._getActionUrls(this._actions);
  }

  get = params=>this._fetchResource(GET_MANY, params);
  getOne = params=>this._fetchResource(GET_ONE, params);
  create = params=>this._fetchResource(CREATE, params);
  update = params=>this._fetchResource(UPDATE, params);
  delete = params=>this._fetchResource(DELETE, params);

  getMethod(action) {
    return this._actionMethods[action]
  }

  createAction(action, httpMethod, endpointName) {
    this._actions[action] = action
    this._setActionHttpMethod(action, httpMethod)
    let url = this._endpoints[endpointName]
    if (!url) throw new Error("Endpoint with name " + endpointName + " not found.")
    this._setActionUrl(action, url)
  }

  createEndpoint(endpointName, url) {
    this._endpoints[endpointName] = url
  }

  _setActionHttpMethod(action, httpMethod) {
    this._actionMethods[action] = httpMethod
  }

  _setActionUrl(action, url) {
    this._actionUrls[action] = url
  }

  _getActionUrls(actions) {
    return Object.keys(actions)
      .reduce((o, action)=>{
        let url
        switch (action) {
          case GET_MANY:
          case CREATE:
            url = this._endpoints.BASE
            break;
          case UPDATE:
          case GET_ONE:
          case DELETE:
            url = this._endpoints.BY_ID
            break;
          default:
            break;
        }

        if (url) {
          o[action] = url
        }
        return o
      }, {})
  }

  async _fetchResource(action, { id, params, payload }) {
    let url = this._mapAndValidateUrl(action, id, payload);

    const method = this.getMethod(action);
    if (!method) return Promise.reject("No method found")

    if (params) {
      url += `?q=${JSON.stringify(params)}`;
    }

    const opts = {
      method,
      headers: {
        "Authorization": `Bearer ${Auth.accessToken}`
      },
    };

    if (payload) {
      opts.body = JSON.stringify(payload);
      opts.headers['Content-type'] = "application/json; charset=utf-8";
    }

    return fetch(url, opts)
      .then(response=>{
        if (response.status === 204) {
          return;
        }
        return response.json()
          .then(responseJson=>{
            if (response.status < 200 || response.status > 299) {
              responseJson.status = response.status;
              return Promise.reject(responseJson);
            } else {
              responseJson.count = response.headers.get('X-Total-Count');
              return responseJson;
            }
          });
      });
  }

  _mapAndValidateUrl(action, id, payload) {
    let url = this._actionUrls[action]
    const { isValid, reason } = this._validateUrl(action, id, payload)
    if (!isValid) throw new Error(`Invalid url: ${reason}`)
    url = url.replace(':id', id)
    return url
  }

  _validateUrl(action, id, payload) {
    let isValid = true
    let reasons = []
    const invalidId = "ID not provided"
    const invalidPayload = "Payload not provided"
      
    switch (action) {
      case GET_ONE:
      case DELETE:
        if (!id) {
          isValid = false
          reasons.push(invalidId)
        }
        break;
      case CREATE:
        if (!payload) {
          isValid = false
          reasons.push(invalidPayload)
        }
        break;
      case UPDATE:
        if (!id) {
          isValid = false
          reasons.push(invalidId)
        }
        if (!payload) {
          isValid = false
          reasons.push(invalidPayload)
        }
        break;
      default:
        break;
    }
    return { isValid, reason: _.uniq(reasons).join(', ') }
  }

}