import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
  getCategoriesFailed,
  getCategoriesSuccess,
  createCategorySuccess,
  createCategoryFailed,
  deleteCategorySuccess,
  deleteCategoryFailed,
  getCategorySuccess,
  getCategoryFailed,
  updateCategorySuccess,
  updateCategoryFailed,
} from './actions';

const Category = new Resource('Category', 'categories')


// SAGAS
function* fetchCategories({ payload }) {
  const { offset, limit, where, params } = payload;
  try {
    const data = yield call(Category.get, { params: { offset, limit, where, ...params } });
    yield put(getCategoriesSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getCategoriesFailed({ message: error.message }));
  }
}

function* createCategory({ payload }) {
  const { category } = payload;
  try {
    const data = yield call(Category.create, { payload: category });
    yield put(createCategorySuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(createCategoryFailed(error));
  }
}

function* deleteCategory({ payload }) {
  const { id } = payload;
  try {
    yield call(Category.delete, { id });
    yield put(deleteCategorySuccess());
  } catch (error) {
    console.error("Error in saga", error);
    yield put(deleteCategoryFailed(error));
  }
}

function* getCategory({ payload }) {
  const { id } = payload;
  try {
    const include = {
      model: 'Measure',
      include: {
        model: 'Unit',
        required: true
      }
    };
    const data = yield call(Category.getOne, { id, params:{ include } });
    yield put(getCategorySuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getCategoryFailed(error));
  }
}

function* updateCategory({ payload }) {
  const { id, category } = payload;
  try {
    const data = yield call(Category.update, { id, payload: category });
    yield put(updateCategorySuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(updateCategoryFailed(error));
  }
}


// WATCH FOR ACTIONS
export function* watchFetchCategories() {
  yield takeEvery("GET_CATEGORIES", fetchCategories);
}

export function* watchGetCategory() {
  yield takeEvery("GET_CATEGORY", getCategory);
}

export function* watchCreateCategory() {
  yield takeEvery("CREATE_CATEGORY", createCategory);
}

export function* watchDeleteCategory() {
  yield takeEvery("DELETE_CATEGORY", deleteCategory);
}

export function* watchUpdateCategory() {
  yield takeEvery("UPDATE_CATEGORY", updateCategory);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchCategories),
    fork(watchGetCategory),
    fork(watchCreateCategory),
    fork(watchDeleteCategory),
    fork(watchUpdateCategory),
  ]);
}
