import React, { Component } from 'react';
import { map, get, sortBy } from 'lodash';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

import OrderViews from './variables';
import OrderStatuses from './statuses';

class OrderForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.parseData(props.data);
  }

  componentWillReceiveProps({ data }) {
    if (data !== this.props.data) {
      this.setState(this.parseData(data), this.handleChangeCategory);
    }
  }

  parseData = data=>{
    const {
      client = '',
      application = ''
    } = data || {};

    return {
      client,
      application,
    };
  };

  handleChange = event=>{
    const { name, value } = event.target;
    this.updateData({ [name]: value });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { onSubmit, orders: { loading } } = this.props;
    const { client, application } = this.state;

    if (!loading) {
      onSubmit({ client, application });
    }
  };

  render() {
    const { data } = this.props;
    const { client, application } = this.state;
    const {
      code = '',
      categoryId = '',
      productId = '',
      factoryId = '',
      factoryBillingId = '',
      shippingId = '',
      marketId = '',
      Category = {},
      Product = {},
      Factory = {},
      FactoryBilling = {},
      Market = {},
      Shipping = {},
      OrderInputs = [],
    } = data || {};

    return (
      <form autoComplete="off" onSubmit={this.handleSubmit}>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              label="Código"
              value={code}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              label="Categoria"
              value={categoryId}
              disabled
              fullWidth
              select
            >
              <MenuItem value={Category.id}>
                {Category.name}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={8} lg={6} xl={4}>
            <TextField
              label="Produto"
              value={productId}
              disabled
              fullWidth
              select
            >
              <MenuItem value={Product.id}>
                {Product.name}
              </MenuItem>
            </TextField>
          </Grid>
          {map(sortBy(OrderInputs, 'inputId'), ({ Input: input = {}, value, id })=>{
            let helper = '';
            if (input.min) {
              helper += `mínimo ${input.min}`;
              if (input.max) {
                helper += ', ';
              }
            }
            if (input.max) {
              helper += `máximo ${input.max}`;
            }
            const props = {};
            if (input.unit) {
              props.InputProps = {
                endAdornment: <InputAdornment position="end">{input.unit}</InputAdornment>,
              };
            }
            return (
              <Grid key={id} item xs={12} sm={6} md={4} lg={3} xl={2}>
                <TextField
                  {...props}
                  label={input.name}
                  value={value}
                  helperText={helper}
                  type="number"
                  disabled
                  fullWidth
                />
              </Grid>
            );
          })}
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              label="Origem"
              value={factoryId}
              disabled
              fullWidth
              select
            >
              <MenuItem value={Factory.id}>
                {Factory.name}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              label="Local de faturamento"
              value={factoryBillingId}
              disabled
              fullWidth
              select
            >
              <MenuItem value={FactoryBilling.id}>
                {get(FactoryBilling, 'Billing.name')}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              label="Frete"
              value={shippingId||0}
              disabled
              fullWidth
              select
            >
              <MenuItem value={0}>FOB</MenuItem>
              {Shipping&&(
                <MenuItem value={Shipping.id}>
                  {Shipping.name}
                </MenuItem>
              )}
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              label="Mercado"
              value={marketId}
              disabled
              fullWidth
              select
            >
              <MenuItem value={Market.id}>
                {Market.name}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              label="Cliente"
              name="client"
              value={client}
              onChange={this.handleChange}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              name="application"
              label="Aplicação"
              value={application}
              onChange={this.handleChange}
              disabled
              fullWidth
            />
          </Grid>
        </Grid>
        <OrderViews order={data} />
        <OrderStatuses order={data} />
      </form>
    );
  }
};

export default OrderForm;