import {
  SHOW_SNACKBAR,
  CLOSE_SNACKBAR,
} from './actions';

const INIT_STATE = {
  message: 'Teste',
  variant: 'success',
  isOpen: true
};

const reducer = (state = INIT_STATE, action)=>{
  switch (action.type) {
    case SHOW_SNACKBAR:
      return { ...state, isOpen: true, message: action.payload.message, variant: action.payload.variant }
    case CLOSE_SNACKBAR:
      return { ...state, isOpen: false }
    default:
      return state
  }
}

export default reducer