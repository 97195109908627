export const getBillings = (offset, limit, filter = {}, params)=>({
  type: "GET_BILLINGS",
  payload: { offset, limit, where: filter, params }
});

export const getBillingsSuccess = data=>({
  type: "GET_BILLINGS_SUCCESS",
  payload: { data }
});

export const getBillingsFailed = error=>({
  type: "GET_BILLINGS_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter faturamentos" }
});

export const getBilling = id=>({
  type: "GET_BILLING",
  payload: { id }
});

export const getBillingSuccess = data=>({
  type: "GET_BILLING_SUCCESS",
  payload: { data }
});

export const getBillingFailed = error=>({
  type: "GET_BILLING_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter faturamento" }
});

export const createBilling = billing=>({
  type: "CREATE_BILLING",
  payload: { billing }
});

export const createBillingSuccess = ()=>({
  type: "CREATE_BILLING_SUCCESS",
  payload: {}
});

export const createBillingFailed = error=>({
  type: "CREATE_BILLING_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar faturamento" }
});

export const updateBilling = (id, billing)=>({
  type: "UPDATE_BILLING",
  payload: { id, billing }
});

export const updateBillingSuccess = ()=>({
  type: "UPDATE_BILLING_SUCCESS",
  payload: {}
});

export const updateBillingFailed = error=>({
  type: "UPDATE_BILLING_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar faturamento" }
});

export const deleteBilling = id=>({
  type: "DELETE_BILLING",
  payload: { id }
});

export const deleteBillingSuccess = ()=>({
  type: "DELETE_BILLING_SUCCESS",
  payload: {}
});

export const deleteBillingFailed = error=>({
  type: "DELETE_BILLING_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar faturamento" }
});