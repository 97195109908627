import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import { getInputs, deleteInput, invalidateListItem, showSnackbar } from '../../redux/actions';
import { connect } from 'react-redux';

import { get, sortBy } from 'lodash';

import { sortByLetter } from './values';

const styles = theme=>({
  chipItem: {
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
  buttons: {
    marginTop: theme.spacing.unit*2,
  },
  muted: {
    color: theme.palette.grey[600]
  },
});

class InputList extends PureComponent {
  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps(props) {
    const { showSnackbar, inputs, where } = props;
    const { loading, error, errorMessage, action } = inputs;

    if (where !== this.props.where) {
      this.fetchData(props);
    }
    if (!loading) {
      if (error) {
        showSnackbar('error', errorMessage);
      } else if (action) {
        switch (action) {
          case 'CREATED':
            showSnackbar('success', `Entrada adicionada com sucesso`);
          break;
          case 'UPDATED':
            showSnackbar('success', `Entrada atualizada com sucesso`);
          break;
          case 'DELETED':
            showSnackbar('success', `Entrada removida com sucesso`);
          break;
          default:
            console.info('unknown action:', action);
          break;
        }
        invalidateListItem();
        this.fetchData(props);
      }
    }
  }

  fetchData = (props = this.props)=>{
    const { getInputs, where } = props;
    if (where) {
      getInputs(0, null, where);
    }
  };

  handleRemoveInput = inputId=>event=>{
    event.preventDefault();
    const { deleteInput } = this.props;
    deleteInput(inputId);
  };

  render() {
    const { classes, inputs: { list }, where, children, match } = this.props;

    if (!where) {
      return null;
    }

    return (
      <Fragment>
        {children}
        <Typography variant="subtitle1">Entradas</Typography>
        <Typography className={classes.muted} gutterBottom>Valores inseridos pelo vendedor ao selecionar essa categoria</Typography>
        {
          sortBy(list, sortByLetter)
            .map(input=>{
              const { name = '', unit, min, max } = input || {};
              const letter = get(input, 'Letter.letter') || '';
              const label = <span>
                {name}
                {!!unit&&`:${unit} `}
                {!!min&&(` (min:${min})`)}
                {!!max&&(` (max:${max})`)}
              </span>;
              return (
                <Chip
                  component={Link}
                  to={`${match.url}/entradas/${input.id}`}
                  clickable
                  key={input.id}
                  avatar={<Avatar>{letter}</Avatar>}
                  label={label}
                  onDelete={this.handleRemoveInput(input.id)}
                  className={classes.chipItem}
                />
              );
            })
        }
        <div className={classes.buttons}>
          <Button
            component={Link}
            to={`${match.url}/entradas/novo`}
            color="primary"
            variant="outlined"
          >
            Adicionar entrada
          </Button>
        </div>
      </Fragment>
    );
  }
};

InputList.propTypes = {
  classes: PropTypes.object.isRequired,
  inputs: PropTypes.object.isRequired,
  getInputs: PropTypes.func.isRequired,
  deleteInput: PropTypes.func.isRequired,
  invalidateListItem: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  where: PropTypes.object,
  children: PropTypes.node,
};

const mapStateToProps = ({ inputs })=>{
  return { inputs };
};

export default withStyles(styles)(connect(mapStateToProps, { getInputs, deleteInput, invalidateListItem, showSnackbar })(InputList));