import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  getListItemFailed,
  getListItemSuccess,
  deleteListItemSuccess,
  deleteListItemFailed,
} from './actions';

// SAGAS
function* getListItem({ payload }) {
  const { offset, limit, where, params, model } = payload;
  try {
    const data = yield call(model.get, { params: { offset, limit, where, ...params } });
    yield put(getListItemSuccess(data));
  } catch (error) {
    console.error("Error in getListItem saga", error);
    yield put(getListItemFailed({ message: error.message }));
  }
}

function* deleteListItem({ payload }) {
  const { model, id } = payload;
  try {
    yield call(model.delete, { id });
    yield put(deleteListItemSuccess());
  } catch (error) {
    console.error("Error in deleteListItem saga", error);
    yield put(deleteListItemFailed(error));
  }
}

// WATCH FOR ACTIONS
export function* watchFetchList() {
  yield takeEvery("GET_LIST_ITEM", getListItem);
}

export function* watchDeleteItem() {
  yield takeEvery("DELETE_LIST_ITEM", deleteListItem);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchList),
    fork(watchDeleteItem),
  ]);
}
