export const getFactoryBillings = (offset, limit, filter = {}, params)=>({
  type: "GET_FACTORY_BILLINGS",
  payload: { offset, limit, where: filter, params }
});

export const getFactoryBillingsSuccess = data=>({
  type: "GET_FACTORY_BILLINGS_SUCCESS",
  payload: { data }
});

export const getFactoryBillingsFailed = error=>({
  type: "GET_FACTORY_BILLINGS_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter faturamentos" }
});

export const getFactoryBilling = id=>({
  type: "GET_FACTORY_BILLING",
  payload: { id }
});

export const getFactoryBillingSuccess = data=>({
  type: "GET_FACTORY_BILLING_SUCCESS",
  payload: { data }
});

export const getFactoryBillingFailed = error=>({
  type: "GET_FACTORY_BILLING_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter faturamento" }
});

export const createFactoryBilling = factoryBilling=>({
  type: "CREATE_FACTORY_BILLING",
  payload: { factoryBilling }
});

export const createFactoryBillingSuccess = ()=>({
  type: "CREATE_FACTORY_BILLING_SUCCESS",
  payload: {}
});

export const createFactoryBillingFailed = error=>({
  type: "CREATE_FACTORY_BILLING_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar faturamento" }
});

export const updateFactoryBilling = (id, factoryBilling)=>({
  type: "UPDATE_FACTORY_BILLING",
  payload: { id, factoryBilling }
});

export const updateFactoryBillingSuccess = ()=>({
  type: "UPDATE_FACTORY_BILLING_SUCCESS",
  payload: {}
});

export const updateFactoryBillingFailed = error=>({
  type: "UPDATE_FACTORY_BILLING_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar faturamento" }
});

export const deleteFactoryBilling = id=>({
  type: "DELETE_FACTORY_BILLING",
  payload: { id }
});

export const deleteFactoryBillingSuccess = ()=>({
  type: "DELETE_FACTORY_BILLING_SUCCESS",
  payload: {}
});

export const deleteFactoryBillingFailed = error=>({
  type: "DELETE_FACTORY_BILLING_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar faturamento" }
});