import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { OrderResource } from '../../resources/order';
import {
  getOrdersFailed,
  getOrdersSuccess,
  createOrderSuccess,
  createOrderFailed,
  deleteOrderSuccess,
  deleteOrderFailed,
  getOrderSuccess,
  getOrderFailed,
  updateOrderSuccess,
  updateOrderFailed,
  finishOrderSuccess,
  finishOrderFailed,
  cancelOrderSuccess,
  cancelOrderFailed,
  calculateOrderSuccess,
  calculateOrderFailed,
} from './actions';

const Order = new OrderResource();

const INCLUDE = [
  { model: 'User', include: { model: 'Profile' }, required: true },
  { model: 'Category', required: true },
  { model: 'Market' },
  { model: 'Factory', required: true },
  { model: 'Product', required: true },
  { model: 'Billing', required: true },
  { model: 'Shipping' },
  { model: 'OrderStatus' },
];

// SAGAS
function* fetchOrders({ payload }) {
  const { offset, limit, where, include = INCLUDE } = payload;
  try {
    const data = yield call(Order.get, { params: { offset, limit, where, include } });
    yield put(getOrdersSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getOrdersFailed({ message: error.message }));
  }
}

function* createOrder({ payload }) {
  const { order } = payload
  try {
    yield call(Order.create, { payload: order });
    yield put(createOrderSuccess())
  } catch (error) {
    console.error("Error in saga", error)
    yield put(createOrderFailed(error))
  }
}

function* deleteOrder({ payload }) {
  const { id } = payload
  try {
    yield call(Order.delete, { id })
    yield put(deleteOrderSuccess())
  } catch (error) {
    console.error("Error in saga", error)
    yield put(deleteOrderFailed(error))
  }
}

function* getOrder({ payload }) {
  const { id } = payload
  try {
    const data = yield call(Order.getOne, { id, params: { include: INCLUDE } })
    yield put(getOrderSuccess(data))
  } catch (error) {
    console.error("Error in saga", error)
    yield put(getOrderFailed(error))
  }
}

function* updateOrder({ payload }) {
  const { id, order } = payload
  try {
    const data = yield call(Order.update, { id, payload: order })
    yield put(updateOrderSuccess(data))
  } catch (error) {
    console.error("Error in saga", error)
    yield put(updateOrderFailed(error))
  }
}

function* finishOrder({ payload }) {
  const { id } = payload
  try {
    const data = yield call(Order.finish, { id })
    yield put(finishOrderSuccess(data))
  } catch (error) {
    console.log("Error in saga", error)
    yield put(finishOrderFailed(error))
  }
}

function* cancelOrder({ payload }) {
  const { id } = payload
  try {
    const data = yield call(Order.cancel, { id })
    yield put(cancelOrderSuccess(data))
  } catch (error) {
    console.log("Error in saga", error)
    yield put(cancelOrderFailed(error))
  }
}

function* calculateOrder({ payload }) {
  const { id, inputs } = payload
  try {
    const data = yield call(Order.calculate, { id, payload: { inputs } })
    yield put(calculateOrderSuccess(data))
  } catch (error) {
    console.log("Error in saga", error)
    yield put(calculateOrderFailed(error))
  }
}


// WATCH FOR ACTIONS
export function* watchFetchOrders() {
  yield takeEvery("GET_ORDERS", fetchOrders);
}

export function* watchGetOrder() {
  yield takeEvery("GET_ORDER", getOrder);
}

export function* watchCreateOrder() {
  yield takeEvery("CREATE_ORDER", createOrder);
}

export function* watchDeleteOrder() {
  yield takeEvery("DELETE_ORDER", deleteOrder);
}

export function* watchUpdateOrder() {
  yield takeEvery("UPDATE_ORDER", updateOrder);
}

export function* watchFinishOrder() {
  yield takeLatest("FINISH_ORDER", finishOrder);
}

export function* watchCancelOrder() {
  yield takeLatest("CANCEL_ORDER", cancelOrder);
}

export function* watchCalculateOrder() {
  yield takeLatest("CALCULATE_ORDER", calculateOrder);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchOrders),
    fork(watchGetOrder),
    fork(watchCreateOrder),
    fork(watchDeleteOrder),
    fork(watchUpdateOrder),
    fork(watchFinishOrder),
    fork(watchCancelOrder),
    fork(watchCalculateOrder),
  ]);
}