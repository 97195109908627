import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ExitToApp from '@material-ui/icons/ExitToApp';

import { connect } from 'react-redux';
import { logoutUser } from '../../redux/actions';

import { getStateUrl } from '../../routes';

class AuthBar extends PureComponent {
  handleLogout = event=>{
    this.props.logoutUser();
  };
  
  render() {
    const { currentUser } = this.props;

    if (!currentUser) {
      return null;
    }

    return (
      <Fragment>
        <IconButton
          color="inherit"
          component={Link}
          to={getStateUrl('profile')}
        >
          <AccountCircle />
        </IconButton>
        <IconButton
          onClick={this.handleLogout}
          color="inherit"
        >
          <ExitToApp />
        </IconButton>
      </Fragment>
    );
  }
};

const mapStateToProps = ({ auth })=>{
  const { currentUser } = auth;
  return { currentUser };
};

AuthBar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
};

export default connect(mapStateToProps, { logoutUser })(AuthBar);