import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

import { recoverPassword, showSnackbar } from '../../redux/actions';
import AppLogin from '../../components/AppLogin';
import { Link } from 'react-router-dom';

const styles = theme=>({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit*2,
  },
  linkText: {
    textAlign: 'center',
    marginTop: theme.spacing.unit*2,
  }
});

class RecoverPassword extends Component {
  state = {
    email: '',
  };

  componentWillReceiveProps(nextProps, nextState) {
    const { loading, error, errorMessage } = nextProps.auth;
    if (!loading) {
      const { showSnackbar } = this.props;
      if (error) {
        showSnackbar('error', errorMessage);
      } else {
        showSnackbar('success', 'E-mail com as instruções para redefinição de senha enviado');
      }
    }
  }

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleRecoverPassword = event=>{
    event.preventDefault();
    const { email } = this.state;
    const { recoverPassword } = this.props;
    const { showSnackbar } = this.props;
    showSnackbar('info', 'Aguarde, enviando e-mail...');
    recoverPassword(email);
  };

  render() {
    const { auth, classes } = this.props;
    const { email } = this.state;
    const { loading } = auth;

    return (
      <AppLogin title="Resetar senha">
        <form className={classes.form} onSubmit={this.handleRecoverPassword}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">E-mail</InputLabel>
            <Input
              id="email"
              name="email"
              onChange={this.handleChange}
              value={email}
              autoComplete="email"
              autoFocus
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            Enviar e-mail
          </Button>
          <Typography component={Link} to='/' className={classes.linkText}>
            Voltar para o login
          </Typography>
        </form>
      </AppLogin>
    );
  }
};

RecoverPassword.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  recoverPassword: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth })=>{
  return { auth };
}
export default withStyles(styles)(connect(mapStateToProps, { recoverPassword, showSnackbar })(RecoverPassword));
