import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Done';
import DeleteIcon from '@material-ui/icons/Delete';
import red from '@material-ui/core/colors/red';

import { getVariables, deleteVariable, showSnackbar } from '../../redux/actions';
import { connect } from 'react-redux';

import { get, sortBy, filter, uniqBy } from 'lodash';

import { sortByLetter } from './values';

import SafeRemove from '../../components/SafeRemove';

const styles = theme=>({
  red: {
    color: red[600],
  },
  buttons: {
    marginTop: theme.spacing.unit*3,
  },
  muted: {
    color: theme.palette.grey[600]
  },
  letter: {
    // borderRadius: 2,
    // backgroundColor: theme.palette.grey[200],
    // paddingLeft: theme.spacing.unit/2,
    // paddingRight: theme.spacing.unit/2,
  },
});

class VariableList extends PureComponent {
  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps(props) {
    const { showSnackbar, variables, where, history, location } = props;
    const { loading, error, errorMessage, action, item, invalid } = variables;

    if (where !== this.props.where) {
      this.fetchData(props);
    }
    if (!loading) {
      if (error) {
        showSnackbar('error', errorMessage);
      } else if (action) {
        switch (action) {
          case 'CREATED':
            showSnackbar('success', `Variável adicionada com sucesso`);
            const newPath = location.pathname.replace(/novo$/, item.id);
            history.replace(newPath);
          break;
          case 'UPDATED':
            showSnackbar('success', `Variável atualizada com sucesso`);
          break;
          case 'DELETED':
            showSnackbar('success', `Variável removida com sucesso`);
          break;
          default:
            console.info('unknown action:', action);
          break;
        }
        this.fetchData(props);
      } if (invalid) {
        this.fetchData(props);
      }
    }
  }

  fetchData = (props = this.props)=>{
    const { getVariables, where } = props;
    if (where) {
      getVariables(0, null, { ...where });
    }
  };

  handleRemoveVariable = variableId=>event=>{
    event.preventDefault();
    const { deleteVariable } = this.props;
    deleteVariable(variableId);
  };

  render() {
    const { classes, variables: { list, loading }, where, children, match, variableId = null } = this.props;

    const variables = list.filter(va=>va.variableId === variableId);

    if (!where && !variableId) {
      return null;
    }

    const path = variableId ? '' : '/variaveis';

    return (
      <Fragment>
        {children}
        <Typography variant="subtitle1">Variáveis</Typography>
        <Typography className={classes.muted}>
          {variableId
            ? 'Variáveis utilizadas na comparação'
            : 'Itens do cálculo visíveis somente para o administrador'
          }
        </Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Letra</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Posição</TableCell>
              <TableCell>Fórmula</TableCell>
              <TableCell>Checklist</TableCell>
              {!variableId&&(
                <TableCell align="right">Comparações</TableCell>
              )}
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              sortBy(variables, sortByLetter)
                .map(variable=>{
                  const compares = get(variable, 'Compares') || [];
                  const len = compares.length - uniqBy(filter(compares, 'compareId'), 'compareId').length;

                  return (
                    <TableRow key={variable.id} hover>
                      <TableCell component="th" scope="row">
                        <strong className={classes.letter}>{get(variable, 'Letter.letter')}</strong>
                      </TableCell>
                      <TableCell>{variable.name}</TableCell>
                      <TableCell>{variable.position}</TableCell>
                      <TableCell>{variable.formula}</TableCell>
                      <TableCell>{!!variable.checklist&&<CheckIcon />}</TableCell>
                      {!variableId&&(
                        <TableCell align="right">
                          {!!len&&len}
                        </TableCell>
                      )}
                      <TableCell align="right">
                        <IconButton
                          component={Link}
                          to={`${match.url}${path}/${variable.id}`}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <SafeRemove
                          loading={loading}
                          onRemove={this.handleRemoveVariable(variable.id)}
                          message={`Remover variável ${variable.name}?`}
                        >
                          <IconButton>
                            <DeleteIcon className={classes.red} fontSize="small" />
                          </IconButton>
                        </SafeRemove>
                      </TableCell>
                    </TableRow>
                  );
                })
            }
          </TableBody>
        </Table>
        
        <div className={classes.buttons}>
          <Button
            component={Link}
            to={`${match.url}${path}/novo`}
            color="primary"
            type="submit"
            variant="outlined"
          >
            Adicionar variável
            {variableId&&` de comparação`}
          </Button>
        </div>

      </Fragment>
    );
  }
};

VariableList.propTypes = {
  classes: PropTypes.object.isRequired,
  variables: PropTypes.object.isRequired,
  getVariables: PropTypes.func.isRequired,
  deleteVariable: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  variableId: PropTypes.any,
  where: PropTypes.object,
  children: PropTypes.node,
};

const mapStateToProps = ({ variables })=>{
  return { variables };
};

export default withStyles(styles)(connect(mapStateToProps, { getVariables, deleteVariable, showSnackbar })(VariableList));