import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

import { getShippings, deleteShipping, invalidateListItem, showSnackbar } from '../../redux/actions';
import { connect } from 'react-redux';

import { get, sortBy } from 'lodash';

const styles = theme=>({
  chipItem: {
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
  buttons: {
    marginTop: theme.spacing.unit*2,
  },
  muted: {
    color: theme.palette.grey[600]
  },
});

class ShippingList extends PureComponent {
  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps(props) {
    const { showSnackbar, shippings, data, invalidateListItem } = props;
    const { loading, error, errorMessage, action } = shippings;

    if (data !== this.props.data) {
      this.fetchData(props);
    }
    if (!loading) {
      if (error) {
        showSnackbar('error', errorMessage);
      } else if (action) {
        switch (action) {
          case 'CREATED':
            showSnackbar('success', `Frete adicionado com sucesso`);
            this.setState({ shippingName: '', shippingCost: '' });
          break;
          case 'UPDATED':
            showSnackbar('success', `Frete atualizado com sucesso`);
          break;
          case 'DELETED':
            showSnackbar('success', `Frete removido com sucesso`);
          break;
          default:
            console.info('unknown action:', action);
          break;
        }
        invalidateListItem();
        this.fetchData(props);
      }
    }
  }

  fetchData = (props = this.props)=>{
    const { getShippings, data } = props;
    if (data && data.id) {
      getShippings(0, null, { factoryId: data.id });
    }
  };

  handleRemoveShipping = shippingId=>event=>{
    event.preventDefault();
    const { deleteShipping } = this.props;
    deleteShipping(shippingId);
  };

  render() {
    const { classes, shippings: { list }, data, children, match } = this.props;

    if (!data || !data.id) {
      return null;
    }

    return (
      <Fragment>
        {children}
        <Typography variant="subtitle1">Fretes</Typography>
        <Typography className={classes.muted}>Valores e distâncias dos frete dessa fábrica</Typography>
        {
          sortBy(list, 'name')
            .map(shipping=>{
              const shippingName = get(shipping, 'name');
              const shippingCost = get(shipping, 'cost') || 0;
              const label = (<span>{shippingName} = <strong>R$ {shippingCost.toFixed(2)}</strong></span>)
              return (
                <Chip
                  component={Link}
                  to={`${match.url}/fretes/${shipping.id}`}
                  clickable
                  key={shipping.id}
                  label={label}
                  onDelete={this.handleRemoveShipping(shipping.id)}
                  className={classes.chipItem}
                />
              );
            })
        }

        <div className={classes.buttons}>
          <Button
            component={Link}
            variant="outlined"
            to={`${match.url}/fretes/novo`}
            color="primary"
          >
            Adicionar frete
          </Button>
        </div>
      </Fragment>
    );
  }
};

ShippingList.propTypes = {
  classes: PropTypes.object.isRequired,
  shippings: PropTypes.object.isRequired,
  getShippings: PropTypes.func.isRequired,
  deleteShipping: PropTypes.func.isRequired,
  invalidateListItem: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  children: PropTypes.node,
};

const mapStateToProps = ({ shippings })=>{
  return { shippings };
};

export default withStyles(styles)(connect(mapStateToProps, { getShippings, deleteShipping, invalidateListItem, showSnackbar })(ShippingList));