import React from 'react';
import { get } from 'lodash';

import Datagrid, { DatagridCell, EditButton, RemoveButton } from '../../components/Datagrid';
import { FabButton } from '../../components/FabToolbar';

import UserForm from './form';

const params = {include: 'Profile'};

const UserList = props=>(
  <Datagrid
    title="Usuários"
    name="Usuário"
    resource="users"
    params={params}
    detailParams={params}
    form={UserForm}
  >
    <FabButton type="add" fab />
    <DatagridCell prop="id" label="Id" />
    <DatagridCell prop="email" label="E-mail" />
    <DatagridCell prop="Profile.firstName" label="Nome" />
    <DatagridCell prop="Profile.lastName" label="Sobrenome" />
    <DatagridCell prop="isAdmin" label="Administrador" />
    <DatagridCell prop="active" label="Ativo" />
    <DatagridCell prop="restricted" label="Restrito" />
    <DatagridCell numeric>
      <EditButton />
      <RemoveButton
        name={i=>`${get(i, 'Profile.firstName')} ${get(i, 'Profile.lastName')}`}
      />
    </DatagridCell>
  </Datagrid>
);

export default UserList;