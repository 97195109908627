import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { get, map, sortBy } from 'lodash';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import FormSwitch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import PriceIcon from '@material-ui/icons/AttachMoney';

import Datagrid, { DatagridCell, EditButton, RemoveButton } from '../../components/Datagrid';
import { FabButton } from '../../components/FabToolbar';

import { getCategories, getFactories, showSnackbar } from '../../redux/actions';
import { connect } from 'react-redux';

import ProductForm from './form';
import UpdatePrices from './prices';
import UpdatePrice from './price';

const styles = theme=>({
  filter: {
    marginBottom: theme.spacing.unit*2
  },
});

const CategoryList = ({ item })=>(
  map(sortBy(item.ProductCategories, 'categoryId'), c=>{
    let name = get(c, 'Category.name');
    if (c.name) {
      name += `:${c.name}`;
    }
    return name;
  }).join(', ')
);
const formatPrice = price=>typeof price === 'number'&&price.toFixed(2);

class ProductList extends Component {
  state = {
    params: {
      include: [
        { model: 'ProductCategory', include: 'Category' },
        { model: 'ProductValue', include: 'Unit' }
      ]
    },
    categoryId: '',
    factoryId: '',
    name: '',
    expanded: false,
  };
  timer = null;
  routes = [
    {
      path: `${this.props.match.path}/precos/atualizar`,
      component: UpdatePrice
    }, {
      path: `${this.props.match.path}/precos`,
      component: UpdatePrices
    }
  ];

  componentWillMount() {
    this.fetchData();
  }

  fetchData = ()=>{
    const { getCategories, getFactories } = this.props;
    getCategories(0, null, null);
    getFactories(0, null, null);
  };

  handleChange = shouldDelay=>event=>{
    clearTimeout(this.timer);
    const { value, name } = event.target;
    const state = { [name]: value };
    if (shouldDelay) {
      this.setState(state, ()=>{
        this.timer = setTimeout(this.queryData, 500);
      });
    } else {
      this.queryData(state);
    }
  };

  queryData = newState=>{
    const params = {
      where: {},
      include: [{
        model: 'ProductValue',
        include: 'Unit'
      }]
    };
    const state = { ...this.state, ...newState, params };
    const { factoryId, categoryId, name } = state;
    if (factoryId) {
      params.include.push({
        model: 'ProductFactory',
        where: { factoryId },
        required: true
      });
    }
    if (categoryId) {
      params.include.push({
        model: 'ProductCategory',
        include: 'Category',
        where: { categoryId },
        required: true
      });
    } else {
      params.include.push({
        model: 'ProductCategory',
        include: 'Category',
      });
    }
    if (name) {
      params.where.name = {
        like: encodeURIComponent(`%${name}%`)
      };
    }
    this.setState(state);
  };

  toggle = prop=>event=>{
    const { checked } = event.target;
    this.setState({ [prop]: checked });
  };

  render() {
    const { classes, categories, factories, match } = this.props;
    const { params, categoryId, factoryId, name, expanded } = this.state;

    return (
      <Datagrid
        title="Produtos"
        name="Produto"
        resource="products"
        form={ProductForm}
        params={params}
        routes={this.routes}
        complex
      >
        <div className={classes.filter} filter="true">
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                value={categoryId}
                onChange={this.handleChange()}
                name="categoryId"
                label="Categoria"
                variant="outlined"
                select
                fullWidth
              >
                <MenuItem value=""></MenuItem>
                {categories.list.map(ca=><MenuItem value={ca.id} key={ca.id}>{ca.name}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                value={factoryId}
                onChange={this.handleChange()}
                name="factoryId"
                label="Local de produção"
                variant="outlined"
                select
                fullWidth
              >
                <MenuItem value=""></MenuItem>
                {factories.list.map(fa=><MenuItem value={fa.id} key={fa.id}>{fa.name}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                value={name}
                onChange={this.handleChange(true)}
                name="name"
                label="Nome do produto"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>

          <FormControlLabel
            control={
              <FormSwitch
                checked={expanded}
                onChange={this.toggle('expanded')}
              />
            }
            label="Medidas"
          />
        </div>
        <FabButton title="Atulizar preços" path={`${match.url}/precos`} fab>
          <PriceIcon />
        </FabButton>
        <FabButton type="add" fab />
        <DatagridCell prop="id" label="Id" />
        <DatagridCell prop="name" label="Nome" />
        <DatagridCell prop="shortName" label="Abv." />
        <DatagridCell prop="active" label="Ativo" />
        <DatagridCell label="Categorias">
          <CategoryList />
        </DatagridCell>
        <DatagridCell prop="price" label="Preço (R$)" decorator={formatPrice} numeric />
        {!!expanded&&(
          <DatagridCell prop="ProductValues" groupBy="unitId" label="Unit.name" numeric />
        )}
        <DatagridCell numeric>
          <EditButton />
          <RemoveButton
            name={i=>`${get(i, 'name')}`}
          />
        </DatagridCell>
      </Datagrid>
    );
  }
};

ProductList.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  factories: PropTypes.object.isRequired,
  getCategories: PropTypes.func.isRequired,
  getFactories: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = ({ categories, factories })=>{
  return { categories, factories };
};

export default withStyles(styles)(connect(mapStateToProps, { getCategories, getFactories, showSnackbar })(ProductList));