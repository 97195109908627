const INIT_STATE = {
  loading: false,
  error: false,
  errorMessage: null,
	list: [],
	item: null,
	action: null
};

const reducer = (state = INIT_STATE, action) => {
	switch (action.type) {
		case 'GET_MARKETS':
			return { ...state, loading: true, error: false, errorMessage: null, action: null };

		case 'GET_MARKETS_SUCCESS':
			return { ...state, loading: false, error: false, errorMessage: null, list: action.payload.data, action: null };

		case 'GET_MARKETS_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

		case 'GET_MARKET':
			return { ...state, loading: true, error: false, action: null };

		case 'GET_MARKET_SUCCESS':
			return { ...state, loading: false, error: false, item: action.payload.data, action: null };

		case 'GET_MARKET_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

		case 'CREATE_MARKET':
			return { ...state, loading: true, error: false, action: null }

		case 'CREATE_MARKET_SUCCESS':
			return { ...state, loading: false, error: false, action: 'CREATED' }

		case 'CREATE_MARKET_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null }

		case 'UPDATE_MARKET':
			return { ...state, loading: true, error: false, action: null }

		case 'UPDATE_MARKET_SUCCESS':
			return { ...state, loading: false, error: false, action: 'UPDATED' }

		case 'UPDATE_MARKET_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null }

		case 'DELETE_MARKET':
			return { ...state, loading: true, action: null }

		case 'DELETE_MARKET_SUCCESS':
			return { ...state, loading: false, error: false, action: 'DELETED' }

		case 'DELETE_MARKET_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null }

		default:
			return state;
	}
};

export default reducer;