import { Parser } from 'expr-eval';
import {
  flatMap,
  isArray,
  filter,
  clone,
  remove,
  get,
  each,
  difference,
  uniq,
  keys,
  pickBy,
  map,
  mapValues,
  omitBy,
  has,
  sortBy,
  find,
  transform
} from 'lodash';

const parser = new Parser();

// valor máximo por index
parser.functions.maxi = function () {
  const data = Array.prototype.slice.call(arguments, 1);
  const index = arguments[0];
  return data.sort((a,b)=>a<b?-1:a>b?1:0).reverse()[index];
};

export const isValidFormula = (formula, data)=>{
  if (!isArray(formula)) {
    formula = [formula];
  }
  let variables = uniq(
    flatMap(formula, f=>{
      if (typeof f !== 'string') {
        return [];
      }
      return parser.parse(parseFormula(f)).variables();
    })
  );
  const ks = keys(pickBy(data, v=>v.value!==undefined));
  const invalid = difference(variables, ks);
  return invalid.length === 0;
};

export const priceFormat = (value, decimals = 4)=>{
  return parseFloat(value).toLocaleString('pt-BR', {
    minimumFractionDigits: 4,
    style: 'currency',
    currency: 'BRL'
  });
};

export const calculateVariables = (variables, data, factoryId)=>{
  const cloned = variables.slice(0);
  const current = remove(cloned, v=>{
    if (!get(v, 'Compares.length')) {
      return isValidFormula(v.formula, data);
    }
    // adiciona as variáveis que tem na comparação
    const vs = transform(v.Variables, (result, cv)=>{
      result[getLetter(cv)] = { value: cv.formula };
    }, {});

    return isValidFormula(map(flatMap(v.Compares, 'Variables'), 'formula'), { ...data, ...vs });
  });

  if (!current.length) {
    return;
  }

  each(current, variable=>{
    let value, compare;
    const formula = parseFormula(variable.formula);
    const letter = getLetter(variable);

    if (!!get(variable, 'Compares.length')) {
      const factoryCompares = filter(variable.Compares, c=>!c.factoryId||c.factoryId===factoryId);
      const results = map(factoryCompares, curr=>{
        const currData = clone(data);
        calculateVariables(curr.Variables, currData, factoryId);
        const values = mapValues(omitBy(currData, (v, k)=>has(data, k)), 'value');
        const compare = {
          compareId: curr.id,
          name: curr.fullName || curr.name,
          values: {}
        };
        each(sortBy(curr.Variables, 'id'), v=>{
          const key = getLetter(v);
          compare.values[key] = {
            variableId: v.id,
            name: v.name,
            formula: parseFormula(v.formula),
            value: values[key]
          };
        });
        return {
          values,
          compare
        };
      });
      // console.log(letter, results);
      each(results, r=>{
        if (parser.evaluate(formula, { a: r.values, b: value || r.values })) {
          value = r.values;
          compare = r.compare;
        }
      });
      if (!value) {
        value = {};
      }
    } else {
      try {
        value = parser.evaluate(formula, mapValues(data, 'value'));
      } catch(err) {
        console.error('invalid evaluate variable', err, formula, data);
      }
    }
    data[letter] = {
      letter,
      variableId: variable.id,
      type: 'calculado',
      name: variable.name,
      formula,
      value,
      compare
    };
  });

  if (!!cloned.length) {
    calculateVariables(cloned, data, factoryId);
  }
};

export const parseFormula = formula=>{
  return typeof formula === 'string' ? formula.toLowerCase() : '';
};

export const parseView = (formula, data)=>{
  let value = 'Erro';
  try {
    value = parser.evaluate(parseFormula(formula), mapValues(data, 'value'));
    value = `R$ ${value.toFixed(2)}`;
  } catch(err) {
    // console.error('invalid evaluate view', err, formula, data);
  }
  return value;
};

export const getLetter = item=>{
  return String(get(item, 'Letter.letter')).toLowerCase();
};

export const getICMS = item=>{
  const icms = get(item, 'icms');
  return typeof icms === 'number' ? icms/100 : 1;
};

export const parseVariables = (variables, factories)=>{
  // converte as váriaveis default no padrão novo para os objeto individuais
  return each(variables, va=>{
    if (va.Variables) {
      const compares = [];
      each(filter(va.Compares, {compareId: null}), compare=>{
        const currCompares = filter(va.Compares, {compareId: compare.id});

        if (currCompares.length) {
          each(currCompares, cc=>{
            cc.Variables = map(va.Variables, v=>{
              const variable = clone(v);
              const cv = find(cc.CompareVariables, {variableId: v.id})
                || find(compare.CompareVariables, {variableId: v.id});
              if (cv) {
                variable.formula = cv.formula;
                variable.compareVariableId = cv.id;
              }
              return variable;
            });
            cc.fullName = `${cc.id}:${compare.name}:${cc.name}`;
            const factory = find(factories, {id: compare.factoryId});
            if (factory) {
              cc.fullName += `:${factory.name}`;
            }
            compares.push(cc);
          });
        } else {
          compare.Variables = map(va.Variables, v=>{
            const variable = clone(v);
            const cv = find(compare.CompareVariables, {variableId: v.id});
            if (cv) {
              variable.formula = cv.formula;
              variable.compareVariableId = cv.id;
            }
            return variable;
          });
          compare.fullName = `${compare.id}:${compare.name}`;
          const factory = find(factories, {id: compare.factoryId});
          if (factory) {
            compare.fullName += `:${factory.name}`;
          }
          compares.push(compare);
        }
      });
      va.Compares = compares;
    }
  });
};