const INIT_STATE = {
  loading: false,
  error: false,
  errorMessage: null,
	list: [],
	item: null,
  action: null
};

const reducer = (state = INIT_STATE, action)=>{
	switch (action.type) {
		case 'GET_USERS':
			return { ...state, loading: true, error: false, errorMessage: null, action: null };

		case 'GET_USERS_SUCCESS':
			return { ...state, loading: false, error: false, errorMessage: null, action: null, list: action.payload.data };

		case 'GET_USERS_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

		case 'GET_USER':
			return { ...state, loading: true, error: false, errorMessage: null, action: null };

		case 'GET_USER_SUCCESS':
			return { ...state, loading: false, error: false, errorMessage: null, action: null, item: action.payload.data };

		case 'GET_USER_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

		case 'GET_USER_ME':
			return { ...state, loading: true, error: false, errorMessage: null, action: null };

		case 'GET_USER_ME_SUCCESS':
			return { ...state, loading: false, error: false, errorMessage: null, action: null, item: action.payload.data };

		case 'GET_USER_ME_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

		case 'CREATE_USER':
			return { ...state, loading: true, error: false, errorMessage: null, action: null };

		case 'CREATE_USER_SUCCESS':
			return { ...state, loading: false, error: false, errorMessage: null, action: 'CREATED' };

		case 'CREATE_USER_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

		case 'UPDATE_USER':
			return { ...state, loading: true, error: false, errorMessage: null, action: null };

		case 'UPDATE_USER_SUCCESS':
			return { ...state, loading: false, error: false, errorMessage: null, action: 'UPDATED' };

		case 'UPDATE_USER_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

		case 'UPDATE_USER_ME':
			return { ...state, loading: true, error: false, errorMessage: null, action: null };

		case 'UPDATE_USER_ME_SUCCESS':
			return { ...state, loading: false, error: false, errorMessage: null, action: 'UPDATED' };

		case 'UPDATE_USER_ME_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

		case 'DELETE_USER':
			return { ...state, loading: true, error: false, errorMessage: null, action: null };

		case 'DELETE_USER_SUCCESS':
			return { ...state, loading: false, error: false, errorMessage: null, action: 'DELETED' };

		case 'DELETE_USER_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

		default:
			return state;
	}
};

export default reducer;