import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import AppDrawer from '../components/AppDrawer';

import Dashboard from './dashboard';
import UserRoutes from './users';
import UnitRoutes from './units';
import FactoryRoutes from './factories';
import BillingRoutes from './billings';
import MarketRoutes from './markets';
import CategoryRoutes from './categories';
import ProductsRoutes from './products';
import OrderRoutes from './orders';
import Profile from './auth/profile';

import DashboardIcon from '@material-ui/icons/Dashboard';
import People from '@material-ui/icons/People';
import StoreMallDirectory from '@material-ui/icons/StoreMallDirectory';
import AttachMoney from '@material-ui/icons/AttachMoney';
import ShoppingCart from '@material-ui/icons/ShoppingCart';
import Category from '@material-ui/icons/Category';
import Widgets from '@material-ui/icons/Widgets';
import Straighten from '@material-ui/icons/Straighten';
import Assignment from '@material-ui/icons/Assignment';

export const resources = [
  {
    label: "Dashboard",
    key: "dashboard",
    Icon: DashboardIcon,
    view: Dashboard,
    restricted: false
  }, {
    label: "Orçamentos",
    key: "orders",
    url: "/orcamentos",
    Icon: Assignment,
    view: OrderRoutes,
    restricted: false
  }, {
    label: "Usuários",
    key: "users",
    url: "/usuarios",
    Icon: People,
    view: UserRoutes
  }, {
    label: "Faturamentos",
    key: "billings",
    url: "/faturamentos",
    Icon: AttachMoney,
    view: BillingRoutes
  }, {
    label: "Fábricas",
    key: "factories",
    url: "/fabricas",
    Icon: StoreMallDirectory,
    view: FactoryRoutes
  }, {
    label: "Unidades",
    key: "units",
    url: "/unidades",
    Icon: Straighten,
    view: UnitRoutes
  }, {
    label: "Categorias",
    key: "categories",
    url: "/categorias",
    Icon: Category,
    view: CategoryRoutes
  }, {
    label: "Produtos",
    key: "products",
    url: "/produtos",
    Icon: Widgets,
    view: ProductsRoutes
  }, {
    label: "Mercados",
    key: "markets",
    url: "/mercados",
    Icon: ShoppingCart,
    view: MarketRoutes
  }, {
    key: "profile",
    url: "/perfil",
    view: Profile
  }
];

export const getStateUrl = key=>{
  const state = resources.find(r=>r.key===key);
  return (state && state.url) || undefined;
};

export default class MainNavigation extends Component {
  render() {
    const dashboard = resources.find(r=>!r.url);

    return (
      <AppDrawer>
        <Switch>
          {resources
            .filter(r=>r.url)
            .map(resource=>(
              <Route
                key={resource.key}
                path={resource.url&&`${resource.url}`}
                component={resource.view}
              />
            ))
          }
          {!!dashboard&&(
            <Route component={dashboard.view} />
          )}
        </Switch>
      </AppDrawer>
    );
  }
};