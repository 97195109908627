import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { get, sortBy, map, groupBy, filter, transform, find } from 'lodash';
import moment from 'moment';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Datagrid, { DatagridCell, ShowButton, RemoveButton } from '../../components/Datagrid';
import { OrderStatus, statuses } from '../../components/OrderStatus';
import Autosuggest from '../../components/Autosuggest';

import { getCategories, showSnackbar } from '../../redux/actions';
import { connect } from 'react-redux';

import OrderForm from './form';

import { UserResource } from '../../resources/user';
import { priceFormat } from '../../resources/calculate';

const User = new UserResource();

const styles = theme=>({
  filter: {
    marginBottom: theme.spacing.unit*2
  },
});

const defaultParams = {
  include: [
    {
      model: 'User',
      required: true,
      include: {
        model: 'Profile'
      }
    }, {
      model: 'Category',
      required: true
    }, {
      model: 'Market'
    }, {
      model: 'Factory',
      required: true
    }, {
      model: 'Product',
      required: true
    }, {
      model: 'FactoryBilling',
      required: true,
      include: 'Billing'
    }, {
      model: 'Shipping'
    }, {
      model: 'OrderStatus'
    }, {
      model: 'OrderInput'
    }, {
      model: 'OrderView',
      include: {
        model: 'View'
      }
    },
  ]
};

const detailParams = {
  include: [
    {
      model: 'Category',
      required: true
    }, {
      model: 'Product',
      required: true
    }, {
      model: 'Factory',
      required: true
    }, {
      model: 'FactoryBilling',
      required: true,
      include: {
        model: 'Billing',
        required: true
      }
    }, {
      model: 'OrderStatus',
      as: 'OrderStatuses',
      include: 'Reason',
      required: true
    }, {
      model: 'OrderMeasure',
      required: true,
      include: {
        model: 'Measure',
        required: true,
        include: [{
          model: 'Unit',
          required: true
        }, {
          model: 'Letter',
          required: true
        }]
      }
    }, {
      model: 'OrderView',
      required: true,
      include: {
        model: 'View',
        required: true
      }
    }, {
      model: 'OrderInput',
      include: {
        model: 'Input',
        include: 'Letter'
      }
    }, {
      model: 'Market'
    }, {
      model: 'Shipping'
    }
  ]
};

const getUserSuggestion = user=>`${user.Profile.firstName} ${user.Profile.lastName} [${user.email}]`;

const ViewsList = ({ item })=>(
  map(sortBy(item.OrderViews, 'View.name'), ov=>(
    <span key={ov.id} style={{ marginRight: 12, display: 'block' }}>
      {get(ov, 'View.name')}: <strong>{priceFormat(get(ov, 'value'))}</strong>
    </span>
  ))
);

const dateFormat = item=>moment(item).format('DD/MM/YYYY');

const InputsList = ({ item, categories })=>{
  const category = find(categories, { id: item.categoryId });
  const groups = groupBy(filter(sortBy(get(category, 'Inputs'), 'id'), 'group'), 'group');
  const inputs = transform(groups, (result, arr)=>{
    result.push(map(arr, input=>(
      get(find(item.OrderInputs, {inputId: input.id}), 'value')
    )).join('x'))
  }, []);
  return inputs.join(' ');
};

const formatProduction = item=>`${get(item, 'Factory.name')}-${get(item, 'FactoryBilling.Billing.name')}-${get(item, 'Shipping.name')||'FOB'}`;

const getName = item=>get(item, 'Product.shortName')||get(item, 'Product.name');

class OrderList extends Component {
  state = {
    params: defaultParams,
    categoryId: '',
    orderStatus: '',
    userId: null,
    client: '',
    expanded: window.innerWidth>=1800,
  };

  componentWillMount() {
    this.fetchData();
  }

  fetchData = ()=>{
    const { getCategories } = this.props;
    getCategories(0, null, null, { include: { model: 'Input' } });
  };

  handleChange = shouldDelay=>event=>{
    clearTimeout(this.timer);
    const { value, name } = event.target;
    const state = { [name]: value };
    if (shouldDelay) {
      this.setState(state, ()=>{
        this.timer = setTimeout(this.queryData, 500);
      });
    } else {
      this.queryData(state);
    }
  };

  handleChangeUser = userId=>{
    this.queryData({ userId });
  };

  fetchUsers = query=>{
    return User.get({
      params: {
        limit: 8,
        include: 'Profile',
        where: {
          '$or': [{
            email: {
              like: encodeURIComponent(`%${query}%`)
            }
          }, {
            fullName: query
          }]
        }
      }
    });
  };

  queryData = newState=>{
    const params = {
      where: {},
      include: [
        { model: 'Market' },
        { model: 'Factory', required: true },
        { model: 'Product', required: true },
        { model: 'FactoryBilling', required: true, include: 'Billing' },
        { model: 'Shipping' },
        { model: 'OrderInput' },
        { model: 'OrderView', include: { model: 'View' } },
      ]
    };
    const state = { ...this.state, ...newState, params };
    const { orderStatus, categoryId, userId, client } = state;
    if (client) {
      params.where.client = {
        like: encodeURIComponent(`%${client}%`)
      };
    }
    if (userId) {
      params.include.push({
        model: 'User',
        include: { model: 'Profile' },
        where: { id: userId },
        required: true
      });
    } else {
      params.include.push({
        model: 'User',
        include: { model: 'Profile' }
      });
    }
    if (orderStatus) {
      params.include.push({
        model: 'OrderStatus',
        where: { status: orderStatus },
        required: true
      });
    } else {
      params.include.push({
        model: 'OrderStatus',
      });
    }
    if (categoryId) {
      params.include.push({
        model: 'Category',
        where: { id: categoryId },
        required: true
      });
    } else {
      params.include.push({
        model: 'Category',
        required: true
      });
    }
    this.setState(state);
  };

  toggle = prop=>event=>{
    const { checked } = event.target;
    this.setState({ [prop]: checked });
  };

  render() {
    const { classes, categories: { list: categoriesList } } = this.props;
    const { params, categoryId, orderStatus, client, expanded } = this.state;

    return (
      <Datagrid
        title="Orçamentos"
        name="Orçamento"
        resource="orders"
        form={OrderForm}
        params={params}
        detailParams={detailParams}
        complex
        readOnly
      >
        <div className={classes.filter} filter="true">
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                value={categoryId}
                name="categoryId"
                onChange={this.handleChange()}
                label="Categoria"
                variant="outlined"
                select
                fullWidth
              >
                <MenuItem value=""></MenuItem>
                {categoriesList.map(({ id, name })=><MenuItem value={id} key={id}>{name}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                value={orderStatus}
                name="orderStatus"
                onChange={this.handleChange()}
                label="Status"
                variant="outlined"
                select
                fullWidth
              >
                <MenuItem value=""></MenuItem>
                {statuses.map(({ id, name })=><MenuItem value={id} key={id}>{name}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid item s={12} sm={6} md={4} lg={3} xl={2}>
              <Autosuggest
                variant="outlined"
                label="Vendedor"
                placeholder="Buscar por nome ou e-mail"
                onChange={this.handleChangeUser}
                fetchData={this.fetchUsers}
                fetchLabel={getUserSuggestion}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                value={client}
                name="client"
                onChange={this.handleChange(true)}
                label="Cliente"
                variant="outlined"
                fullWidth
              />
            </Grid>
          </Grid>

          <FormControlLabel
            control={
              <Switch
                checked={expanded}
                onChange={this.toggle('expanded')}
              />
            }
            label="Detalhado"
          />
        </div>
        {!!expanded&&(
          <DatagridCell prop="id" label="Id" />
        )}
        <DatagridCell prop="code" label="Código" />
        <DatagridCell prop="User.Profile" label="Vendedor" decorator={p=>`${get(p, 'firstName')} ${get(p, 'lastName')}`} />
        {!!expanded&&(
          <DatagridCell prop="Category.name" label="Categoria" />
        )}
        {!!expanded&&(
          <DatagridCell label="Dimensões">
            <InputsList categories={categoriesList} />
          </DatagridCell>
        )}
        <DatagridCell label="Matéria prima" decorator={getName} />
        <DatagridCell label="Produção" decorator={formatProduction} />
        <DatagridCell label="Resultados">
          <ViewsList />
        </DatagridCell>
        <DatagridCell prop="createdAt" label="Criação" decorator={dateFormat} />
        <DatagridCell label="Status">
          <OrderStatus />
        </DatagridCell>
        <DatagridCell numeric>
          <ShowButton />
          <RemoveButton
            name={i=>`${get(i, 'id')}`}
          />
        </DatagridCell>
      </Datagrid>
    );
  }
};

OrderList.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  getCategories: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = ({ categories })=>{
  return { categories };
};

export default withStyles(styles)(connect(mapStateToProps, { getCategories, showSnackbar })(OrderList));