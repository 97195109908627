import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { connect } from 'react-redux';

import MarketTaxList from './taxes';
import MarketTaxForm from './tax';

import SideNavigation from '../../components/SideNavigation';

class MarketForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.parseData(props.data);
  }

  componentWillReceiveProps({ data, marketTaxes: { action: mt } }) {
    if (data !== this.props.data) {
      this.setState(this.parseData(data));
    }
    if (mt === 'CREATED' || mt === 'UPDATED') {
      this.handleClose();
    }
  }

  parseData = data=>{
    const { id, name = '' } = data || {};
    return {
      id,
      name
    };
  };

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { onSubmit } = this.props;
    onSubmit(this.state);
  };

  handleClose = ()=>{
    const { history, match } = this.props;
    history.push(match.url);
  };

  render() {
    const { classes, loading, data, match } = this.props;
    const { name } = this.state;
    
    return (
      <Fragment>
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={12} md={8} lg={6} xl={4}>
              <TextField
                label="Nome"
                name="name"
                value={name}
                onChange={this.handleChange}
                disabled={loading}
                fullWidth
              />
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              Salvar
            </Button>
          </div>
        </form>

        <MarketTaxList data={data} match={match}>
          <Divider className={classes.divider} />
        </MarketTaxList>

        <SideNavigation open={!match.isExact} onClose={this.handleClose}>
          <Switch>
            <Route path={`${match.path}/taxas/:marketTaxId`} component={MarketTaxForm} />
          </Switch>
        </SideNavigation>
      </Fragment>
    );
  }
};

const mapStateToProps = ({ marketTaxes })=>{
  return { marketTaxes };
};

export default connect(mapStateToProps)(MarketForm);