import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
  getOrderVariablesFailed,
  getOrderVariablesSuccess,
  getOrderVariableSuccess,
  getOrderVariableFailed,
} from './actions';

const OrderVariable = new Resource('OrderVariable', 'orderVariables');

// SAGAS
function* fetchOrderVariables({ payload }) {
  const { offset, limit, where, params } = payload;
  try {
    const data = yield call(OrderVariable.get, { params: { offset, limit, where, include: { model: 'Variable', include: 'Letter' }, ...params } });
    yield put(getOrderVariablesSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getOrderVariablesFailed({ message: error.message }));
  }
}

function* getOrderVariable({ payload }) {
  const { id } = payload;
  try {
    const data = yield call(OrderVariable.getOne, { id });
    yield put(getOrderVariableSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getOrderVariableFailed(error));
  }
}


// WATCH FOR ACTIONS
export function* watchFetchOrderVariables() {
  yield takeEvery("GET_ORDER_VARIABLES", fetchOrderVariables);
}

export function* watchGetOrderVariable() {
  yield takeEvery("GET_ORDER_VARIABLE", getOrderVariable);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchOrderVariables),
    fork(watchGetOrderVariable),
  ]);
}
