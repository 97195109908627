import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { createInput, updateInput, getInput } from '../../redux/actions';
import { connect } from 'react-redux';

const styles = theme=>({
  buttons: {
    marginTop: theme.spacing.unit*3,
  },
});

class InputForm extends Component {
  state = {
    name: '',
    unit: '',
    min: '',
    max: '',
    group: ''
  };

  constructor(props) {
    super(props);
    this.id = this.props.match.params.inputId;
  }

  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps({ inputs }) {
    const { loading, error, item } = inputs;
    if (!loading && !error && item && this.id !== 'novo') {
      this.setState({
        name: item.name,
        unit: item.unit || '',
        min: item.min || '',
        max: item.max || '',
        group: item.group || ''
      });
    }
  }

  fetchData = ()=>{
    if (this.id !== 'novo') {
      this.props.getInput(this.id);
    }
  };

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { createInput, updateInput, match } = this.props;
    const { name, unit, min, max, group } = this.state;
    const data = {
      name,
      group,
      unit,
      min: parseInt(min, 10),
      max: parseInt(max, 10)
    };
    if (this.id !== 'novo') {
      updateInput(this.id, data);
    } else {
      createInput({ ...data, categoryId: match.params.id });
    }
  };

  render() {
    const { classes, inputs: { loading } } = this.props;
    const { name, unit, min, max, group } = this.state;
    
    return (
      <div className={classes.root}>
        <Typography variant="h4" component="h3" gutterBottom>
          {this.id !== 'novo' ? 'Editar' : 'Adicionar' } Entrada
        </Typography>
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Nome"
                name="name"
                value={name}
                onChange={this.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Grupo"
                name="group"
                value={group}
                onChange={this.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Unidade de medida"
                name="unit"
                value={unit}
                onChange={this.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Valor mínimo"
                name="min"
                value={min}
                type="number"
                onChange={this.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Valor máximo"
                name="max"
                value={max}
                type="number"
                onChange={this.handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              type="submit"
            >
              Salvar
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

InputForm.propTypes = {
  classes: PropTypes.object.isRequired,
  inputs: PropTypes.object.isRequired,
  createInput: PropTypes.func.isRequired,
  updateInput: PropTypes.func.isRequired,
  getInput: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = ({ inputs })=>{
  return { inputs };
};

export default withStyles(styles)(connect(mapStateToProps, { createInput, updateInput, getInput })(InputForm));