import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
  getBillingsFailed,
  getBillingsSuccess,
  createBillingSuccess,
  createBillingFailed,
  deleteBillingSuccess,
  deleteBillingFailed,
  getBillingSuccess,
  getBillingFailed,
  updateBillingSuccess,
  updateBillingFailed,
} from './actions';

const Billing = new Resource('Billing', 'billings');

// SAGAS
function* fetchBillings({ payload }) {
  const { offset, limit, where, params } = payload;
  try {
    const data = yield call(Billing.get, { params: { offset, limit, where, ...params } });
    yield put(getBillingsSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getBillingsFailed({ message: error.message }));
  }
}

function* createBilling({ payload }) {
  const { billing } = payload;
  try {
    const data = yield call(Billing.create, { payload: billing });
    yield put(createBillingSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(createBillingFailed(error));
  }
}

function* deleteBilling({ payload }) {
  const { id } = payload;
  try {
    yield call(Billing.delete, { id });
    yield put(deleteBillingSuccess());
  } catch (error) {
    console.error("Error in saga", error);
    yield put(deleteBillingFailed(error));
  }
}

function* getBilling({ payload }) {
  const { id, params } = payload;
  try {
    const data = yield call(Billing.getOne, { id, params });
    yield put(getBillingSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getBillingFailed(error));
  }
}

function* updateBilling({ payload }) {
  const { id, billing } = payload;
  try {
    const data = yield call(Billing.update, { id, payload: billing });
    yield put(updateBillingSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(updateBillingFailed(error));
  }
}


// WATCH FOR ACTIONS
export function* watchFetchBillings() {
  yield takeEvery("GET_BILLINGS", fetchBillings);
}

export function* watchGetBilling() {
  yield takeEvery("GET_BILLING", getBilling);
}

export function* watchCreateBilling() {
  yield takeEvery("CREATE_BILLING", createBilling);
}

export function* watchDeleteBilling() {
  yield takeEvery("DELETE_BILLING", deleteBilling);
}

export function* watchUpdateBilling() {
  yield takeEvery("UPDATE_BILLING", updateBilling);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchBillings),
    fork(watchGetBilling),
    fork(watchCreateBilling),
    fork(watchDeleteBilling),
    fork(watchUpdateBilling),
  ]);
}
