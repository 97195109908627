import React from 'react';
import { get, map, sortBy } from 'lodash';

import Tooltip from '@material-ui/core/Tooltip';

import Datagrid, { DatagridCell, EditButton, RemoveButton } from '../../components/Datagrid';
import { FabButton } from '../../components/FabToolbar';

import CategoryForm from './form';

const ListShow = ({ item, prop, nameRef="name", len=3 })=>{
  const sorted = sortBy(get(item, prop), nameRef);
  const overflow = sorted.splice(len);


  const result = map(sorted, nameRef).join(', ');

  if (!overflow.length) {
    return result;
  }

  return (
    <Tooltip title={map(overflow, nameRef).join(', ')}>
      <span>{`${result} e mais ${overflow.length}`}</span>
    </Tooltip>
  );
};

const params = {
  include: [{
    model: 'Measure',
    include: 'Unit'
  }, {
    model: 'Input',
  }, {
    model: 'View'
  }]
};

const CategoryList = props=>(
  <Datagrid
    title="Categorias"
    name="Categoria"
    resource="categories"
    form={CategoryForm}
    params={params}
    complex
  >
    <FabButton type="add" fab />
    <DatagridCell prop="id" label="Id" />
    <DatagridCell prop="name" label="Nome" />
    <DatagridCell label="Medidas">
      <ListShow prop="Measures" nameRef="Unit.name" />
    </DatagridCell>
    <DatagridCell label="Entradas">
      <ListShow prop="Inputs" />
    </DatagridCell>
    <DatagridCell label="Resultados">
      <ListShow prop="Views" />
    </DatagridCell>
    <DatagridCell numeric>
      <EditButton />
      <RemoveButton
        name={i=>`${get(i, 'name')}`}
      />
    </DatagridCell>
  </Datagrid>
);

export default CategoryList;