import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Zoom from '@material-ui/core/Zoom';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';

import AddIcon from '@material-ui/icons/Add';

import { Link, withRouter } from 'react-router-dom';

const styles = theme=>({
  root: {
    position: 'relative',
    marginLeft: theme.spacing.unit*2,
  },
  progress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
});

class FabButton extends PureComponent {
  state = {
    loading: false
  };

  handleClick = event=>{
    const { onClick } = this.props;
    if (typeof onClick === 'function') {
      const request = onClick(event);
      if (request instanceof Promise) {
        this.setState({loading: true}, ()=>{
          request
            .then(shouldUpdate=>{
              if (shouldUpdate) {
                this.setState({loading: false});
              }
            });
        });
      }
    }
  };

  render() {
    const { classes, match, type, title = '', children, path, color = 'primary' } = this.props;
    const { loading } = this.state;
    let to, content;

    switch(type) {
      case 'add':
        to = `${match.url}/novo`;
        content = <AddIcon />;
      break;
      default:
        to = path;
        content = children;
      break;
    }

    return (
      <div className={classes.root}>
        <Tooltip title={title} placement="top">
          <Zoom in>
            <div>
              <Fab
                component={to&&Link}
                to={to}
                color={color}
                onClick={this.handleClick}
                disabled={loading}
                aria-label={title}
              >
                {content}
              </Fab>
              {loading&&(
                <CircularProgress
                  size={68}
                  className={classes.progress}
                />
              )}
            </div>
          </Zoom>
        </Tooltip>
      </div>
    );
  }
}

FabButton.propTypes = {
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  title: PropTypes.string,
  onClick: PropTypes.func,
  path: PropTypes.string,
  type: PropTypes.oneOf(['add']),
};

export default withRouter(withStyles(styles)(FabButton));