import { GET, PUT } from './utils/http-methods'
import { Resource } from './resource'
export const GET_ME = 'GET_ME'
export const UPDATE_ME = 'UPDATE_ME'
const ME = 'ME'


export class UserResource extends Resource {
  constructor() {
    super('User', 'users')
    this.createEndpoint(ME, `${this.API_URL}/${this.pathname}/me`)
    this.createAction(GET_ME, GET, ME)
    this.createAction(UPDATE_ME, PUT, ME)
  }

  getMe = params => this._fetchResource(GET_ME, params)
  updateMe = params => this._fetchResource(UPDATE_ME, params)
}