import { combineReducers } from 'redux';

import auth from './auth/reducer';
import datagridList from './datagridList/reducer';
import datagridItem from './datagridItem/reducer';
import users from './users/reducer';
import units from './units/reducer';
import factories from './factories/reducer';
import billings from './billings/reducer';
import markets from './markets/reducer';
import marketTaxes from './marketTaxes/reducer';
import shippings from './shippings/reducer';
import categories from './categories/reducer';
import measures from './measures/reducer';
import priceLogs from './priceLogs/reducer';
import products from './products/reducer';
import orders from './orders/reducer';
import orderVariables from './orderVariables/reducer';
import inputs from './inputs/reducer';
import variables from './variables/reducer';
import compares from './compares/reducer';
import compareVariables from './compareVariables/reducer';
import views from './views/reducer';
import productBillings from './productBillings/reducer';
import productValues from './productValues/reducer';
import productCategories from './productCategories/reducer';
import productFactories from './productFactories/reducer';
import factoryBillings from './factoryBillings/reducer';
import snackbar from './snackbar/reducer';

const reducers = combineReducers({
  auth,
  datagridList,
  datagridItem,
  users,
  units,
  factories,
  billings,
  markets,
  marketTaxes,
  shippings,
  categories,
  measures,
  orders,
  orderVariables,
  priceLogs,
  products,
  inputs,
  variables,
  compares,
  compareVariables,
  views,
  productBillings,
  productValues,
  productFactories,
  factoryBillings,
  productCategories,
  snackbar
});

export default reducers;