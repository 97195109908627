import React, { Children } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { map } from 'lodash';

const DatagridHeader = ({ columns, data })=>(
  <TableHead>
    <TableRow>
      {Children.map(columns, ({ props }, index)=>{
        const { numeric, keys, label } = props;
        
        if (typeof keys === 'object' && Object.keys(keys).length) {
          return map(keys, (label, key)=>(
            <TableCell
              key={key}
              align={numeric ? 'right' : 'left'}
            >
              {label}
            </TableCell>
          ))
        }

        return (
          <TableCell
            key={index}
            align={numeric ? 'right' : 'left'}
          >
            {label}
          </TableCell>
        );
      })}
    </TableRow>
  </TableHead>
);

export default DatagridHeader;