import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { get, map, sortBy } from 'lodash';

import FormSwitch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Datagrid, { DatagridCell, EditButton, RemoveButton } from '../../components/Datagrid';
import { FabButton } from '../../components/FabToolbar';

import FactoryForm from './form';

const styles = theme=>({
  filter: {
    marginBottom: theme.spacing.unit*2
  },
});

const ShippingList = ({ item })=>(
  map(sortBy(item.Shippings, 'name'), sh=>(
    <span key={sh.id} style={{ marginRight: 12 }}>
      {get(sh, 'name')}:<strong>R$ {get(sh, 'cost').toFixed(2)}</strong>
    </span>
  ))
);

const params = {
  include: [{
    model: 'Shipping',
  }, {
    model: 'FactoryBilling',
    include: {
      model: 'Billing'
    }
  }]
};

class FactoryList extends Component {
  state = {
    expanded: false,
  };

  toggle = prop=>event=>{
    const { checked } = event.target;
    this.setState({ [prop]: checked });
  };

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;

    return (
      <Datagrid
        title="Locais de produção"
        name="Local de produção"
        resource="factories"
        form={FactoryForm}
        params={params}
        complex
      >
        <div className={classes.filter} filter="true">
          <FormControlLabel
            control={
              <FormSwitch
                checked={expanded}
                onChange={this.toggle('expanded')}
              />
            }
            label="Faturamentos"
          />
        </div>
        <FabButton type="add" fab />
        <DatagridCell prop="id" label="Id" />
        <DatagridCell prop="name" label="Nome" />
        <DatagridCell prop="icms" append="%" label="ICMS" numeric />
        {expanded&&(
          <DatagridCell
            prop="FactoryBillings"
            groupBy="billingId"
            label="Billing.name"
            groupProp="icms"
            numeric
          />
        )}
        <DatagridCell label="Fretes">
          <ShippingList />
        </DatagridCell>
        <DatagridCell numeric>
          <EditButton />
          <RemoveButton
            name={i=>`${get(i, 'name')}`}
          />
        </DatagridCell>
      </Datagrid>
    );
  }
}

FactoryList.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FactoryList);