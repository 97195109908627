import React from 'react';
import { get } from 'lodash';

import Datagrid, { DatagridCell, EditButton, RemoveButton } from '../../components/Datagrid';
import { FabButton } from '../../components/FabToolbar';

import UnitForm from './form';

const UnitList = props=>(
  <Datagrid
    title="Unidades de medida"
    name="Unidade de medida"
    resource="units"
    form={UnitForm}
  >
    <FabButton type="add" fab />
    <DatagridCell prop="id" label="Id" />
    <DatagridCell prop="name" label="Nome" />
    <DatagridCell numeric>
      <EditButton />
      <RemoveButton
        name={i=>`${get(i, 'name')}`}
      />
    </DatagridCell>
  </Datagrid>
);

export default UnitList;