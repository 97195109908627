import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import red from '@material-ui/core/colors/red';

import { getViews, deleteView, invalidateListItem, showSnackbar } from '../../redux/actions';
import { connect } from 'react-redux';

const styles = theme=>({
  red: {
    color: red[600],
  },
  buttons: {
    marginTop: theme.spacing.unit*3,
  },
  muted: {
    color: theme.palette.grey[600]
  },
});

class ViewList extends PureComponent {
  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps(props) {
    const { showSnackbar, views, where, invalidateListItem } = props;
    const { loading, error, errorMessage, action } = views;

    if (where !== this.props.where) {
      this.fetchData(props);
    }
    if (!loading) {
      if (error) {
        showSnackbar('error', errorMessage);
      } else if (action) {
        switch (action) {
          case 'CREATED':
            showSnackbar('success', `Resultado adicionado com sucesso`);
          break;
          case 'UPDATED':
            showSnackbar('success', `Resultado atualizado com sucesso`);
          break;
          case 'DELETED':
            showSnackbar('success', `Resultado removido com sucesso`);
          break;
          default:
            console.info('unknown action:', action);
          break;
        }
        invalidateListItem();
        this.fetchData(props);
      }
    }
  }

  fetchData = (props = this.props)=>{
    const { getViews, where } = props;
    if (where) {
      getViews(0, null, where);
    }
  };

  handleRemoveView = viewId=>event=>{
    event.preventDefault();
    const { deleteView } = this.props;
    deleteView(viewId);
  };

  render() {
    const { classes, views: { list }, where, children, match } = this.props;

    if (!where) {
      return null;
    }

    return (
      <Fragment>
        {children}
        <Typography variant="subtitle1">Resultados</Typography>
        <Typography className={classes.muted}>Itens do cálculo visíveis para o vendedor</Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>FOB</TableCell>
              <TableCell>CIF</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              list
                .map(view=>{
                  return (
                    <TableRow key={view.id} hover>
                      <TableCell component="th" scope="row">
                        {view.name}
                      </TableCell>
                      <TableCell>{view.fob}</TableCell>
                      <TableCell>{view.cif}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          component={Link}
                          to={`${match.url}/resultados/${view.id}`}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton onClick={this.handleRemoveView(view.id)}>
                          <DeleteIcon className={classes.red} fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })
            }
          </TableBody>
        </Table>
        
        <div className={classes.buttons}>
          <Button
            component={Link}
            to={`${match.url}/resultados/novo`}
            color="primary"
            type="submit"
            variant="outlined"
          >
            Adicionar resultado
          </Button>
        </div>
      </Fragment>
    );
  }
};

ViewList.propTypes = {
  classes: PropTypes.object.isRequired,
  views: PropTypes.object.isRequired,
  getViews: PropTypes.func.isRequired,
  deleteView: PropTypes.func.isRequired,
  invalidateListItem: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  where: PropTypes.object,
  children: PropTypes.node,
};

const mapStateToProps = ({ views })=>{
  return { views };
};

export default withStyles(styles)(connect(mapStateToProps, { getViews, deleteView, invalidateListItem, showSnackbar })(ViewList));