import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

import {
  getCategories,
  getFactories,
  getMarketTaxes,
  createMarketTax,
  deleteMarketTax,
  invalidateListItem,
  showSnackbar
} from '../../redux/actions';
import { connect } from 'react-redux';

import { get, sortBy } from 'lodash';

const styles = theme=>({
  chipItem: {
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
  buttons: {
    marginTop: theme.spacing.unit*2,
  },
  muted: {
    color: theme.palette.grey[600]
  },
});

class MarketTaxList extends PureComponent {
  state = {
    anchorEl: null,
  };

  componentWillMount() {
    const { getCategories, getFactories } = this.props;
    getCategories(0, null, null);
    getFactories(0, null, null);
    this.fetchData();
  }

  componentWillReceiveProps(props) {
    const { showSnackbar, categories, factories, marketTaxes, data, invalidateListItem } = props;
    const { loading, action, error, errorMessage } = marketTaxes;

    if (data !== this.props.data) {
      this.fetchData(props);
    }
    if (!categories.loading) {
      if (categories.error) {
        showSnackbar('error', categories.errorMessage);
      }
    }
    if (!factories.loading) {
      if (factories.error) {
        showSnackbar('error', factories.errorMessage);
      }
    }
    if (!loading) {
      if (error) {
        showSnackbar('error', errorMessage);
      } else if (action !== this.props.marketTaxes.action) {
        switch (action) {
          case 'CREATED':
            showSnackbar('success', `Taxa adicionado com sucesso`);
          break;
          case 'DELETED':
            showSnackbar('success', `Taxa removido com sucesso`);
          break;
          default:
            console.info('unknown action:', action);
          break;
        }
        invalidateListItem();
        this.fetchData(props);
      }
    }
  }

  fetchData = (props = this.props)=>{
    const { getMarketTaxes, data } = props;
    if (data && data.id) {
      getMarketTaxes(0, null, { marketId: data.id });
    }
  };

  handleAddTax = categoryId=>event=>{
    this.setState({ anchorEl: null });
    const { createMarketTax, data } = this.props;
    createMarketTax({ marketId: data.id, categoryId });
  };

  handleRemoveTax = mTaxId=>event=>{
    event.preventDefault();
    const { deleteMarketTax } = this.props;
    deleteMarketTax(mTaxId);
  };

  handleClick = event=>{
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = ()=>{
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, categories, factories, marketTaxes: { list }, data, children, match } = this.props;

    if (!data || !data.id) {
      return null;
    }

    const currentList = {};
    list.forEach(mt=>{
      const factories = currentList[mt.categoryId] || [];
      factories.push(mt.factoryId);
      currentList[mt.categoryId] = factories;
    });
    const categoryChoices = categories.list
      .filter(cl=>{
        const list = currentList[cl.id];
        return !list || factories.list.find(fa=>!list.includes(fa.id))
      });

    return (
      <Fragment>
        {children}
        <Typography variant="subtitle1">Taxas</Typography>
        <Typography className={classes.muted}>Taxas entre mercado, categoria e fábrica</Typography>
        {
          sortBy(list.map(marketTax=>{
            return {
              category: categories.list.find(ca=>ca.id===marketTax.categoryId),
              factory: factories.list.find(fa=>fa.id===marketTax.factoryId),
              marketTax
            }
          }), ['category.name', 'factory.name'])
            .map(({ marketTax: mTax, category, factory })=>{
              const label = <span>
                {get(category, 'name')} - {get(factory, 'name')} = <strong>{get(mTax, 'value')}%</strong>
              </span>;
              return (
                <Chip
                  key={mTax.id}
                  component={Link}
                  to={`${match.url}/taxas/${mTax.id}`}
                  clickable
                  label={label}
                  onDelete={this.handleRemoveTax(mTax.id)}
                  className={classes.chipItem}
                />
              )
            })
        }

        {!!categoryChoices.length&&(
          <div className={classes.buttons}>
            <Button
              component={Link}
              variant="outlined"
              to={`${match.url}/taxas/novo`}
              color="primary"
            >
              Adicionar taxa
            </Button>
          </div>
        )}
      </Fragment>
    );
  }
};

MarketTaxList.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  factories: PropTypes.object.isRequired,
  marketTaxes: PropTypes.object.isRequired,
  getCategories: PropTypes.func.isRequired,
  getFactories: PropTypes.func.isRequired,
  getMarketTaxes: PropTypes.func.isRequired,
  createMarketTax: PropTypes.func.isRequired,
  deleteMarketTax: PropTypes.func.isRequired,
  invalidateListItem: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  children: PropTypes.node,
};

const mapStateToProps = ({ categories, factories, marketTaxes })=>{
  return { categories, factories, marketTaxes };
};

export default withStyles(styles)(connect(mapStateToProps, {
  getCategories,
  getFactories,
  getMarketTaxes,
  createMarketTax,
  deleteMarketTax,
  invalidateListItem,
  showSnackbar
})(MarketTaxList));