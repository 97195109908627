import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
  getMarketTaxesFailed,
  getMarketTaxesSuccess,
  createMarketTaxSuccess,
  createMarketTaxFailed,
  deleteMarketTaxSuccess,
  deleteMarketTaxFailed,
  getMarketTaxSuccess,
  getMarketTaxFailed,
  updateMarketTaxSuccess,
  updateMarketTaxFailed,
} from './actions';

const MarketTax = new Resource('MarketTax', 'marketTaxes');

// SAGAS
function* fetchMarketTaxes({ payload }) {
  const { offset, limit, where } = payload;
  try {
    const data = yield call(MarketTax.get, { params: { offset, limit, where } });
    yield put(getMarketTaxesSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getMarketTaxesFailed({ message: error.message }));
  }
}

function* createMarketTax({ payload }) {
  const { marketTax } = payload;
  try {
    yield call(MarketTax.create, { payload: marketTax });
    yield put(createMarketTaxSuccess());
  } catch (error) {
    console.error("Error in saga", error);
    yield put(createMarketTaxFailed(error));
  }
}

function* deleteMarketTax({ payload }) {
  const { id } = payload;
  try {
    yield call(MarketTax.delete, { id });
    yield put(deleteMarketTaxSuccess());
  } catch (error) {
    console.error("Error in saga", error);
    yield put(deleteMarketTaxFailed(error));
  }
}

function* getMarketTax({ payload }) {
  const { id } = payload;
  try {
    const data = yield call(MarketTax.getOne, { id });
    yield put(getMarketTaxSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getMarketTaxFailed(error));
  }
}

function* updateMarketTax({ payload }) {
  const { id, marketTax } = payload;
  try {
    const data = yield call(MarketTax.update, { id, payload: marketTax });
    yield put(updateMarketTaxSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(updateMarketTaxFailed(error));
  }
}


// WATCH FOR ACTIONS
export function* watchFetchMarketTaxes() {
  yield takeEvery("GET_MARKET_TAXES", fetchMarketTaxes);
}

export function* watchGetMarketTax() {
  yield takeEvery("GET_MARKET_TAX", getMarketTax);
}

export function* watchCreateMarketTax() {
  yield takeEvery("CREATE_MARKET_TAX", createMarketTax);
}

export function* watchDeleteMarketTax() {
  yield takeEvery("DELETE_MARKET_TAX", deleteMarketTax);
}

export function* watchUpdateMarketTax() {
  yield takeEvery("UPDATE_MARKET_TAX", updateMarketTax);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchMarketTaxes),
    fork(watchGetMarketTax),
    fork(watchCreateMarketTax),
    fork(watchDeleteMarketTax),
    fork(watchUpdateMarketTax),
  ]);
}
