export const getMeasures = (offset, limit, filter = {}, params) => ({
    type: "GET_MEASURES",
    payload: { offset, limit, where: filter }
});

export const getMeasuresSuccess = (data) => ({
    type: "GET_MEASURES_SUCCESS",
    payload: { data }
});

export const getMeasuresFailed = (error) => ({
    type: "GET_MEASURES_FAILED",
    payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter medidas" }
});

export const getMeasure = (id) => ({
    type: "GET_MEASURE",
    payload: { id }
});

export const getMeasureSuccess = (data) => ({
    type: "GET_MEASURE_SUCCESS",
    payload: { data }
});

export const getMeasureFailed = (error) => ({
    type: "GET_MEASURE_FAILED",
    payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter medida" }
});

export const createMeasure = (measure) => ({
    type: "CREATE_MEASURE",
    payload: { measure }
});

export const createMeasureSuccess = () => ({
    type: "CREATE_MEASURE_SUCCESS",
    payload: {}
});

export const createMeasureFailed = (error) => ({
    type: "CREATE_MEASURE_FAILED",
    payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar medida" }
});

export const updateMeasure = (id, measure) => ({
    type: "UPDATE_MEASURE",
    payload: { id, measure }
});

export const updateMeasureSuccess = () => ({
    type: "UPDATE_MEASURE_SUCCESS",
    payload: {}
});

export const updateMeasureFailed = (error) => ({
    type: "UPDATE_MEASURE_FAILED",
    payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar medida" }
});

export const deleteMeasure = (id) => ({
    type: "DELETE_MEASURE",
    payload: { id }
});

export const deleteMeasureSuccess = () => ({
    type: "DELETE_MEASURE_SUCCESS",
    payload: {}
});

export const deleteMeasureFailed = (error) => ({
    type: "DELETE_MEASURE_FAILED",
    payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar medida" }
});