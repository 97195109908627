import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
  getShippingsFailed,
  getShippingsSuccess,
  createShippingSuccess,
  createShippingFailed,
  deleteShippingSuccess,
  deleteShippingFailed,
  getShippingSuccess,
  getShippingFailed,
  updateShippingSuccess,
  updateShippingFailed,
} from './actions';

const Shipping = new Resource('Shipping', 'shippings');

// SAGAS
function* fetchShippings({ payload }) {
  const { offset, limit, where, params } = payload;
  try {
    const data = yield call(Shipping.get, { params: { offset, limit, where, ...params } });
    yield put(getShippingsSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getShippingsFailed({ message: error.message }));
  }
}

function* createShipping({ payload }) {
  const { shipping } = payload;
  try {
    const data = yield call(Shipping.create, { payload: shipping });
    yield put(createShippingSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(createShippingFailed(error));
  }
}

function* deleteShipping({ payload }) {
  const { id } = payload
  try {
    yield call(Shipping.delete, { id });
    yield put(deleteShippingSuccess());
  } catch (error) {
    console.error("Error in saga", error);
    yield put(deleteShippingFailed(error));
  }
}

function* getShipping({ payload }) {
  const { id, params } = payload
  try {
    const data = yield call(Shipping.getOne, { id, params });
    yield put(getShippingSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getShippingFailed(error));
  }
}

function* updateShipping({ payload }) {
  const { id, shipping } = payload
  try {
    const data = yield call(Shipping.update, { id, payload: shipping });
    yield put(updateShippingSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(updateShippingFailed(error));
  }
}


// WATCH FOR ACTIONS
export function* watchFetchShippings() {
  yield takeEvery("GET_SHIPPINGS", fetchShippings);
}

export function* watchGetShipping() {
  yield takeEvery("GET_SHIPPING", getShipping);
}

export function* watchCreateShipping() {
  yield takeEvery("CREATE_SHIPPING", createShipping);
}

export function* watchDeleteShipping() {
  yield takeEvery("DELETE_SHIPPING", deleteShipping);
}

export function* watchUpdateShipping() {
  yield takeEvery("UPDATE_SHIPPING", updateShipping);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchShippings),
    fork(watchGetShipping),
    fork(watchCreateShipping),
    fork(watchDeleteShipping),
    fork(watchUpdateShipping),
  ]);
}
