import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import { loginUser, showSnackbar } from '../../redux/actions';
import AppLogin from '../../components/AppLogin';
import { Link } from 'react-router-dom';

const styles = theme=>({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    marginTop: theme.spacing.unit,
  },
  linkText: {
    textAlign: 'center',
    marginTop: theme.spacing.unit*2,
  }
});

class Login extends Component {
  state = {
    email: '',
    password: '',
    remember: true,
  };

  componentWillReceiveProps(nextProps, nextState) {
    const { loading, error, errorMessage } = nextProps.auth;
    if (!loading && error) {
      this.props.showSnackbar('error', errorMessage);
    }
  }

  handleChange = event=>{
    const { name, value, checked, type } = event.target;
    this.setState({
      [name]: type === 'checkbox' ? checked : value
    });
  };

  handleLogin = event=>{
    event.preventDefault();
    const { email, password, remember } = this.state;
    const { loginUser } = this.props;
    loginUser({ email, password, remember });
  };

  render() {
    const { auth, classes } = this.props;
    const { email, password, remember } = this.state;
    const { loading } = auth;

    return (
      <AppLogin title="Login">
        <form className={classes.form} onSubmit={this.handleLogin}>
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="email">E-mail</InputLabel>
            <Input
              id="email"
              name="email"
              onChange={this.handleChange}
              value={email}
              autoComplete="email"
              autoFocus
            />
          </FormControl>
          
          <FormControl margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Senha</InputLabel>
            <Input
              name="password"
              type="password"
              id="password"
              onChange={this.handleChange}
              value={password}
              autoComplete="current-password"
            />
          </FormControl>

          <FormControlLabel
            control={<Checkbox
              name="remember"
              checked={remember}
              onChange={this.handleChange}
            />}
            label="Manter conectado"
          />
          
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={loading}
          >
            Entrar
          </Button>

          <Typography
            component={Link}
            className={classes.linkText}
            to="/esqueci_senha"
          >
            Esqueceu sua senha?
          </Typography>
        </form>
      </AppLogin>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth })=>{
  return { auth };
};

export default withStyles(styles)(connect(mapStateToProps, { loginUser, showSnackbar })(Login));