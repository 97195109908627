import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import { createProductValue, updateProductValue, deleteProductValue } from '../../redux/actions';
import { connect } from 'react-redux';

import { get, isNil } from 'lodash';

class ProductValueForm extends PureComponent {
  constructor(props) {
    super(props);

    const value = get(props, 'data.value');
    this.state = {
      editing: isNil(value),
      value: !isNil(value) ? value : ''
    };
  }

  componentWillReceiveProps({ data }) {
    if (this.props.data !== data) {
      const value = get(data, 'value');
      this.setState({
        editing: isNil(value),
        value: !isNil(value) ? value : ''
      });
    }
  }

  handleChange = event=>{
    const { value } = event.target;
    this.setState({ value });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { removable, deleteProductValue, updateProductValue, createProductValue, data, where, unit } = this.props;
    const { editing, value } = this.state;
    const productValueId = get(data, 'id');

    if (removable) {
      deleteProductValue(productValueId);
    } else if (!editing) {
      this.setState({ editing: true });
    } else {
      if (productValueId) {
        updateProductValue(productValueId, { value });
      } else {
        createProductValue({ ...where, unitId: unit.id, value });
      }
    }
  };

  getIcon = ()=>{
    const { removable } = this.props;
    const { editing } = this.state;

    if (removable) {
      return <DeleteIcon />;
    }
    if (editing) {
      return <SaveIcon />;
    }
    return <EditIcon />;
  };

  render() {
    const { productValues: { loading }, unit } = this.props;
    const { editing, value } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <TextField
          label={unit.name}
          value={value}
          type="number"
          onChange={this.handleChange}
          disabled={loading||!editing}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  type="submit"
                  disabled={loading||isNil(value)}
                >
                  {this.getIcon()}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </form>
    );
  }
};

ProductValueForm.propTypes = {
  productValues: PropTypes.object.isRequired,
  createProductValue: PropTypes.func.isRequired,
  updateProductValue: PropTypes.func.isRequired,
  deleteProductValue: PropTypes.func.isRequired,
  unit: PropTypes.object.isRequired,
  data: PropTypes.object,
  where: PropTypes.object,
  removable: PropTypes.bool,
};

const mapStateToProps = ({ productValues })=>{
  return { productValues };
};

export default connect(mapStateToProps, {
  createProductValue,
  updateProductValue,
  deleteProductValue
})(ProductValueForm);