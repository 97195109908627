import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import {
  getCategories,
  getFactories,
  getProducts,
  recalculatePrices,
  invalidateListItem,
  showSnackbar
} from '../../redux/actions';
import { connect } from 'react-redux';

import { get, map, without, sortBy } from 'lodash';

const styles = theme=>({
  buttons: {
    marginTop: theme.spacing.unit*3,
  },
  button: {
    marginLeft: theme.spacing.unit*2,
  },
  filter: {
    marginTop: theme.spacing.unit*4,
  }
});

class UpdatePriceForm extends Component {
  state = {
    name: '',
    amount: '',
    categories: [],
    factories: [],
  };

  componentWillMount() {
    this.props.getCategories(
      getFactories,0, null, null);
  }

  componentWillReceiveProps(props) {
    const { showSnackbar, priceLogs, invalidateListItem, history, location } = props;
    const { loading, error, errorMessage, action } = priceLogs;
    const backUrl = get(location, 'state.backUrl');

    if (!loading) {
      if (error !== this.props.priceLogs.error) {
        showSnackbar('error', errorMessage);
      } else if (action) {
        switch (action) {
          case 'CREATED':
            showSnackbar('success', `Preços atualizados com sucesso`);
          break;
          default:
            console.info('unknown action:', action);
          break;
        }
        invalidateListItem();
        if (backUrl) {
          history.push(backUrl);
        } else {
          this.setState({
            name: '',
            amount: '',
            categories: [],
            factories: []
          });
        }
      }
    }
  }

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  toggleCategory = categoryId=>event=>{
    this.setState(({ categories })=>({
      categories: categories.includes(categoryId)
        ? without(categories, categoryId)
        : categories.concat(categoryId)
    }), this.getProducts);
  };

  toggleFactory = factoryId=>event=>{
    this.setState(({ factories })=>({
      factories: factories.includes(factoryId)
        ? without(factories, factoryId)
        : factories.concat(factoryId)
    }), this.getProducts);
  };

  getProducts = ()=>{
    const { factories, categories } = this.state;

    if (!factories.length || !categories.length) {
      return;
    }

    this.props.getProducts(0, null, null, {
      include: [{
        model: 'ProductCategory',
        where: {
          categoryId: {
            in: categories
          }
        }
      }, {
        model: 'ProductFactory',
        where: {
          factoryId: {
            in: factories
          }
        }
      }]
    });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { name, amount, categories, factories } = this.state;
    this.props.recalculatePrices({
      name,
      amount,
      filter: {
        include: [{
          model: 'ProductCategory',
          where: {
            categoryId: {
              in: categories
            }
          }
        }, {
          model: 'ProductFactory',
          where: {
            factoryId: {
              in: factories
            }
          }
        }]
      }
    })
  };

  render() {
    const {
      classes,
      priceLogs: { loading },
      categories: { list: categoriesList },
      factories: { list: factoriesList },
      products: { list },
      location
    } = this.props;
    const { name, amount, categories, factories } = this.state;
    const backUrl = get(location, 'state.backUrl');

    return (
      <form onSubmit={this.handleSubmit}>
        <Grid container spacing={16}>
          <Grid item xs={12} md={8} lg={6} xl={4}>
            <TextField
              label="Nome"
              value={name}
              onChange={this.handleChange}
              name="name"
              disabled={loading}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              label="Valor"
              value={amount}
              onChange={this.handleChange}
              type="number"
              name="amount"
              disabled={loading}
              fullWidth
            />
          </Grid>
        </Grid>

        <FormLabel component="legend" className={classes.filter}>
          Selecione as categorias onde o preço deve ser alterado
        </FormLabel>
        <FormGroup row>
          {map(categoriesList, ({ id, name })=>(
            <FormControlLabel
              control={
                <Checkbox
                  checked={categories.includes(id)}
                  onChange={this.toggleCategory(id)}
                />
              }
              key={id}
              label={name}
            />
          ))}
        </FormGroup>

        <FormLabel component="legend" className={classes.filter}>
          Selecione os locais de produção onde o preço deve ser alterado
        </FormLabel>
        <FormGroup row>
          {map(factoriesList, ({ id, name })=>(
            <FormControlLabel
              control={
                <Checkbox
                  checked={factories.includes(id)}
                  onChange={this.toggleFactory(id)}
                />
              }
              key={id}
              label={name}
            />
          ))}
        </FormGroup>

        {!!categories.length&&!!factories.length&&(
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Produto</TableCell>
                <TableCell align="right">Preço atual</TableCell>
                <TableCell align="right">Preço alterado</TableCell>
              </TableRow>
            </TableHead>
            {map(sortBy(list, 'name'), ({ id, name, price })=>{
              return (
                <TableBody key={id}>
                  <TableRow>
                    <TableCell>{name}</TableCell>
                    <TableCell align="right">{price}</TableCell>
                    <TableCell align="right">{price*amount}</TableCell>
                  </TableRow>
                </TableBody>
              );
            })}
          </Table>
        )}

        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            disabled={loading||!name||!amount||!categories.length||!factories.length}
            type="submit"
          >
            Aplicar
          </Button>
          {backUrl&&(
            <Button
              disabled={loading}
              className={classes.button}
              component={Link}
              to={backUrl}
            >
              Cancelar
            </Button>
          )}
        </div>
      </form>
    );
  }
};

UpdatePriceForm.propTypes = {
  classes: PropTypes.object.isRequired,
  priceLogs: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  getCategories: PropTypes.func.isRequired,
  factories: PropTypes.object.isRequired,
  getFactories: PropTypes.func.isRequired,
  products: PropTypes.object.isRequired,
  getProducts: PropTypes.func.isRequired,
  recalculatePrices: PropTypes.func.isRequired,
  invalidateListItem: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = ({ priceLogs, categories, factories, products })=>{
  return { priceLogs, categories, factories, products };
};

export default withStyles(styles)(connect(mapStateToProps, {
  getCategories,
  getFactories,
  getProducts,
  recalculatePrices,
  invalidateListItem,
  showSnackbar
})(UpdatePriceForm));