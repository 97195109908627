export const getUnits = (offset, limit, filter = {}, params)=>({
  type: "GET_UNITS",
  payload: { offset, limit, where: filter }
});

export const getUnitsSuccess = data=>({
  type: "GET_UNITS_SUCCESS",
  payload: { data }
});

export const getUnitsFailed = error=>({
  type: "GET_UNITS_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter unidades de medida" }
});

export const getUnit = id=>({
  type: "GET_UNIT",
  payload: { id }
});

export const getUnitSuccess = data=>({
  type: "GET_UNIT_SUCCESS",
  payload: { data }
});

export const getUnitFailed = error=>({
  type: "GET_UNIT_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter unidade de medida" }
});

export const createUnit = unit=>({
  type: "CREATE_UNIT",
  payload: { unit }
});

export const createUnitSuccess = ()=>({
  type: "CREATE_UNIT_SUCCESS",
  payload: {}
});

export const createUnitFailed = error=>({
  type: "CREATE_UNIT_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar unidade de medida" }
});

export const updateUnit = (id, unit)=>({
  type: "UPDATE_UNIT",
  payload: { id, unit }
});

export const updateUnitSuccess = ()=>({
  type: "UPDATE_UNIT_SUCCESS",
  payload: {}
});

export const updateUnitFailed = error=>({
  type: "UPDATE_UNIT_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar unidade de medida" }
});

export const deleteUnit = id=>({
  type: "DELETE_UNIT",
  payload: { id }
});

export const deleteUnitSuccess = ()=>({
  type: "DELETE_UNIT_SUCCESS",
  payload: {}
});

export const deleteUnitFailed = error=>({
  type: "DELETE_UNIT_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar unidade de medida" }
});