import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { get, filter } from 'lodash';

import Drawer from '@material-ui/core/Drawer';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { resources } from '../../routes';

import AuthBar from '../AuthBar';

const DRAWER_WIDTH = 240;
const IOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);

const styles = theme=>({
  root: {
    display: 'flex',
  },
  toolbar: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
    paddingRight: 12, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    [theme.breakpoints.up('md')]: {
      marginLeft: DRAWER_WIDTH,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 12,
    [theme.breakpoints.up('md')]: {
      marginRight: 24,
    },
  },
  menuButtonHidden: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'fixed',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    // transform: 'translate(0, 0)',
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('md')]: {
      marginLeft: DRAWER_WIDTH,
    },
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    minHeight: '100vh',
  },
  contentLarge: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing.unit * 9,
      width: `calc(100% - ${theme.spacing.unit * 9}px)`,
    },
  },
  active: {
    backgroundColor: theme.palette.action.selected
  },
  menuItem: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 24,
      paddingRight: 24,
    }
  }
});

class AppDrawer extends Component {
  state = {
    open: window.innerWidth>=1800,
    mobile: false,
  };

  handleDrawerOpen = ()=>{
    this.setState({
      open: true,
      mobile: true
    });
  };

  handleDrawerClose = ()=>{
    this.setState({
      open: false,
      mobile: false
    });
  };

  getRoutes = ()=>{
    const { currentUser } = this.props;

    const routes = get(currentUser, 'restricted')
      ? filter(resources, r=>r.restricted===false)
      : resources;

    return (
      <List component="nav">
        {routes
          .filter(r=>!!r.label)
          .map(({ label, url, Icon, key })=>(
            <ListItem
              component={NavLink}
              to={`${url}`}
              button
              key={key}
              className={this.props.classes.menuItem}
              activeClassName={this.props.classes.active}
            >
              {!!Icon&&(
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
              )}
              <ListItemText inset primary={label} />
            </ListItem>
          ))
        }
      </List>
    );
  };

  render() {
    const { classes, children, theme } = this.props;
    const { open, mobile } = this.state;

    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={classNames(
            classes.appBar,
            open&&classes.appBarShift
          )}
        >
          <Toolbar
            disableGutters={!open}
            className={classes.toolbar}
          >
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(
                classes.menuButton,
                open && classes.menuButtonHidden,
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
              component="h1"
            >
              Knauf Isopor Admin
            </Typography>
            <AuthBar />
          </Toolbar>
        </AppBar>
        <Hidden mdUp>
          <SwipeableDrawer
            disableBackdropTransition={!IOS}
            disableDiscovery={IOS}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobile}
            onOpen={this.handleDrawerOpen}
            onClose={this.handleDrawerClose}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {this.getRoutes()}
          </SwipeableDrawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            variant="permanent"
            classes={{
              paper: classNames(
                classes.drawerPaper,
                !open && classes.drawerPaperClose
              ),
            }}
            open={open}
          >
            <div className={classes.toolbarIcon}>
              <IconButton onClick={this.handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </div>
            <Divider />
            {this.getRoutes()}
          </Drawer>
        </Hidden>
        <main className={classNames(
          classes.content,
          !open && classes.contentLarge,
        )}>
          <div className={classes.appBarSpacer} />
          {children}
        </main>
      </div>
    );
  }
};

AppDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth })=>{
  const { currentUser } = auth;
  return { currentUser };
};

export default connect(mapStateToProps)(withStyles(styles, {withTheme: true})(AppDrawer));