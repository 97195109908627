import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { map, get, sortBy, find, each } from 'lodash';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { getOrderVariables } from '../../redux/actions';
import { connect } from 'react-redux';

import { priceFormat } from '../../resources/calculate';

import { Parser } from 'expr-eval';

const parser = new Parser();

const styles = theme=>({
  divider: {
    marginTop: theme.spacing.unit*3,
    marginBottom: theme.spacing.unit*2,
  },
  result: {
    marginRight: theme.spacing.unit*3
  },
  resume: {
    marginTop: theme.spacing.unit*4,
    marginBottom: theme.spacing.unit*4
  },
  chip: {
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit*2
  },
  chipItem: {
    display: 'inline',
  },
  chipRow: {
    display: 'block',
  },
  button: {
    marginTop: theme.spacing.unit*2,
  },
});

class OrderViews extends PureComponent {
  state = {
    open: false
  };

  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps(props) {
    if (this.props.order !== props.order) {
      this.fetchData(props);
    }
  }

  fetchData = (props = this.props)=>{
    const { order, getOrderVariables } = props;
    const orderId = get(order, 'id');
    if (orderId) {
      getOrderVariables(null, null, { orderId });
    }
  };

  toggleOpen = event=>{
    this.setState(({ open })=>({ open: !open }));
  };

  render() {
    const { classes, order, orderVariables: { list: variables } } = this.props;
    const { open } = this.state;

    if (!order) {
      return null;
    }

    const hasChecklist = !!find(variables, 'Variable.checklist');
    const show = !hasChecklist || open;

    const measures = get(order, 'OrderMeasures');
    const inputs = get(order, 'OrderInputs');

    const data = {};

    each(measures, measure=>{
      const letter = String(get(measure, 'Measure.Letter.letter')).toLowerCase();
      data[letter] = measure.value;
    });
    each(inputs, input=>{
      const letter = String(get(input, 'Input.Letter.letter')).toLowerCase();
      data[letter] = input.value;
    });
    each(variables, variable=>{
      const letter = String(get(variable, 'Variable.Letter.letter')).toLowerCase();
      data[letter] = variable.value;
    });

    return (
      <div className={classes.resume}>
        <Grid container spacing={24}>
          {map(sortBy(get(order, 'OrderViews'), 'viewId'), ({ View, value, id })=>{
            const { name } = View || {};
            return (
              <Grid key={id} item className={classes.result}>
                <Typography variant="overline">{name}</Typography>
                <Typography variant="h6">
                  {priceFormat(value)}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        
        <Divider className={classes.divider} />
        
        <Typography variant="h6" gutterBottom>Checklist</Typography>
        <div>
          {map(sortBy(variables, 'Variable.position'), ({ id, value, Variable })=>{
            const { name, checklist, formula } = Variable || {};
            if (!show && !checklist) {
              return null;
            }
            let debug = parser.parse(formula.toLowerCase().replace(/[\w\d]*\./g, ''));
            each(debug.variables({ withMembers: true }), v=>{
              const value = data[v];
              if (value) {
                debug = debug.substitute(v, value);
              }
            });
            return (
              <Tooltip title={debug.toString()} placement="top" key={id}>
                <Chip
                  className={classes.chip}
                  color={checklist?'secondary':'default'}
                  label={
                    <span>
                      {name}: <strong>{value}</strong>
                    </span>
                  }
                />
              </Tooltip>
            )
          })}
        </div>

        {!!show&&(
          <Fragment>
            <Typography variant="overline">Medidas</Typography>
            <div>
              {map(sortBy(measures, 'measureId'), ({ id, value, Measure })=>{

                return (
                  <Chip
                    className={classes.chip}
                    key={id}
                    label={
                      <span>
                        {get(Measure, 'Unit.name')}: <strong>{value}</strong>
                      </span>
                    }
                  />
                )
              })}
            </div>
          </Fragment>
        )}
        {hasChecklist&&(
          <Button
            className={classes.button}
            variant="contained"
            onClick={this.toggleOpen}
          >
            {open ? 'Somente resumo' : 'Mostrar tudo'}
          </Button>
        )}
      </div>
    );
  }
};

OrderViews.propTypes = {
  classes: PropTypes.object.isRequired,
  orderVariables: PropTypes.object.isRequired,
  getOrderVariables: PropTypes.func.isRequired,
  order: PropTypes.object,
};

const mapStateToProps = ({ orderVariables })=>{
  return { orderVariables };
};

export default withStyles(styles)(connect(mapStateToProps, { getOrderVariables })(OrderViews));