import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
  getProductFactoriesFailed,
  getProductFactoriesSuccess,
  createProductFactorySuccess,
  createProductFactoryFailed,
  deleteProductFactorySuccess,
  deleteProductFactoryFailed,
  getProductFactorySuccess,
  getProductFactoryFailed,
  updateProductFactorySuccess,
  updateProductFactoryFailed,
} from './actions';

const ProductFactory = new Resource('ProductFactory', 'productFactories')


// SAGAS
function* fetchProductFactories({ payload }) {
  const { access_token, offset, limit, where } = payload;
  try {
    const include = [
      {
        model: 'Factory',
        required: true,
        include: {
          model: 'FactoryBilling',
          include: {
            model: 'Billing'
          }
        }
      }, {
        model: 'ProductFactoryBilling'
      }
    ]
    const data = yield call(ProductFactory.get, { access_token, params: { offset, limit, where, include } });
    yield put(getProductFactoriesSuccess(data));
  } catch (error) {
    console.error("Error in saga", error)
    yield put(getProductFactoriesFailed({ message: error.message }));
  }
}

function* createProductFactory({ payload }) {
  const { access_token, productFactory } = payload
  try {
    yield call(ProductFactory.create, { access_token, payload: productFactory })
    yield put(createProductFactorySuccess())
  } catch (error) {
    console.error("Error in saga", error)
    yield put(createProductFactoryFailed(error))
  }
}

function* deleteProductFactory({ payload }) {
  const { access_token, id } = payload
  try {
    yield call(ProductFactory.delete, { access_token, id })
    yield put(deleteProductFactorySuccess())
  } catch (error) {
    console.error("Error in saga", error)
    yield put(deleteProductFactoryFailed(error))
  }
}

function* getProductFactory({ payload }) {
  const { access_token, id } = payload
  try {
    const include = [
      { model: 'Factory', required: true },
    ]
    const data = yield call(ProductFactory.getOne, { access_token, id, params: { include } })
    yield put(getProductFactorySuccess(data))
  } catch (error) {
    console.error("Error in saga", error)
    yield put(getProductFactoryFailed(error))
  }
}

function* updateProductFactory({ payload }) {
  const { access_token, id, productFactory } = payload
  try {
    const data = yield call(ProductFactory.update, { access_token, id, payload: productFactory })
    yield put(updateProductFactorySuccess(data))
  } catch (error) {
    console.error("Error in saga", error)
    yield put(updateProductFactoryFailed(error))
  }
}


// WATCH FOR ACTIONS
export function* watchFetchProductFactories() {
  yield takeEvery("GET_PRODUCT_FACTORIES", fetchProductFactories);
}

export function* watchGetProductFactory() {
  yield takeEvery("GET_PRODUCT_FACTORY", getProductFactory);
}

export function* watchCreateProductFactory() {
  yield takeEvery("CREATE_PRODUCT_FACTORY", createProductFactory);
}

export function* watchDeleteProductFactory() {
  yield takeEvery("DELETE_PRODUCT_FACTORY", deleteProductFactory);
}

export function* watchUpdateProductFactory() {
  yield takeEvery("UPDATE_PRODUCT_FACTORY", updateProductFactory);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchProductFactories),
    fork(watchGetProductFactory),
    fork(watchCreateProductFactory),
    fork(watchDeleteProductFactory),
    fork(watchUpdateProductFactory),
  ]);
}
