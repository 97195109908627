const INIT_STATE = {
  loading: false,
  error: false,
  errorMessage: null,
	list: [],
	item: null,
	action: null
};

const reducer = (state = INIT_STATE, action) => {
	switch (action.type) {
		case 'GET_PRICE_LOGS':
			return { ...state, loading: true, error: false, errorMessage: null, action: null };

		case 'GET_PRICE_LOGS_SUCCESS':
			return { ...state, loading: false, error: false, errorMessage: null, list: action.payload.data, action: null };

		case 'GET_PRICE_LOGS_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

		case 'GET_PRICE_LOG':
			return { ...state, loading: true, error: false, action: null };

		case 'GET_PRICE_LOG_SUCCESS':
			return { ...state, loading: false, error: false, item: action.payload.data, action: null };

		case 'GET_PRICE_LOG_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

		case 'RECALCULATE':
			return { ...state, loading: true, action: null }

		case 'RECALCULATE_SUCCESS':
			return { ...state, loading: false, error: false, action: 'CREATED', item: action.payload.data }

		case 'RECALCULATE_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null }

		case 'REBASE':
			return { ...state, loading: true, action: null }

		case 'REBASE_SUCCESS':
			return { ...state, loading: false, error: false, action: 'UPDATED', item: action.payload.data }

		case 'REBASE_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null }

		default:
			return state;

	}
};

export default reducer;