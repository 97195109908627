import { POST } from './utils/http-methods';
import { Resource } from './resource';
const RECALCULATE = 'RECALCULATE';
const REBASE = 'REBASE';

export class PriceLogResource extends Resource {
  constructor() {
    super('PriceLog', 'priceLogs');
    this.createEndpoint(RECALCULATE, `${this.API_URL}/${this.pathname}/recalculatePrice`);
    this.createAction(RECALCULATE, POST, RECALCULATE);
    this.createEndpoint(REBASE, `${this._endpoints.BY_ID}/rebase`);
    this.createAction(REBASE, POST, REBASE);
  }

  recalculate = payload=>this._fetchResource(RECALCULATE, { payload });
  rebase = params=>this._fetchResource(REBASE, params);
}