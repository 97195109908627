import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { UserResource } from '../../resources/user';
import {
  getUsersFailed,
  getUsersSuccess,
  createUserSuccess,
  createUserFailed,
  deleteUserSuccess,
  deleteUserFailed,
  getUserSuccess,
  getUserFailed,
  getUserMeSuccess,
  getUserMeFailed,
  updateUserSuccess,
  updateUserFailed,
  updateUserMeSuccess,
  updateUserMeFailed,
} from './actions';

const User = new UserResource();

// SAGAS
function* fetchUsers({ payload }) {
  const { offset, limit, where } = payload;
  try {
    const data = yield call(User.get, { params: { offset, limit, where, include: 'Profile' } });
    yield put(getUsersSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getUsersFailed({ message: error.message }));
  }
}

function* createUser({ payload }) {
  const { user } = payload;
  try {
    yield call(User.create, { payload: user });
    yield put(createUserSuccess());
  } catch (error) {
    console.error("Error in saga", error);
    yield put(createUserFailed(error));
  }
}

function* deleteUser({ payload }) {
  const { id } = payload;
  try {
    yield call(User.delete, { id });
    yield put(deleteUserSuccess());
  } catch (error) {
    console.error("Error in saga", error);
    yield put(deleteUserFailed(error));
  }
}

function* getUser({ payload }) {
  const { id } = payload;
  try {
    const data = yield call(User.getOne, { id, params: { include: 'Profile' } });
    yield put(getUserSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getUserFailed(error));
  }
}

function* getUserMe({ payload }) {
  const { access_token } = payload;
  try {
    const data = yield call(User.getMe, { access_token });
    yield put(getUserMeSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getUserMeFailed(error));
  }
}

function* updateUserMe({ payload }) {
  const { user, access_token } = payload;
  try {
    yield call(User.updateMe, { payload: user, access_token });
    yield put(updateUserMeSuccess());
  } catch (error) {
    console.error("Error in saga", error);
    yield put(updateUserMeFailed(error));
  }
}

function* updateUser({ payload }) {
  const { id, user } = payload;
  try {
    const data = yield call(User.update, { id, payload: user });
    yield put(updateUserSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(updateUserFailed(error));
  }
}

// WATCH FOR ACTIONS
export function* watchFetchUsers() {
  yield takeEvery("GET_USERS", fetchUsers);
}

export function* watchGetUser() {
  yield takeEvery("GET_USER", getUser);
}

export function* watchCreateUser() {
  yield takeEvery("CREATE_USER", createUser);
}

export function* watchDeleteUser() {
  yield takeEvery("DELETE_USER", deleteUser);
}

export function* watchUpdateUser() {
  yield takeEvery("UPDATE_USER", updateUser);
}

export function* watchGetUserMe() {
  yield takeEvery("GET_USER_ME", getUserMe);
}

export function* watchUpdateUserMe() {
  yield takeEvery("UPDATE_USER_ME", updateUserMe);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchUsers),
    fork(watchGetUser),
    fork(watchCreateUser),
    fork(watchDeleteUser),
    fork(watchUpdateUser),
    fork(watchUpdateUser),
    fork(watchGetUserMe),
    fork(watchUpdateUserMe),
  ]);
}
