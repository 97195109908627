export const getMarketTaxes = (offset, limit, filter = {}, params)=>({
  type: "GET_MARKET_TAXES",
  payload: { offset, limit, where: filter, params }
});

export const getMarketTaxesSuccess = data=>({
  type: "GET_MARKET_TAXES_SUCCESS",
  payload: { data }
});

export const getMarketTaxesFailed = error=>({
  type: "GET_MARKET_TAXES_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter mercados" }
});

export const getMarketTax = id=>({
  type: "GET_MARKET_TAX",
  payload: { id }
});

export const getMarketTaxSuccess = data=>({
  type: "GET_MARKET_TAX_SUCCESS",
  payload: { data }
});

export const getMarketTaxFailed = error=>({
  type: "GET_MARKET_TAX_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter mercado" }
});

export const createMarketTax = marketTax=>({
  type: "CREATE_MARKET_TAX",
  payload: { marketTax }
});

export const createMarketTaxSuccess = ()=>({
  type: "CREATE_MARKET_TAX_SUCCESS",
  payload: {}
});

export const createMarketTaxFailed = error=>({
  type: "CREATE_MARKET_TAX_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar mercado" }
});

export const updateMarketTax = (id, marketTax)=>({
  type: "UPDATE_MARKET_TAX",
  payload: { id, marketTax }
});

export const updateMarketTaxSuccess = ()=>({
  type: "UPDATE_MARKET_TAX_SUCCESS",
  payload: {}
});

export const updateMarketTaxFailed = error=>({
  type: "UPDATE_MARKET_TAX_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar mercado" }
});

export const deleteMarketTax = id=>({
  type: "DELETE_MARKET_TAX",
  payload: { id }
});

export const deleteMarketTaxSuccess = ()=>({
  type: "DELETE_MARKET_TAX_SUCCESS",
  payload: {}
});

export const deleteMarketTaxFailed = (error)=>({
  type: "DELETE_MARKET_TAX_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar mercado" }
});