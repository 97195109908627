const INIT_STATE = {
  loading: false,
  error: false,
  errorMessage: null,
	list: [],
	item: null,
	action: null,
	invalid: false
};

const reducer = (state = INIT_STATE, action) => {
	switch (action.type) {
		case 'INVALIDATE_VARIABLES':
      return { ...state, invalid: true };

		case 'GET_VARIABLES':
			return { ...state, loading: true, error: false, errorMessage: null, action: null, list: [], invalid: false };

		case 'GET_VARIABLES_SUCCESS':
			return { ...state, loading: false, error: false, errorMessage: null, list: action.payload.data, action: null };

		case 'GET_VARIABLES_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

		case 'GET_VARIABLE':
			return { ...state, loading: true, error: false, action: null, item: null };

		case 'GET_VARIABLE_SUCCESS':
			return { ...state, loading: false, error: false, item: action.payload.data, action: null };

		case 'GET_VARIABLE_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

		case 'CREATE_VARIABLE':
			return { ...state, loading: true, error: false, action: null }

		case 'CREATE_VARIABLE_SUCCESS':
			return { ...state, loading: false, error: false, action: 'CREATED', item: action.payload.data }

		case 'CREATE_VARIABLE_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null }

		case 'UPDATE_VARIABLE':
			return { ...state, loading: true, error: false, action: null }

		case 'UPDATE_VARIABLE_SUCCESS':
			return { ...state, loading: false, error: false, action: 'UPDATED', item: action.payload.data }

		case 'UPDATE_VARIABLE_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null }

		case 'DELETE_VARIABLE':
			return { ...state, loading: true, action: null }

		case 'DELETE_VARIABLE_SUCCESS':
			return { ...state, loading: false, error: false, action: 'DELETED' }

		case 'DELETE_VARIABLE_FAILED':
			return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null }

		default:
			return state;

	}
};

export default reducer;