export const getCategories = (offset, limit, filter = {}, params)=>({
  type: "GET_CATEGORIES",
  payload: { offset, limit, where: filter, params }
});

export const getCategoriesSuccess = data=>({
  type: "GET_CATEGORIES_SUCCESS",
  payload: { data }
});

export const getCategoriesFailed = error=>({
  type: "GET_CATEGORIES_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter mercados" }
});

export const getCategory = id=>({
  type: "GET_CATEGORY",
  payload: { id }
});

export const getCategorySuccess = data=>({
  type: "GET_CATEGORY_SUCCESS",
  payload: { data }
});

export const getCategoryFailed = error=>({
  type: "GET_CATEGORY_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter mercado" }
});

export const createCategory = category=>({
  type: "CREATE_CATEGORY",
  payload: { category }
});

export const createCategorySuccess = data=>({
  type: "CREATE_CATEGORY_SUCCESS",
  payload: { data }
});

export const createCategoryFailed = error=>({
  type: "CREATE_CATEGORY_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar mercado" }
});

export const updateCategory = (id, category)=>({
  type: "UPDATE_CATEGORY",
  payload: { id, category }
});

export const updateCategorySuccess = data=>({
  type: "UPDATE_CATEGORY_SUCCESS",
  payload: { data }
});

export const updateCategoryFailed = error=>({
  type: "UPDATE_CATEGORY_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar mercado" }
});

export const deleteCategory = id=>({
  type: "DELETE_CATEGORY",
  payload: { id }
});

export const deleteCategorySuccess = ()=>({
  type: "DELETE_CATEGORY_SUCCESS",
  payload: {}
});

export const deleteCategoryFailed = error=>({
  type: "DELETE_CATEGORY_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar mercado" }
});