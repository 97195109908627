export const invalidateCompares = item=>({
  type: "INVALIDATE_COMPARES",
  payload: { item }
});

export const getCompares = (offset, limit, filter = {})=>({
  type: "GET_COMPARES",
  payload: { offset, limit, where: filter }
});

export const getComparesSuccess = data=>({
  type: "GET_COMPARES_SUCCESS",
  payload: { data }
});

export const getComparesFailed = error=>({
  type: "GET_COMPARES_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter variáveis" }
});

export const getCompare = id=>({
  type: "GET_COMPARE",
  payload: { id }
});

export const getCompareSuccess = data=>({
  type: "GET_COMPARE_SUCCESS",
  payload: { data }
});

export const getCompareFailed = error=>({
  type: "GET_COMPARE_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter variável" }
});

export const createCompare = compare=>({
  type: "CREATE_COMPARE",
  payload: { compare }
});

export const createCompareSuccess = data=>({
  type: "CREATE_COMPARE_SUCCESS",
  payload: { data }
});

export const createCompareFailed = error=>({
  type: "CREATE_COMPARE_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar variável" }
});

export const updateCompare = (id, compare)=>({
  type: "UPDATE_COMPARE",
  payload: { id, compare }
});

export const updateCompareSuccess = data=>({
  type: "UPDATE_COMPARE_SUCCESS",
  payload: { data }
});

export const updateCompareFailed = error=>({
  type: "UPDATE_COMPARE_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar variável" }
});

export const deleteCompare = id=>({
  type: "DELETE_COMPARE",
  payload: { id }
});

export const deleteCompareSuccess = ()=>({
  type: "DELETE_COMPARE_SUCCESS",
  payload: {}
});

export const deleteCompareFailed = error=>({
  type: "DELETE_COMPARE_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar variável" }
});