import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
    getProductValuesFailed,
    getProductValuesSuccess,
    createProductValueSuccess,
    createProductValueFailed,
    deleteProductValueSuccess,
    deleteProductValueFailed,
    getProductValueSuccess,
    getProductValueFailed,
    updateProductValueSuccess,
    updateProductValueFailed,
} from './actions';

const ProductValue = new Resource('ProductValue', 'productValues')


// SAGAS
function* fetchProductValues({ payload }) {
    const { offset, limit, where } = payload;
    try {
        const include = [
            { model: 'Unit', required: true },
        ]
        const data = yield call(ProductValue.get, { params: { offset, limit, where, include } });
        yield put(getProductValuesSuccess(data));
    } catch (error) {
        console.error("Error in saga", error)
        yield put(getProductValuesFailed({ message: error.message }));
    }
}

function* createProductValue({ payload }) {
    const { productValue } = payload
    try {
        yield call(ProductValue.create, { payload: productValue })
        yield put(createProductValueSuccess())
    } catch (error) {
        console.error("Error in saga", error)
        yield put(createProductValueFailed(error))
    }
}

function* deleteProductValue({ payload }) {
    const { id } = payload
    try {
        yield call(ProductValue.delete, { id })
        yield put(deleteProductValueSuccess())
    } catch (error) {
        console.error("Error in saga", error)
        yield put(deleteProductValueFailed(error))
    }
}

function* getProductValue({ payload }) {
    const { id } = payload
    try {
        const include = [
            { model: 'Unit', required: true },
        ]
        const data = yield call(ProductValue.getOne, { id, include })
        yield put(getProductValueSuccess(data))
    } catch (error) {
        console.error("Error in saga", error)
        yield put(getProductValueFailed(error))
    }
}

function* updateProductValue({ payload }) {
    const { id, productValue } = payload
    try {
        const data = yield call(ProductValue.update, { id, payload: productValue })
        yield put(updateProductValueSuccess(data))
    } catch (error) {
        console.error("Error in saga", error)
        yield put(updateProductValueFailed(error))
    }
}


// WATCH FOR ACTIONS
export function* watchFetchProductValues() {
    yield takeEvery("GET_PRODUCT_VALUES", fetchProductValues);
}

export function* watchGetProductValue() {
    yield takeEvery("GET_PRODUCT_VALUE", getProductValue);
}

export function* watchCreateProductValue() {
    yield takeEvery("CREATE_PRODUCT_VALUE", createProductValue);
}

export function* watchDeleteProductValue() {
    yield takeEvery("DELETE_PRODUCT_VALUE", deleteProductValue);
}

export function* watchUpdateProductValue() {
    yield takeEvery("UPDATE_PRODUCT_VALUE", updateProductValue);
}

// COMBINED SAGAS
export default function* rootSaga() {
    yield all([
        fork(watchFetchProductValues),
        fork(watchGetProductValue),
        fork(watchCreateProductValue),
        fork(watchDeleteProductValue),
        fork(watchUpdateProductValue),
    ]);
}
