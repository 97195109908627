export const getMarkets = (offset, limit, filter = {}, params)=>({
  type: "GET_MARKETS",
  payload: { offset, limit, where: filter, params }
});

export const getMarketsSuccess = data=>({
  type: "GET_MARKETS_SUCCESS",
  payload: { data }
});

export const getMarketsFailed = error=>({
  type: "GET_MARKETS_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter mercados" }
});

export const getMarket = id=>({
  type: "GET_MARKET",
  payload: { id }
});

export const getMarketSuccess = data=>({
  type: "GET_MARKET_SUCCESS",
  payload: { data }
});

export const getMarketFailed = error=>({
  type: "GET_MARKET_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter mercado" }
});

export const createMarket = market=>({
  type: "CREATE_MARKET",
  payload: { market }
});

export const createMarketSuccess = ()=>({
  type: "CREATE_MARKET_SUCCESS",
  payload: {}
});

export const createMarketFailed = error=>({
  type: "CREATE_MARKET_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar mercado" }
});

export const updateMarket = (id, market)=>({
  type: "UPDATE_MARKET",
  payload: { id, market }
});

export const updateMarketSuccess = ()=>({
  type: "UPDATE_MARKET_SUCCESS",
  payload: {}
});

export const updateMarketFailed = error=>({
  type: "UPDATE_MARKET_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar mercado" }
});

export const deleteMarket = id=>({
  type: "DELETE_MARKET",
  payload: { id }
});

export const deleteMarketSuccess = ()=>({
  type: "DELETE_MARKET_SUCCESS",
  payload: {}
});

export const deleteMarketFailed = (error)=>({
  type: "DELETE_MARKET_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar mercado" }
});