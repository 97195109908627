import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { closeSnackbar } from '../../redux/actions';
import Snackbar from './Snackbar';

class AppSnackbar extends Component {
  state = {
    message: '',
    variant: null,
    isOpen: false
  };
  queue = [];
  timer = null;

  componentWillReceiveProps(props) {
    const { snackbar } = props;

    if (snackbar.isOpen) {
      this.open(snackbar);
    } else {
      clearTimeout(this.timer);
      this.setState({isOpen: false});
    }
  }

  open = snackbar=>{
    if (!snackbar) {
      return;
    }
    clearTimeout(this.timer);
    if (this.state.isOpen) {
      this.queue.push(snackbar);
      // this.queue.push(this.state);
      this.setState({isOpen: false});
    } else {
      const { autoHide, closeSnackbar } = this.props;
      this.setState(snackbar, ()=>{
        if (autoHide) {
          this.timer = setTimeout(closeSnackbar, autoHide);
        }
      });
    }
  };

  handleClose = (event, reason)=>{
    if (reason === 'clickaway') {
      return;
    }
    this.props.closeSnackbar();
  };

  handleExited = ()=>{
    this.open(this.queue.shift());
  };

  render() {
    return (
      <Snackbar
        open={this.state.isOpen}
        message={this.state.message}
        variant={this.state.variant}
        onClose={this.handleClose}
        onExited={this.handleExited}
      />
    )
  }
}

AppSnackbar.propTypes = {
  snackbar: PropTypes.object.isRequired,
  autoHide: PropTypes.number.isRequired,
  closeSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = ({ snackbar })=>{
  return { snackbar };
};

export default connect(mapStateToProps, { closeSnackbar })(AppSnackbar);