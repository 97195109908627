import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
  getUnitsFailed,
  getUnitsSuccess,
  createUnitSuccess,
  createUnitFailed,
  deleteUnitSuccess,
  deleteUnitFailed,
  getUnitSuccess,
  getUnitFailed,
  updateUnitSuccess,
  updateUnitFailed,
} from './actions';

const Unit = new Resource('Unit', 'units');


// SAGAS
function* fetchUnits({ payload }) {
  const { offset, limit, where, params } = payload;
  try {
    const data = yield call(Unit.get, { params: { offset, limit, where, ...params } });
    yield put(getUnitsSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getUnitsFailed({ message: error.message }));
  }
}

function* createUnit({ payload }) {
  const { unit } = payload;
  try {
    const data = yield call(Unit.create, { payload: unit });
    yield put(createUnitSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(createUnitFailed(error));
  }
}

function* deleteUnit({ payload }) {
  const { id } = payload;
  try {
      yield call(Unit.delete, { id });
      yield put(deleteUnitSuccess());
  } catch (error) {
      console.error("Error in saga", error);
      yield put(deleteUnitFailed(error));
  }
}

function* getUnit({ payload }) {
  const { id, params } = payload;
  try {
    const data = yield call(Unit.getOne, { id, params });
    yield put(getUnitSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getUnitFailed(error));
  }
}

function* updateUnit({ payload }) {
  const { id, unit } = payload;
  try {
    const data = yield call(Unit.update, { id, payload: unit });
    yield put(updateUnitSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(updateUnitFailed(error));
  }
}


// WATCH FOR ACTIONS
export function* watchFetchUnits() {
  yield takeEvery("GET_UNITS", fetchUnits);
}

export function* watchGetUnit() {
  yield takeEvery("GET_UNIT", getUnit);
}

export function* watchCreateUnit() {
  yield takeEvery("CREATE_UNIT", createUnit);
}

export function* watchDeleteUnit() {
  yield takeEvery("DELETE_UNIT", deleteUnit);
}

export function* watchUpdateUnit() {
  yield takeEvery("UPDATE_UNIT", updateUnit);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchUnits),
    fork(watchGetUnit),
    fork(watchCreateUnit),
    fork(watchDeleteUnit),
    fork(watchUpdateUnit),
    fork(watchUpdateUnit),
  ]);
}
