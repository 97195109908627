import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import FabButton from './FabButton';

export { FabButton };

const styles = theme=>({
  fabContainer: {
    height: 70
  },
  fabButtons: {
    display: 'flex',
    position: 'fixed',
    right: theme.spacing.unit*2,
    bottom: theme.spacing.unit*2,
  },
});

const FabToolbar = ({ children, classes })=>(
  <div className={classes.fabContainer}>
    <div className={classes.fabButtons}>
      {children}
    </div>
  </div>
);

FabToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FabToolbar);