import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
    getInputsFailed,
    getInputsSuccess,
    createInputSuccess,
    createInputFailed,
    deleteInputSuccess,
    deleteInputFailed,
    getInputSuccess,
    getInputFailed,
    updateInputSuccess,
    updateInputFailed,
} from './actions';

const Input = new Resource('Input', 'inputs')


// SAGAS
function* fetchInputs({ payload }) {
    const { offset, limit, where } = payload;
    try {
        const include = [
            { model: 'Letter', required: true },
        ]
        const data = yield call(Input.get, { params: { offset, limit, where, include } });
        yield put(getInputsSuccess(data));
    } catch (error) {
        console.error("Error in saga", error)
        yield put(getInputsFailed({ message: error.message }));
    }
}

function* createInput({ payload }) {
    const { input } = payload
    try {
        yield call(Input.create, { payload: input })
        yield put(createInputSuccess())
    } catch (error) {
        console.error("Error in saga", error)
        yield put(createInputFailed(error))
    }
}

function* deleteInput({ payload }) {
    const { id } = payload
    try {
        yield call(Input.delete, { id })
        yield put(deleteInputSuccess())
    } catch (error) {
        console.error("Error in saga", error)
        yield put(deleteInputFailed(error))
    }
}

function* getInput({ payload }) {
    const { id } = payload
    try {
        const include = [
            { model: 'Letter', required: true },
        ]
        const data = yield call(Input.getOne, { id, params: { include } })
        yield put(getInputSuccess(data))
    } catch (error) {
        console.error("Error in saga", error)
        yield put(getInputFailed(error))
    }
}

function* updateInput({ payload }) {
    const { id, input } = payload
    try {
        const data = yield call(Input.update, { id, payload: input })
        yield put(updateInputSuccess(data))
    } catch (error) {
        console.error("Error in saga", error)
        yield put(updateInputFailed(error))
    }
}


// WATCH FOR ACTIONS
export function* watchFetchInputs() {
    yield takeEvery("GET_INPUTS", fetchInputs);
}

export function* watchGetInput() {
    yield takeEvery("GET_INPUT", getInput);
}

export function* watchCreateInput() {
    yield takeEvery("CREATE_INPUT", createInput);
}

export function* watchDeleteInput() {
    yield takeEvery("DELETE_INPUT", deleteInput);
}

export function* watchUpdateInput() {
    yield takeEvery("UPDATE_INPUT", updateInput);
}

// COMBINED SAGAS
export default function* rootSaga() {
    yield all([
        fork(watchFetchInputs),
        fork(watchGetInput),
        fork(watchCreateInput),
        fork(watchDeleteInput),
        fork(watchUpdateInput),
    ]);
}
