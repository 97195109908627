export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";

export const showSnackbar = (variant, message)=>({
  type: SHOW_SNACKBAR,
  payload: { message, variant }
});

export const closeSnackbar = ()=>({
  type: CLOSE_SNACKBAR,
  payload: {}
});