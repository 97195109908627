export const getInputs = (offset, limit, filter = {})=>({
  type: "GET_INPUTS",
  payload: { offset, limit, where: filter }
});

export const getInputsSuccess = data=>({
  type: "GET_INPUTS_SUCCESS",
  payload: { data }
});

export const getInputsFailed = error=>({
  type: "GET_INPUTS_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter entradas" }
});

export const getInput = id=>({
  type: "GET_INPUT",
  payload: { id }
});

export const getInputSuccess = data=>({
  type: "GET_INPUT_SUCCESS",
  payload: { data }
});

export const getInputFailed = error=>({
  type: "GET_INPUT_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter entrada" }
});

export const createInput = input=>({
  type: "CREATE_INPUT",
  payload: { input }
});

export const createInputSuccess = ()=>({
  type: "CREATE_INPUT_SUCCESS",
  payload: {}
});

export const createInputFailed = error=>({
  type: "CREATE_INPUT_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar entrada" }
});

export const updateInput = (id, input)=>({
  type: "UPDATE_INPUT",
  payload: { id, input }
});

export const updateInputSuccess = ()=>({
  type: "UPDATE_INPUT_SUCCESS",
  payload: {}
});

export const updateInputFailed = error=>({
  type: "UPDATE_INPUT_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar entrada" }
});

export const deleteInput = id=>({
  type: "DELETE_INPUT",
  payload: { id }
});

export const deleteInputSuccess = ()=>({
  type: "DELETE_INPUT_SUCCESS",
  payload: {}
});

export const deleteInputFailed = error=>({
  type: "DELETE_INPUT_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar entrada" }
});