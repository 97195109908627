const INIT_STATE = {
  loading: false,
  error: false,
  errorMessage: null,
  data: null,
  action: null
};

const reducer = (state = INIT_STATE, action)=>{
  switch (action.type) {
    case 'RESET_ITEM':
      return { ...state, loading: false, error: false, errorMessage: null, action: null, data: null };

    case 'GET_ITEM':
      return { ...state, loading: true, error: false, errorMessage: null, action: null };

    case 'GET_ITEM_SUCCESS':
      return { ...state, loading: false, error: false, errorMessage: null, action: null, data: action.payload.data };

    case 'GET_ITEM_FAILED':
      return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

    case 'CREATE_ITEM':
      return { ...state, loading: true, error: false, errorMessage: null };

    case 'CREATE_ITEM_SUCCESS':
      return { ...state, loading: false, error: false, errorMessage: null, action: 'CREATED', data: action.payload.data };

    case 'CREATE_ITEM_FAILED':
      return { ...state, loading: false, error: true, errorMessage: action.payload.message };

    case 'UPDATE_ITEM':
      return { ...state, loading: true, error: false, errorMessage: null };

    case 'UPDATE_ITEM_SUCCESS':
      return { ...state, loading: false, error: false, errorMessage: null, action: 'UPDATED' };

    case 'UPDATE_ITEM_FAILED':
      return { ...state, loading: false, error: true, errorMessage: action.payload.message };

    case 'DELETE_ITEM':
      return { ...state, loading: true, error: false, errorMessage: null };

    case 'DELETE_ITEM_SUCCESS':
      return { ...state, loading: false, error: false, errorMessage: null, action: 'DELETED' };

    case 'DELETE_ITEM_FAILED':
      return { ...state, loading: false, error: true, errorMessage: action.payload.message };

    default:
      return state;
  }
};

export default reducer;