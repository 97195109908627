export const getViews = (offset, limit, filter = {})=>({
  type: "GET_VIEWS",
  payload: { offset, limit, where: filter }
});

export const getViewsSuccess = data=>({
  type: "GET_VIEWS_SUCCESS",
  payload: { data }
});

export const getViewsFailed = error=>({
  type: "GET_VIEWS_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter variáveis" }
});

export const getView = id=>({
  type: "GET_VIEW",
  payload: { id }
});

export const getViewSuccess = data=>({
  type: "GET_VIEW_SUCCESS",
  payload: { data }
});

export const getViewFailed = error=>({
  type: "GET_VIEW_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter variável" }
});

export const createView = view=>({
  type: "CREATE_VIEW",
  payload: { view }
});

export const createViewSuccess = ()=>({
  type: "CREATE_VIEW_SUCCESS",
  payload: {}
});

export const createViewFailed = error=>({
  type: "CREATE_VIEW_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar variável" }
});

export const updateView = (id, view)=>({
  type: "UPDATE_VIEW",
  payload: { id, view }
});

export const updateViewSuccess = ()=>({
  type: "UPDATE_VIEW_SUCCESS",
  payload: {}
});

export const updateViewFailed = error=>({
  type: "UPDATE_VIEW_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar variável" }
});

export const deleteView = id=>({
  type: "DELETE_VIEW",
  payload: { id }
});

export const deleteViewSuccess = ()=>({
  type: "DELETE_VIEW_SUCCESS",
  payload: {}
});

export const deleteViewFailed = error=>({
  type: "DELETE_VIEW_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar variável" }
});