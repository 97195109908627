export const getProductValues = (offset, limit, filter = {}, access_token) => ({
    type: "GET_PRODUCT_VALUES",
    payload: { offset, limit, where: filter, access_token }
});

export const getProductValuesSuccess = (data) => ({
    type: "GET_PRODUCT_VALUES_SUCCESS",
    payload: { data }
});

export const getProductValuesFailed = (error) => ({
    type: "GET_PRODUCT_VALUES_FAILED",
    payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter medidas" }
});

export const getProductValue = (id, access_token) => ({
    type: "GET_PRODUCT_VALUE",
    payload: { id, access_token }
});

export const getProductValueSuccess = (data) => ({
    type: "GET_PRODUCT_VALUE_SUCCESS",
    payload: { data }
});

export const getProductValueFailed = (error) => ({
    type: "GET_PRODUCT_VALUE_FAILED",
    payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter medida" }
});

export const createProductValue = (productValue, access_token) => ({
    type: "CREATE_PRODUCT_VALUE",
    payload: { productValue, access_token }
});

export const createProductValueSuccess = () => ({
    type: "CREATE_PRODUCT_VALUE_SUCCESS",
    payload: {}
});

export const createProductValueFailed = (error) => ({
    type: "CREATE_PRODUCT_VALUE_FAILED",
    payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar medida" }
});

export const updateProductValue = (id, productValue, access_token) => ({
    type: "UPDATE_PRODUCT_VALUE",
    payload: { id, productValue, access_token }
});

export const updateProductValueSuccess = () => ({
    type: "UPDATE_PRODUCT_VALUE_SUCCESS",
    payload: {}
});

export const updateProductValueFailed = (error) => ({
    type: "UPDATE_PRODUCT_VALUE_FAILED",
    payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar medida" }
});

export const deleteProductValue = (id, access_token) => ({
    type: "DELETE_PRODUCT_VALUE",
    payload: { id, access_token }
});

export const deleteProductValueSuccess = () => ({
    type: "DELETE_PRODUCT_VALUE_SUCCESS",
    payload: {}
});

export const deleteProductValueFailed = (error) => ({
    type: "DELETE_PRODUCT_VALUE_FAILED",
    payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar medida" }
});

export const setPageProductValues = (page) => ({
    type: "SET_PAGE_PRODUCT_VALUES",
    payload: { page }
});

export const setPerPageProductValues = (perPage) => ({
    type: "SET_PER_PAGE_PRODUCT_VALUES",
    payload: { perPage }
});

export const setSelectedProductValues = (selected) => ({
    type: "SET_SELECTED_PRODUCT_VALUES",
    payload: { selected }
});