import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
  getCompareVariablesFailed,
  getCompareVariablesSuccess,
  createCompareVariableSuccess,
  createCompareVariableFailed,
  deleteCompareVariableSuccess,
  deleteCompareVariableFailed,
  getCompareVariableSuccess,
  getCompareVariableFailed,
  updateCompareVariableSuccess,
  updateCompareVariableFailed,
} from './actions';

const Variable = new Resource('CompareVariable', 'compareVariables')


// SAGAS
function* fetchVariables({ payload }) {
  const { offset, limit, where, ...params } = payload;
  try {
    const data = yield call(Variable.get, { params: { offset, limit, where, ...params } });
    yield put(getCompareVariablesSuccess(data));
  } catch (error) {
    console.error("Error in saga", error)
    yield put(getCompareVariablesFailed({ message: error.message }));
  }
}

function* createVariable({ payload }) {
  const { variable } = payload
  try {
    const data = yield call(Variable.create, { payload: variable })
    yield put(createCompareVariableSuccess(data));
  } catch (error) {
    console.error("Error in saga", error)
    yield put(createCompareVariableFailed(error))
  }
}

function* deleteVariable({ payload }) {
  const { id } = payload
  try {
    yield call(Variable.delete, { id })
    yield put(deleteCompareVariableSuccess())
  } catch (error) {
    console.error("Error in saga", error)
    yield put(deleteCompareVariableFailed(error))
  }
}

function* getVariable({ payload }) {
  const { id, params } = payload
  try {
    const data = yield call(Variable.getOne, { id, params })
    yield put(getCompareVariableSuccess(data))
  } catch (error) {
    console.error("Error in saga", error)
    yield put(getCompareVariableFailed(error))
  }
}

function* updateVariable({ payload }) {
  const { id, variable } = payload
  try {
    const data = yield call(Variable.update, { id, payload: variable })
    yield put(updateCompareVariableSuccess(data))
  } catch (error) {
    console.error("Error in saga", error)
    yield put(updateCompareVariableFailed(error))
  }
}


// WATCH FOR ACTIONS
export function* watchFetchVariables() {
  yield takeEvery("GET_COMPARE_VARIABLES", fetchVariables);
}

export function* watchGetVariable() {
  yield takeEvery("GET_COMPARE_VARIABLE", getVariable);
}

export function* watchCreateVariable() {
  yield takeEvery("CREATE_COMPARE_VARIABLE", createVariable);
}

export function* watchDeleteVariable() {
  yield takeEvery("DELETE_COMPARE_VARIABLE", deleteVariable);
}

export function* watchUpdateVariable() {
  yield takeEvery("UPDATE_COMPARE_VARIABLE", updateVariable);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchVariables),
    fork(watchGetVariable),
    fork(watchCreateVariable),
    fork(watchDeleteVariable),
    fork(watchUpdateVariable),
  ]);
}
