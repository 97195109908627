export const getOrderVariables = (offset, limit, filter = {}, params)=>({
  type: "GET_ORDER_VARIABLES",
  payload: { offset, limit, where: filter, params }
});

export const getOrderVariablesSuccess = data=>({
  type: "GET_ORDER_VARIABLES_SUCCESS",
  payload: { data }
});

export const getOrderVariablesFailed = error=>({
  type: "GET_ORDER_VARIABLES_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter variáveis" }
});

export const getOrderVariable = id=>({
  type: "GET_ORDER_VARIABLE",
  payload: { id }
});

export const getOrderVariableSuccess = data=>({
  type: "GET_ORDER_VARIABLE_SUCCESS",
  payload: { data }
});

export const getOrderVariableFailed = error=>({
  type: "GET_ORDER_VARIABLE_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter variável" }
});