export const getPriceLogs = (offset, limit, filter = {}, params)=>({
  type: "GET_PRICE_LOGS",
  payload: { offset, limit, where: filter, params }
});

export const getPriceLogsSuccess = data=>({
  type: "GET_PRICE_LOGS_SUCCESS",
  payload: { data }
});

export const getPriceLogsFailed = error=>({
  type: "GET_PRICE_LOGS_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter produtos" }
});

export const getPriceLog = id=>({
  type: "GET_PRICE_LOG",
  payload: { id }
});

export const getPriceLogSuccess = data=>({
  type: "GET_PRICE_LOG_SUCCESS",
  payload: { data }
});

export const getPriceLogFailed = error=>({
  type: "GET_PRICE_LOG_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter produto" }
});

export const recalculatePrices = ({ name, filter, amount })=>({
  type: "RECALCULATE",
  payload: { name, filter, amount }
})

export const recalculatePricesSuccess = data=>({
  type: "RECALCULATE_SUCCESS",
  payload: { data }
})

export const recalculatePricesFailed = error=>({
  type: "RECALCULATE_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar preços" }
})

export const rebasePrices = id=>({
  type: "REBASE",
  payload: { id }
})

export const rebasePricesSuccess = data=>({
  type: "REBASE_SUCCESS",
  payload: { data }
})

export const rebasePricesFailed = error=>({
  type: "REBASE_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar resetar preços" }
})