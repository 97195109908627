import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
    getMeasuresFailed,
    getMeasuresSuccess,
    createMeasureSuccess,
    createMeasureFailed,
    deleteMeasureSuccess,
    deleteMeasureFailed,
    getMeasureSuccess,
    getMeasureFailed,
    updateMeasureSuccess,
    updateMeasureFailed,
} from './actions';

const Measure = new Resource('Measure', 'measures')


// SAGAS
function* fetchMeasures({ payload }) {
    const { offset, limit, where } = payload;
    try {
        const include = [
            { model: 'Unit', required: true },
            { model: 'Letter', required: true },
        ]
        const data = yield call(Measure.get, { params: { offset, limit, where, include } });
        yield put(getMeasuresSuccess(data));
    } catch (error) {
        console.error("Error in saga", error)
        yield put(getMeasuresFailed({ message: error.message }));
    }
}

function* createMeasure({ payload }) {
    const { measure } = payload
    try {
        yield call(Measure.create, { payload: measure })
        yield put(createMeasureSuccess())
    } catch (error) {
        console.error("Error in saga", error)
        yield put(createMeasureFailed(error))
    }
}

function* deleteMeasure({ payload }) {
    const { id } = payload
    try {
        yield call(Measure.delete, { id })
        yield put(deleteMeasureSuccess())
    } catch (error) {
        console.error("Error in saga", error)
        yield put(deleteMeasureFailed(error))
    }
}

function* getMeasure({ payload }) {
    const { id } = payload
    try {
        const include = [
            { model: 'Unit', required: true },
            { model: 'Letter', required: true },
        ]
        const data = yield call(Measure.getOne, { id, params: { include } })
        yield put(getMeasureSuccess(data))
    } catch (error) {
        console.error("Error in saga", error)
        yield put(getMeasureFailed(error))
    }
}

function* updateMeasure({ payload }) {
    const { id, measure } = payload
    try {
        const data = yield call(Measure.update, { id, payload: measure })
        yield put(updateMeasureSuccess(data))
    } catch (error) {
        console.error("Error in saga", error)
        yield put(updateMeasureFailed(error))
    }
}


// WATCH FOR ACTIONS
export function* watchFetchMeasures() {
    yield takeEvery("GET_MEASURES", fetchMeasures);
}

export function* watchGetMeasure() {
    yield takeEvery("GET_MEASURE", getMeasure);
}

export function* watchCreateMeasure() {
    yield takeEvery("CREATE_MEASURE", createMeasure);
}

export function* watchDeleteMeasure() {
    yield takeEvery("DELETE_MEASURE", deleteMeasure);
}

export function* watchUpdateMeasure() {
    yield takeEvery("UPDATE_MEASURE", updateMeasure);
}

// COMBINED SAGAS
export default function* rootSaga() {
    yield all([
        fork(watchFetchMeasures),
        fork(watchGetMeasure),
        fork(watchCreateMeasure),
        fork(watchDeleteMeasure),
        fork(watchUpdateMeasure),
    ]);
}
