export const getProductBillings = (offset, limit, filter = {}, params)=>({
  type: "GET_PRODUCT_BILLINGS",
  payload: { offset, limit, where: filter, params }
});

export const getProductBillingsSuccess = data=>({
  type: "GET_PRODUCT_BILLINGS_SUCCESS",
  payload: { data }
});

export const getProductBillingsFailed = error=>({
  type: "GET_PRODUCT_BILLINGS_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter medidas" }
});

export const getProductBilling = id=>({
  type: "GET_PRODUCT_BILLING",
  payload: { id }
});

export const getProductBillingSuccess = data=>({
  type: "GET_PRODUCT_BILLING_SUCCESS",
  payload: { data }
});

export const getProductBillingFailed = error=>({
  type: "GET_PRODUCT_BILLING_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter medida" }
});

export const createProductBilling = productBilling=>({
  type: "CREATE_PRODUCT_BILLING",
  payload: { productBilling }
});

export const createProductBillingSuccess = data=>({
  type: "CREATE_PRODUCT_BILLING_SUCCESS",
  payload: { data }
});

export const createProductBillingFailed = error=>({
  type: "CREATE_PRODUCT_BILLING_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar medida" }
});

export const updateProductBilling = (id, productBilling)=>({
  type: "UPDATE_PRODUCT_BILLING",
  payload: { id, productBilling }
});

export const updateProductBillingSuccess = data=>({
  type: "UPDATE_PRODUCT_BILLING_SUCCESS",
  payload: { data }
});

export const updateProductBillingFailed = error=>({
  type: "UPDATE_PRODUCT_BILLING_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar medida" }
});

export const deleteProductBilling = id=>({
  type: "DELETE_PRODUCT_BILLING",
  payload: { id }
});

export const deleteProductBillingSuccess = ()=>({
  type: "DELETE_PRODUCT_BILLING_SUCCESS",
  payload: {}
});

export const deleteProductBillingFailed = error=>({
  type: "DELETE_PRODUCT_BILLING_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar medida" }
});