import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';

import {
  getCategories,
  getProductCategories,
  deleteProductCategory,
  showSnackbar,
  invalidateListItem
} from '../../redux/actions';
import { connect } from 'react-redux';

import { get, map } from 'lodash';

import ProductValueList from './values';

const styles = theme=>({
  chipItem: {
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
  buttons: {
    marginTop: theme.spacing.unit*2,
  },
  muted: {
    color: theme.palette.grey[600]
  },
  mtl: {
    marginTop: theme.spacing.unit*3,
  },
});

class ProductCategoryList extends PureComponent {
  componentWillMount() {
    const { getCategories } = this.props;
    getCategories(0, null, null);
    this.fetchData();
  }

  componentWillReceiveProps(props) {
    const { showSnackbar, categories, productCategories, where, invalidateListItem } = props;

    if (where !== this.props.where) {
      this.fetchData(props);
    }
    if (!categories.loading) {
      if (categories.error) {
        showSnackbar('error', categories.errorMessage);
      }
    }
    if (!productCategories.loading) {
      if (productCategories.error) {
        showSnackbar('error', productCategories.errorMessage);
      } else if (productCategories.action) {
        switch (productCategories.action) {
          case 'CREATED':
            showSnackbar('success', `Categoria adicionada com sucesso`);
          break;
          case 'DELETED':
            showSnackbar('success', `Categoria removida com sucesso`);
          break;
          default:
            console.info('unknown action:', productCategories.action);
          break;
        }
        invalidateListItem();
        this.fetchData(props);
      }
    }
  }

  fetchData = (props = this.props)=>{
    const { getProductCategories, where } = props;
    if (where) {
      getProductCategories(0, null, where);
    }
  };

  handleRemoveProductCategory = productCategoryId=>event=>{
    const { deleteProductCategory } = this.props;
    deleteProductCategory(productCategoryId);
  };

  render() {
    const { classes, categories, productCategories, where, match, children } = this.props;
    const currentProductCategories = map(productCategories.list, 'categoryId');
    const categoryChoices = categories.list.filter(b=>!currentProductCategories.includes(b.id));

    if (!where) {
      return null;
    }

    return (
      <Fragment>
        {children}
        <Typography variant="subtitle1">Categorias</Typography>
        <Typography className={classes.muted}>Categorias que o produto pertence e suas unidade relacionadas, este produto pode ter um nome diferente para cada categoria</Typography>
        {
          productCategories.list
            .map(pc=>{
              const category = categories.list.find(b=>b.id===pc.categoryId);
              let label = get(category, 'name');
              if (pc.name) {
                label += `:${pc.name}`;
              }
              return (
                <Chip
                  key={pc.id}
                  component={Link}
                  to={`${match.url}/categorias/${pc.id}`}
                  clickable
                  label={label}
                  onDelete={this.handleRemoveProductCategory(pc.id)}
                  className={classes.chipItem}
                />
              )
            })
        }

        {!!categoryChoices.length&&(
          <div className={classes.buttons}>
            <Button
              component={Link}
              variant="outlined"
              to={`${match.url}/categorias/novo`}
              color="primary"
            >
              Adicionar categoria
            </Button>
          </div>
        )}

        <div className={classes.mtl}>
          <ProductValueList where={where} />
        </div>
      </Fragment>
    );
  }
};

ProductCategoryList.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  productCategories: PropTypes.object.isRequired,
  getCategories: PropTypes.func.isRequired,
  getProductCategories: PropTypes.func.isRequired,
  deleteProductCategory: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  invalidateListItem: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  where: PropTypes.object,
  children: PropTypes.node,
};

const mapStateToProps = ({ categories, productCategories })=>{
  return { categories, productCategories };
};

export default withStyles(styles)(connect(mapStateToProps, {
  getCategories,
  getProductCategories,
  deleteProductCategory,
  showSnackbar,
  invalidateListItem
})(ProductCategoryList));