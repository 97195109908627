import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
  getViewsFailed,
  getViewsSuccess,
  createViewSuccess,
  createViewFailed,
  deleteViewSuccess,
  deleteViewFailed,
  getViewSuccess,
  getViewFailed,
  updateViewSuccess,
  updateViewFailed,
} from './actions';

const View = new Resource('View', 'views')


// SAGAS
function* fetchViews({ payload }) {
  const { offset, limit, where } = payload;
  try {
    const data = yield call(View.get, { params: { offset, limit, where } });
    yield put(getViewsSuccess(data));
  } catch (error) {
    console.error("Error in saga", error)
    yield put(getViewsFailed({ message: error.message }));
  }
}

function* createView({ payload }) {
  const { view } = payload
  try {
    yield call(View.create, { payload: view })
    yield put(createViewSuccess())
  } catch (error) {
    console.error("Error in saga", error)
    yield put(createViewFailed(error))
  }
}

function* deleteView({ payload }) {
  const { id } = payload
  try {
    yield call(View.delete, { id })
    yield put(deleteViewSuccess())
  } catch (error) {
    console.error("Error in saga", error)
    yield put(deleteViewFailed(error))
  }
}

function* getView({ payload }) {
  const { id } = payload
  try {
    const data = yield call(View.getOne, { id })
    yield put(getViewSuccess(data))
  } catch (error) {
    console.error("Error in saga", error)
    yield put(getViewFailed(error))
  }
}

function* updateView({ payload }) {
  const { id, view } = payload
  try {
    const data = yield call(View.update, { id, payload: view })
    yield put(updateViewSuccess(data))
  } catch (error) {
    console.error("Error in saga", error)
    yield put(updateViewFailed(error))
  }
}


// WATCH FOR ACTIONS
export function* watchFetchViews() {
  yield takeEvery("GET_VIEWS", fetchViews);
}

export function* watchGetView() {
  yield takeEvery("GET_VIEW", getView);
}

export function* watchCreateView() {
  yield takeEvery("CREATE_VIEW", createView);
}

export function* watchDeleteView() {
  yield takeEvery("DELETE_VIEW", deleteView);
}

export function* watchUpdateView() {
  yield takeEvery("UPDATE_VIEW", updateView);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchViews),
    fork(watchGetView),
    fork(watchCreateView),
    fork(watchDeleteView),
    fork(watchUpdateView),
  ]);
}
