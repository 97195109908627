export const GET_ONE = 'GET_ONE';
export const GET_MANY = 'GET_MANY';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';

export const ACTION_METHOD = {
  GET_ONE: 'GET',
  GET_MANY: 'GET',
  CREATE: 'POST',
  UPDATE: 'PUT',
  DELETE: 'DELETE',
};