import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';

import { connect } from 'react-redux';

import { get, sortBy } from 'lodash';

const styles = theme=>({
  root: {
    marginBottom: theme.spacing.unit*3,
  },
  chipItem: {
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
  group: {
    '& + &': {
      marginTop: theme.spacing.unit*2,
    }
  },
  popper: {
    zIndex: theme.zIndex.tooltip,
    maxWidth: theme.breakpoints.values.md,
  },
  popperPaper: {
    padding: theme.spacing.unit*2,
    paddingTop: theme.spacing.unit,
  },
  letter: {
    marginRight: theme.spacing.unit,
  },
});

export const sortByLetter = item=>{
  const letter = get(item, 'Letter.letter');
  let value;
  if (typeof letter === 'string') {
    const parse = letter.match(/(\w?)(\d*)(\w?)(\d*)/);
    if (parse) {
      value = `${parse[2]||0}_${parse[1]}`;
      if (parse[3]) {
        value += `_${parse[4]||0}_${parse[3]}`;
      }
    }
  }
  return value;
};

class CategoryValues extends PureComponent {
  render() {
    const { classes, variables: { list }, inputs, measures } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.group}>
          <Chip className={classes.chipItem} label={
            <span>
              <strong className={classes.letter}>X</strong>
              Preço
            </span>
          } />
          <Chip className={classes.chipItem} label={
            <span>
              <strong className={classes.letter}>Y</strong>
              Frete
            </span>
          } />
          <Chip className={classes.chipItem} label={
            <span>
              <strong className={classes.letter}>W.A</strong>
              Margem de transferência
            </span>
          } />
          <Chip className={classes.chipItem} label={
            <span>
              <strong className={classes.letter}>W.B</strong>
              Margem do mercado
            </span>
          } />

          <Chip className={classes.chipItem} label={
            <span>
              <strong className={classes.letter}>Z.A</strong>
              ICMS fábrica
            </span>
          } />
          <Chip className={classes.chipItem} label={
            <span>
              <strong className={classes.letter}>Z.B</strong>
              ICMS entrega
            </span>
          } />
        </div>

        <div className={classes.group}>
          <Typography variant="overline">Medidas</Typography>

          {sortBy(measures.list, sortByLetter)
            .map(measure=>(
              <Chip key={measure.id} className={classes.chipItem} label={
                <span>
                  <strong className={classes.letter}>{get(measure, 'Letter.letter')}</strong>
                  {get(measure, 'Unit.name')}
                </span>
              } />
            ))
          }
        </div>
        <div className={classes.group}>
          <Typography variant="overline">Entradas</Typography>
          
          {sortBy(inputs.list, sortByLetter)
            .map(input=>(
              <Chip key={input.id} className={classes.chipItem} label={
                <span>
                  <strong className={classes.letter}>{get(input, 'Letter.letter')}</strong>
                  {get(input, 'name')}
                </span>
              } />
            ))
          }
        </div>

        <div className={classes.group}>
          <Typography variant="overline">Cálculos</Typography>
          {sortBy(list, sortByLetter)
              .map(variable=>{
                return (
                  <Tooltip title={variable.formula} placement="top">
                    <Chip
                      key={variable.id}
                      className={classes.chipItem}
                      variant={variable.variableId?'outlined':'default'}
                      color={variable.checklist?'secondary':'default'}
                      label={
                        <span>
                          <strong className={classes.letter}>{get(variable, 'Letter.letter')}</strong>
                          {get(variable, 'name')}
                        </span>
                      }
                    />
                  </Tooltip>
                );
              })
          }
        </div>
      </div>
    );
  }
}

CategoryValues.propTypes = {
  classes: PropTypes.object.isRequired,
  variables: PropTypes.object.isRequired,
  inputs: PropTypes.object.isRequired,
  measures: PropTypes.object.isRequired,
};

const mapStateToProps = ({ variables, inputs, measures })=>{
  return { variables, inputs, measures };
};

export default withStyles(styles)(connect(mapStateToProps)(CategoryValues));