import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';

import {
  getUnits,
  getMeasures,
  createMeasure,
  deleteMeasure,
  invalidateListItem,
  showSnackbar
} from '../../redux/actions';
import { connect } from 'react-redux';

import { get, map, sortBy } from 'lodash';

import { sortByLetter } from './values';

const styles = theme=>({
  chipItem: {
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
  buttons: {
    marginTop: theme.spacing.unit*2,
  },
  muted: {
    color: theme.palette.grey[600]
  },
});

class MeasureList extends PureComponent {
  state = {
    anchorEl: null,
  };

  componentWillMount() {
    const { getUnits } = this.props;
    getUnits(0, null, null, { order: [['name', 'ASC']] });
    this.fetchData();
  }

  componentWillReceiveProps(props) {
    const { showSnackbar, units, measures, where, invalidateListItem } = props;

    if (where !== this.props.where) {
      this.fetchData(props);
    }
    if (!units.loading) {
      if (units.error) {
        showSnackbar('error', units.errorMessage);
      }
    }
    if (!measures.loading) {
      if (measures.error) {
        showSnackbar('error', measures.errorMessage);
      } else if (measures.action) {
        switch (measures.action) {
          case 'CREATED':
            showSnackbar('success', `Unidade adicionada com sucesso`);
          break;
          case 'DELETED':
            showSnackbar('success', `Unidade removida com sucesso`);
          break;
          default:
            console.info('unknown action:', measures.action);
          break;
        }
        invalidateListItem();
        this.fetchData(props);
      }
    }
  }

  fetchData = (props = this.props)=>{
    const { getMeasures, where } = props;
    if (where) {
      getMeasures(0, null, where);
    }
  };

  handleAddMeasure = unitId=>event=>{
    this.setState({ anchorEl: null });
    const { createMeasure, where } = this.props;
    createMeasure({ ...where, unitId });
  };

  handleRemoveMeasure = measureId=>event=>{
    const { deleteMeasure } = this.props;
    deleteMeasure(measureId);
  };

  handleClick = event=>{
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = ()=>{
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, units, measures, where, children } = this.props;
    const { anchorEl } = this.state;
    const currentMeasures = map(measures.list, 'unitId');
    const unitChoices = units.list.filter(b=>!currentMeasures.includes(b.id));

    if (!where) {
      return null;
    }

    return (
      <Fragment>
        {children}
        <Typography variant="subtitle1">Unidades de medida</Typography>
        <Typography className={classes.muted}>Valores inseridos nos produtos com essa categoria</Typography>
        {
          sortBy(measures.list, sortByLetter)
            .map(measure=>{
              const unit = units.list.find(b=>b.id===measure.unitId);
              const unitName = get(unit, 'name') || '';
              const letter = get(measure, 'Letter.letter') || '';
              return (
                <Chip
                  key={measure.id}
                  color="secondary"
                  label={unitName}
                  avatar={<Avatar>{letter}</Avatar>}
                  onDelete={this.handleRemoveMeasure(measure.id)}
                  className={classes.chipItem}
                />
              )
            })
        }

        {!!unitChoices.length&&(
          <div className={classes.buttons}>
            <Button
              disabled={measures.loading}
              color="secondary"
              variant="outlined"
              aria-owns={anchorEl ? 'add-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleClick}
            >
              Adicionar unidade de medida
            </Button>
          </div>
        )}

        <Menu
          id="add-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {
            unitChoices
              .map(({ id, name })=>(
                <MenuItem key={id} onClick={this.handleAddMeasure(id)}>{name}</MenuItem>
              ))
          }
        </Menu>
      </Fragment>
    );
  }
};

MeasureList.propTypes = {
  classes: PropTypes.object.isRequired,
  units: PropTypes.object.isRequired,
  measures: PropTypes.object.isRequired,
  getUnits: PropTypes.func.isRequired,
  getMeasures: PropTypes.func.isRequired,
  createMeasure: PropTypes.func.isRequired,
  deleteMeasure: PropTypes.func.isRequired,
  invalidateListItem: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  where: PropTypes.object,
  children: PropTypes.node,
};

const mapStateToProps = ({ units, measures })=>{
  return { units, measures };
};

export default withStyles(styles)(connect(mapStateToProps, {
  getUnits,
  getMeasures,
  createMeasure,
  deleteMeasure,
  invalidateListItem,
  showSnackbar
})(MeasureList));