import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { map, get, sortBy, find, uniqueId } from 'lodash';
import moment from 'moment';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';

import { orderStatuses } from '../../components/OrderStatus';

const styles = theme=>({
  root: {
    marginTop: theme.spacing.unit*3,
  },
});

const required = ['finished', 'confirmed'];

class OrderStatusesView extends PureComponent {
  render() {
    const { classes, order } = this.props;
    const statuses = sortBy(get(order, 'OrderStatuses'), 'id');

    if (!order || !statuses.length) {
      return null;
    }

    let activeStep = 0;

    if (!find(statuses, { status: 'canceled' })) {
      for (let status of required) {
        if (!find(statuses, { status })) {
          activeStep++;
          statuses.push({
            id: uniqueId('status_'),
            status
          });
        }
      }
    }

    return (
      <Stepper className={classes.root} activeStep={activeStep} orientation="vertical">
        {map(statuses.reverse(), ({ status, id, createdAt, Reason })=>{
          let info = [];
          const reason = get(Reason, 'name');
          if (createdAt) {
            info.push(moment(createdAt).format('DD/MM/YYYY HH:mm'));
          }
          if (reason) {
            info.push(reason);
          }
          info = info.join(' - ');
          const legend = info ? <Typography variant="caption">{info}</Typography> : '';
          return (
            <Step key={id} completed={!!createdAt}>
              <StepLabel optional={legend}>{orderStatuses[status]}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    );
  }
};

OrderStatusesView.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object,
};

export default withStyles(styles)(OrderStatusesView);