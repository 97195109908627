export const getProductCategories = (offset, limit, filter = {})=>({
  type: "GET_PRODUCT_CATEGORIES",
  payload: { offset, limit, where: filter }
});

export const getProductCategoriesSuccess = data=>({
  type: "GET_PRODUCT_CATEGORIES_SUCCESS",
  payload: { data }
});

export const getProductCategoriesFailed = error=>({
  type: "GET_PRODUCT_CATEGORIES_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter medidas" }
});

export const getProductCategory = id=>({
  type: "GET_PRODUCT_CATEGORY",
  payload: { id }
});

export const getProductCategorySuccess = data=>({
  type: "GET_PRODUCT_CATEGORY_SUCCESS",
  payload: { data }
});

export const getProductCategoryFailed = error=>({
  type: "GET_PRODUCT_CATEGORY_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter medida" }
});

export const createProductCategory = productCategory=>({
  type: "CREATE_PRODUCT_CATEGORY",
  payload: { productCategory }
});

export const createProductCategorySuccess = data=>({
  type: "CREATE_PRODUCT_CATEGORY_SUCCESS",
  payload: { data }
});

export const createProductCategoryFailed = error=>({
  type: "CREATE_PRODUCT_CATEGORY_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar medida" }
});

export const updateProductCategory = (id, productCategory)=>({
  type: "UPDATE_PRODUCT_CATEGORY",
  payload: { id, productCategory }
});

export const updateProductCategorySuccess = data=>({
  type: "UPDATE_PRODUCT_CATEGORY_SUCCESS",
  payload: { data }
});

export const updateProductCategoryFailed = error=>({
  type: "UPDATE_PRODUCT_CATEGORY_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar medida" }
});

export const deleteProductCategory = id=>({
  type: "DELETE_PRODUCT_CATEGORY",
  payload: { id }
});

export const deleteProductCategorySuccess = ()=>({
  type: "DELETE_PRODUCT_CATEGORY_SUCCESS",
  payload: {}
});

export const deleteProductCategoryFailed = error=>({
  type: "DELETE_PRODUCT_CATEGORY_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar medida" }
});