import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default class UserForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.parseData(props.data);
  }

  componentWillReceiveProps({ data }) {
    if (data !== this.props.data) {
      this.setState(this.parseData(data));
    }
  }

  parseData = data=>{
    const {
      email = '',
      Profile,
      firstName = '',
      lastName = '',
      isAdmin = false,
      active = false,
      restricted = false
    } = data || {};
    
    return {
      email,
      firstName: (Profile && Profile.firstName) || firstName,
      lastName: (Profile && Profile.lastName) || lastName,
      password: '',
      isAdmin,
      restricted,
      active
    };
  };

  handleChange = event=>{
    const { name, value, checked, type } = event.target;
    this.setState({
      [name]: type === 'checkbox' ? checked : value
    });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { onSubmit } = this.props;
    onSubmit(this.state);
  }

  render() {
    const { classes, loading } = this.props;
    const { email, firstName, lastName, password, isAdmin, active, restricted } = this.state;

    return (
      <form autoComplete="off" onSubmit={this.handleSubmit}>
        <Grid container spacing={16}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              label="Email"
              type="email"
              name="email"
              value={email}
              onChange={this.handleChange}
              disabled={loading}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              label="Senha"
              type="password"
              name="password"
              value={password}
              onChange={this.handleChange}
              disabled={loading}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              label="Nome"
              name="firstName"
              value={firstName}
              onChange={this.handleChange}
              disabled={loading}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              label="Sobrenome"
              name="lastName"
              value={lastName}
              onChange={this.handleChange}
              disabled={loading}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={isAdmin}
                  name="isAdmin"
                  onChange={this.handleChange}
                  disabled={loading}
                />
              }
              label="Administrador"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={restricted}
                  name="restricted"
                  onChange={this.handleChange}
                  disabled={loading}
                />
              }
              label="Acesso restrito"
              helperText="Usuário com acesso restrito acessa somente os orçamentos no admin"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={active}
                  name="active"
                  onChange={this.handleChange}
                  disabled={loading}
                />
              }
              label="Ativo"
            />
          </Grid>
        </Grid>
        <div className={classes.buttons}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
          >
            Salvar
          </Button>
        </div>
      </form>
    );
  }
};