import React from 'react';
import { get } from 'lodash';

import Datagrid, { DatagridCell, EditButton, RemoveButton } from '../../components/Datagrid';
import { FabButton } from '../../components/FabToolbar';

import BillingForm from './form';

const BillingList = props=>(
  <Datagrid
    title="Locais de faturamento"
    name="Local de faturamento"
    resource="billings"
    form={BillingForm}
  >
    <FabButton type="add" fab />
    <DatagridCell prop="id" label="Id" />
    <DatagridCell prop="name" label="Nome" />
    <DatagridCell numeric>
      <EditButton />
      <RemoveButton
        name={i=>`${get(i, 'name')}`}
      />
    </DatagridCell>
  </Datagrid>
);

export default BillingList;