import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';

import { createCompareVariable, updateCompareVariable, getCompareVariable } from '../../redux/actions';
import { connect } from 'react-redux';

import CategoryValues from './values';

import { get, map, find, filter } from 'lodash';

const styles = theme=>({
  buttons: {
    marginTop: theme.spacing.unit*3,
  },
  divider: {
    marginTop: theme.spacing.unit*3,
    marginBottom: theme.spacing.unit*3
  },
});

class CompareVariableForm extends Component {
  state = {
    variableId: '',
    formula: ''
  };
  where = null;

  constructor(props) {
    super(props);
    this.id = this.props.match.params.compareVariableId;
  }

  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps({ compareVariables }) {
    const { loading, error, item } = compareVariables;
    if (!loading && !error && item && this.id !== 'novo') {
      this.setState({ variableId: item.variableId, formula: item.formula });
    }
  }

  fetchData = ()=>{
    if (this.id !== 'novo') {
      this.props.getCompareVariable(this.id);
    }
  };

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { createCompareVariable, updateCompareVariable, match } = this.props;
    const { variableId, formula } = this.state;
    if (this.id !== 'novo') {
      updateCompareVariable(this.id, { variableId, formula });
    } else {
      createCompareVariable({
        variableId,
        formula,
        categoryId: parseInt(match.params.id, 10),
        compareId: parseInt(match.params.compareId, 10)
      });
    }
  };

  render() {
    const { classes, compareVariables: { loading, list }, variables: { item } } = this.props;
    const { variableId, formula } = this.state;

    const options = filter(get(item, 'Variables'), v=>v.id === variableId || !find(list, {variableId: v.id}));

    return (
      <div className={classes.root}>
        <Typography variant="h4" component="h3" gutterBottom>
          {this.id !== 'novo' ? 'Editar' : 'Adicionar' } Variável
        </Typography>
        <CategoryValues />
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Variável"
                name="variableId"
                value={variableId}
                onChange={this.handleChange}
                select
                fullWidth
              >
                {map(options, va=>(
                  <MenuItem key={va.id} value={va.id}>{get(va, 'Letter.letter')}:{va.name}</MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} md={8} lg={6} xl={4}>
              <TextField
                label="Fórmula"
                name="formula"
                value={formula}
                onChange={this.handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              type="submit"
            >
              Salvar
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

CompareVariableForm.propTypes = {
  classes: PropTypes.object.isRequired,
  compareVariables: PropTypes.object.isRequired,
  variables: PropTypes.object.isRequired,
  createCompareVariable: PropTypes.func.isRequired,
  updateCompareVariable: PropTypes.func.isRequired,
  getCompareVariable: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = ({ compareVariables, variables })=>{
  return { compareVariables, variables };
};

export default withStyles(styles)(connect(mapStateToProps, { createCompareVariable, updateCompareVariable, getCompareVariable })(CompareVariableForm));