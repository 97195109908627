import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Link, Route, Switch } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import red from '@material-ui/core/colors/red';

import SideNavigation from '../../components/SideNavigation';

import { getCompareVariables, deleteCompareVariable, invalidateCompares, showSnackbar } from '../../redux/actions';
import { connect } from 'react-redux';

import CategoryValues, { sortByLetter } from './values';
import CompareVariableForm from './compareVariable';

import SafeRemove from '../../components/SafeRemove';

import { get, sortBy, find } from 'lodash';

const styles = theme=>({
  red: {
    color: red[600],
  },
  buttons: {
    marginTop: theme.spacing.unit*3,
  },
  muted: {
    color: theme.palette.grey[600]
  },
});

class CompareVariableList extends PureComponent {
  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps(props) {
    const { showSnackbar, compareVariables, where, invalidateCompares } = props;
    const { loading, error, errorMessage, action } = compareVariables;

    if (where !== this.props.where) {
      this.fetchData(props);
    }
    if (!loading) {
      if (error) {
        showSnackbar('error', errorMessage);
      } else if (action) {
        switch (action) {
          case 'CREATED':
            showSnackbar('success', `Variável adicionada com sucesso`);
            this.handleClose();
          break;
          case 'UPDATED':
            showSnackbar('success', `Variável atualizada com sucesso`);
            this.handleClose();
          break;
          case 'DELETED':
            showSnackbar('success', `Variável removida com sucesso`);
          break;
          default:
            console.info('unknown action:', action);
          break;
        }
        invalidateCompares();
        this.fetchData(props);
      }
    }
  }

  fetchData = (props = this.props)=>{
    const { getCompareVariables, where } = props;
    if (where) {
      getCompareVariables(0, null, where);
    }
  };

  handleRemoveCompareVariable = variableId=>event=>{
    event.preventDefault();
    const { deleteCompareVariable } = this.props;
    deleteCompareVariable(variableId);
  };

  handleClose = ()=>{
    const { history, match } = this.props;
    history.push(match.url);
  };

  render() {
    const { classes, compareVariables: { list, loading }, variables, where, children, match } = this.props;

    if (!where) {
      return null;
    }

    return (
      <Fragment>
        {children}
        <CategoryValues />
        <Typography variant="subtitle1">Variáveis</Typography>
        <Typography className={classes.muted}>Valores para serem substituidos na fórumla padrão</Typography>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Fórmula</TableCell>
              <TableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              sortBy(list, sortByLetter)
                .map(cv=>{
                  const variable = find(variables.list, { id: cv.variableId });
                  return (
                    <TableRow key={cv.id} hover>
                      <TableCell component="th" scope="row">
                        <strong>{get(variable, 'Letter.letter')}</strong> = {get(variable, 'name')}
                      </TableCell>
                      <TableCell>{cv.formula}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          component={Link}
                          to={`${match.url}/variaveis/${cv.id}`}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <SafeRemove
                          loading={loading}
                          onRemove={this.handleRemoveCompareVariable(cv.id)}
                          message={`Remover variável ${cv.name}?`}
                        >
                          <IconButton>
                            <DeleteIcon className={classes.red} fontSize="small" />
                          </IconButton>
                        </SafeRemove>
                      </TableCell>
                    </TableRow>
                  );
                })
            }
          </TableBody>
        </Table>
        
        <div className={classes.buttons}>
          <Button
            component={Link}
            to={`${match.url}/variaveis/novo`}
            color="primary"
            type="submit"
            variant="outlined"
          >
            Adicionar variável
          </Button>
        </div>

        <SideNavigation open={!match.isExact} onClose={this.handleClose}>
          <Switch>
            <Route path={`${match.path}/variaveis/:compareVariableId`} component={CompareVariableForm} />
          </Switch>
        </SideNavigation>
      </Fragment>
    );
  }
};

CompareVariableList.propTypes = {
  classes: PropTypes.object.isRequired,
  compareVariables: PropTypes.object.isRequired,
  variables: PropTypes.object.isRequired,
  getCompareVariables: PropTypes.func.isRequired,
  deleteCompareVariable: PropTypes.func.isRequired,
  invalidateCompares: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  where: PropTypes.object,
  children: PropTypes.node,
};

const mapStateToProps = ({ compareVariables, variables })=>{
  return { compareVariables, variables };
};

export default withStyles(styles)(connect(mapStateToProps, { getCompareVariables, deleteCompareVariable, invalidateCompares, showSnackbar })(CompareVariableList));