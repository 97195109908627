import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import { invalidateListItem, getItem, updateItem, createItem, deleteItem, showSnackbar } from '../../redux/actions';
import { connect } from 'react-redux';

const styles = theme=>({
  root: {

  },
  buttons: {
    marginTop: theme.spacing.unit*3,
  },
  divider: {
    marginTop: theme.spacing.unit*3,
    marginBottom: theme.spacing.unit*3
  },
});

class DatagridItem extends Component {
  constructor(props) {
    super(props);
    this.id = this.props.match.params.id;
  }

  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps(props) {
    // console.log('received', props);
    const { datagridItem, showSnackbar, name, invalidateListItem, history, location } = props;
    const { loading, error, errorMessage, data, action } = datagridItem;
    if (!loading) {
      if (error && errorMessage !== this.props.datagridItem.errorMessage) {
        showSnackbar('error', errorMessage);
      } else if (action !== this.props.datagridItem.action) {
        switch (action) {
          case 'CREATED':
            showSnackbar('success', `${name} criado com sucesso`);
            const newPath = location.pathname.replace(/novo$/, data.id);
            this.id = data.id;
            history.replace(newPath);
          break;
          case 'UPDATED':
            showSnackbar('success', `${name} atualizado com sucesso`);
          break;
          case 'DELETED':
            showSnackbar('success', `${name} deletado com sucesso`);
          break;
          default:
            console.info('unknown action:', name, action);
          break;
        }
        invalidateListItem(data);
      }
    }
  }

  isValid(value, type) {
    let valid;

    switch(type) {
      case 'email':
        valid = typeof value === 'string' && value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
      break;
      case 'password':
        valid = typeof value === 'string' && value && value.length>=6;
      break;
      default:
        valid = !!value;
      break;
    }

    return valid;
  }

  fetchData = ()=>{
    if (this.id !== 'novo') {
      const { getItem, params, model } = this.props;
      getItem(model, this.id, params);
    }
  };

  onSubmit = data=>{
    const { updateItem, createItem, model } = this.props;
    if (this.id !== 'novo') {
      updateItem(model, this.id, data);
    } else {
      createItem(model, data);
    }
  };

  render() {
    const { classes, name, match, history, location, component: Component, datagridItem: { data, loading }, readOnly } = this.props;

    return (
      <div className={classes.root}>
        <Typography variant="h4" component="h3" gutterBottom>
          {readOnly ? '' : (this.id !== 'novo' ? 'Editar ' : 'Criar ')}{name}
        </Typography>
        <Component
          match={match}
          history={history}
          location={location}
          data={data}
          loading={loading}
          classes={classes}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
};

DatagridItem.propTypes = {
  model: PropTypes.object.isRequired,
  component: PropTypes.any.isRequired,
  classes: PropTypes.object.isRequired,
  getItem: PropTypes.func.isRequired,
  updateItem: PropTypes.func.isRequired,
  createItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  invalidateListItem: PropTypes.func.isRequired,
  params: PropTypes.object,
  readOnly: PropTypes.bool,
};

const mapStateToProps = ({ datagridItem })=>{
  return { datagridItem };
};

export default withStyles(styles)(connect(mapStateToProps, { invalidateListItem, getItem, updateItem, createItem, deleteItem, showSnackbar })(DatagridItem));