import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Chip from '@material-ui/core/Chip';
import red from '@material-ui/core/colors/red';

import {
  createProductBilling,
  deleteProductBilling,
  showSnackbar
} from '../../redux/actions';
import { connect } from 'react-redux';

import { get, find, sortBy, remove } from 'lodash';

const styles = theme=>({
  chipItem: {
    marginRight: theme.spacing.unit,
    marginTop: theme.spacing.unit,
  },
  red: {
    backgroundColor: red[600],
    color: 'white'
  },
});

class ProductBillingList extends PureComponent {
  markForDelete = null;

  componentWillReceiveProps(props) {
    const { showSnackbar, productBillings, productFactories, productFactoryId } = props;
    const { loading, error, errorMessage, action, item } = productBillings;

    if (!loading) {
      if (error) {
        showSnackbar('error', errorMessage);
      } else if (action) {
        const productFactory = find(productFactories.list, { id: productFactoryId });
        const productBillings = get(productFactory, 'ProductFactoryBillings');

        switch (action) {
          case 'CREATED':
            productBillings.push(item);
            showSnackbar('success', `Local de faturamento adicionado com sucesso`);
          break;
          case 'DELETED':
            if (this.markForDelete) {
              remove(productBillings, {id: this.markForDelete});
              this.markForDelete = null;
            }
            showSnackbar('success', `Local de faturamento removido com sucesso`);
          break;
          default:
            console.info('unknown action:', action);
          break;
        }
      }
    }
  }

  handleCreateProductBilling = factoryBillingId=>event=>{
    const { createProductBilling, productFactoryId } = this.props;
    createProductBilling({ productFactoryId, factoryBillingId });
  };

  handleRemoveProductBilling = productBillingId=>event=>{
    this.markForDelete = productBillingId;
    this.props.deleteProductBilling(productBillingId);
  };

  render() {
    const { classes, productFactoryId, productFactories } = this.props;

    const productFactory = find(productFactories.list, { id: productFactoryId });
    const factoryBillings = get(productFactory, 'Factory.FactoryBillings');

    if (!get(factoryBillings, 'length')) {
      return null;
    }

    return (
      sortBy(factoryBillings, 'Billing.name')
        .map(fb=>{
          const pb = find(get(productFactory, 'ProductFactoryBillings'), { factoryBillingId: fb.id });
          const data = !!pb
            ? { onDelete: this.handleRemoveProductBilling(pb.id) }
            : { variant: 'outlined', clickable: true, onClick: this.handleCreateProductBilling(fb.id) };
          let label = `${get(fb, 'Billing.name')} (${get(fb, 'icms')}%)`;
          const margin = get(fb, 'margin');
          if (margin) {
            label += `:${margin}`;
          }
          return (
            <Chip
              key={fb.id}
              label={label}
              className={classes.chipItem}
              {...data}
            />
          );
        })
    );
  }
};

ProductBillingList.propTypes = {
  productFactories: PropTypes.object.isRequired,
  productBillings: PropTypes.object.isRequired,
  createProductBilling: PropTypes.func.isRequired,
  deleteProductBilling: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  productFactoryId: PropTypes.any,
};

const mapStateToProps = ({ productFactories, productBillings })=>{
  return { productFactories, productBillings };
};

export default withStyles(styles)(connect(mapStateToProps, {
  createProductBilling,
  deleteProductBilling,
  showSnackbar
})(ProductBillingList));