export const getFactories = (offset, limit, filter = {}, params)=>({
  type: "GET_FACTORIES",
  payload: { offset, limit, where: filter, params }
});

export const getFactoriesSuccess = data=>({
  type: "GET_FACTORIES_SUCCESS",
  payload: { data }
});

export const getFactoriesFailed = error=>({
  type: "GET_FACTORIES_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter fábricas" }
});

export const getFactory = id=>({
  type: "GET_FACTORY",
  payload: { id }
});

export const getFactorySuccess = data=>({
  type: "GET_FACTORY_SUCCESS",
  payload: { data }
});

export const getFactoryFailed = error=>({
  type: "GET_FACTORY_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter fábrica" }
});

export const createFactory = factory=>({
  type: "CREATE_FACTORY",
  payload: { factory }
});

export const createFactorySuccess = data=>({
  type: "CREATE_FACTORY_SUCCESS",
  payload: { data }
});

export const createFactoryFailed = error=>({
  type: "CREATE_FACTORY_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar fábrica" }
});

export const updateFactory = (id, factory)=>({
  type: "UPDATE_FACTORY",
  payload: { id, factory }
});

export const updateFactorySuccess = data=>({
  type: "UPDATE_FACTORY_SUCCESS",
  payload: { data }
});

export const updateFactoryFailed = error=>({
  type: "UPDATE_FACTORY_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar fábrica" }
});

export const deleteFactory = id=>({
  type: "DELETE_FACTORY",
  payload: { id }
});

export const deleteFactorySuccess = ()=>({
  type: "DELETE_FACTORY_SUCCESS",
  payload: {}
});

export const deleteFactoryFailed = error=>({
  type: "DELETE_FACTORY_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar fábrica" }
});