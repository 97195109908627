import React from 'react';
import { get } from 'lodash';
import { Link, withRouter } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import ShowIcon from '@material-ui/icons/Visibility';

const ShowButton = ({ item, match, size = 'small' })=>{
  if (!get(item, 'id')) {
    return null;
  }
  return (
    <IconButton component={Link} to={`${match.url}/${item.id}`}>
      <ShowIcon fontSize={size} />
    </IconButton>
  );
};

export default withRouter(ShowButton);