import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { Resource } from '../../resources/resource';
import {
  getProductBillingsFailed,
  getProductBillingsSuccess,
  createProductBillingSuccess,
  createProductBillingFailed,
  deleteProductBillingSuccess,
  deleteProductBillingFailed,
  getProductBillingSuccess,
  getProductBillingFailed,
  updateProductBillingSuccess,
  updateProductBillingFailed,
} from './actions';

const ProductBilling = new Resource('ProductBilling', 'productFactoryBillings')


// SAGAS
function* fetchProductBillings({ payload }) {
  const { offset, limit, where, params } = payload;
  try {
    const data = yield call(ProductBilling.get, { params: { offset, limit, where, ...params } });
    yield put(getProductBillingsSuccess(data));
  } catch (error) {
    console.error("Error in saga", error)
    yield put(getProductBillingsFailed({ message: error.message }));
  }
}

function* createProductBilling({ payload }) {
  const { productBilling } = payload;
  try {
    const data = yield call(ProductBilling.create, { payload: productBilling });
    yield put(createProductBillingSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(createProductBillingFailed(error));
  }
}

function* deleteProductBilling({ payload }) {
  const { id } = payload;
  try {
    yield call(ProductBilling.delete, { id });
    yield put(deleteProductBillingSuccess());
  } catch (error) {
    console.error("Error in saga", error);
    yield put(deleteProductBillingFailed(error));
  }
}

function* getProductBilling({ payload }) {
  const { id, params } = payload;
  try {
    const data = yield call(ProductBilling.getOne, { id, ...params });
    yield put(getProductBillingSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(getProductBillingFailed(error));
  }
}

function* updateProductBilling({ payload }) {
  const { id, productBilling } = payload;
  try {
    const data = yield call(ProductBilling.update, { id, payload: productBilling });
    yield put(updateProductBillingSuccess(data));
  } catch (error) {
    console.error("Error in saga", error);
    yield put(updateProductBillingFailed(error));
  }
}


// WATCH FOR ACTIONS
export function* watchFetchProductBillings() {
  yield takeEvery("GET_PRODUCT_BILLINGS", fetchProductBillings);
}

export function* watchGetProductBilling() {
  yield takeEvery("GET_PRODUCT_BILLING", getProductBilling);
}

export function* watchCreateProductBilling() {
  yield takeEvery("CREATE_PRODUCT_BILLING", createProductBilling);
}

export function* watchDeleteProductBilling() {
  yield takeEvery("DELETE_PRODUCT_BILLING", deleteProductBilling);
}

export function* watchUpdateProductBilling() {
  yield takeEvery("UPDATE_PRODUCT_BILLING", updateProductBilling);
}

// COMBINED SAGAS
export default function* rootSaga() {
  yield all([
    fork(watchFetchProductBillings),
    fork(watchGetProductBilling),
    fork(watchCreateProductBilling),
    fork(watchDeleteProductBilling),
    fork(watchUpdateProductBilling),
  ]);
}
