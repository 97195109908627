export const getUsers = (offset, limit, filter = {}, access_token)=>({
  type: "GET_USERS",
  payload: { offset, limit, where: filter, access_token }
});

export const getUsersSuccess = data=>({
  type: "GET_USERS_SUCCESS",
  payload: { data }
});

export const getUsersFailed = error=>({
  type: "GET_USERS_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter usuários" }
});

export const getUser = (id, access_token)=>({
  type: "GET_USER",
  payload: { id, access_token }
});

export const getUserSuccess = data=>({
  type: "GET_USER_SUCCESS",
  payload: { data }
});

export const getUserFailed = error=>({
  type: "GET_USER_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter usuário" }
});

export const getUserMe = access_token=>({
  type: "GET_USER_ME",
  payload: { access_token }
});

export const getUserMeSuccess = data=>({
  type: "GET_USER_ME_SUCCESS",
  payload: { data }
});

export const getUserMeFailed = error=>({
  type: "GET_USER_ME_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter usuário" }
});

export const createUser = (user, access_token)=>({
  type: "CREATE_USER",
  payload: { user, access_token }
});

export const createUserSuccess = ()=>({
  type: "CREATE_USER_SUCCESS",
  payload: {}
});

export const createUserFailed = error=>({
  type: "CREATE_USER_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar usuário" }
});

export const updateUser = (id, user, access_token)=>({
  type: "UPDATE_USER",
  payload: { id, user, access_token }
});

export const updateUserSuccess = ()=>({
  type: "UPDATE_USER_SUCCESS",
  payload: {}
});

export const updateUserFailed = error=>({
  type: "UPDATE_USER_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar usuário" }
});

export const updateUserMe = (user, access_token)=>({
  type: "UPDATE_USER_ME",
  payload: { user, access_token }
});

export const updateUserMeSuccess = ()=>({
  type: "UPDATE_USER_ME_SUCCESS",
  payload: {}
});

export const updateUserMeFailed = error=>({
  type: "UPDATE_USER_ME_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar usuário" }
});

export const deleteUser = (id, access_token)=>({
  type: "DELETE_USER",
  payload: { id, access_token }
});

export const deleteUserSuccess = ()=>({
  type: "DELETE_USER_SUCCESS",
  payload: {}
});

export const deleteUserFailed = error=>({
  type: "DELETE_USER_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar usuário" }
});