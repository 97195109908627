import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SwitchControl from '@material-ui/core/Switch';

import { connect } from 'react-redux';

import SideNavigation from '../../components/SideNavigation';

import MeasureList from './measures';
import InputList from './inputs';
import InputForm from './input';
import VariableList from './variables';
import VariableForm from './variable';
import ViewList from './views';
import ViewForm from './view';

class CategoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = this.parseData(props.data);
  }
  where = null;

  componentWillReceiveProps({ data, inputs: { action: ia }, views: { action: ra } }) {
    if (data !== this.props.data) {
      this.where = data ? { categoryId: data.id } : null;
      this.setState(this.parseData(data));
    }
    if (ia === 'CREATED' || ia === 'UPDATED' || ra === 'CREATED' || ra === 'UPDATED') {
      this.handleClose();
    }
  }

  parseData = data=>{
    const { name = '', noShipping = false } = data || {};
    return {
      name,
      noShipping
    };
  };

  handleChange = event=>{
    const { name, value, checked, type } = event.target;
    this.setState({
      [name]: type === 'checkbox' ? checked : value
    });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { onSubmit } = this.props;
    onSubmit(this.state);
  };

  handleClose = ()=>{
    const { history, match } = this.props;
    history.push(match.url);
  };

  render() {
    const { classes, loading, match, location, history } = this.props;
    const { name, noShipping } = this.state;

    return (
      <Fragment>
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Nome"
                name="name"
                value={name}
                onChange={this.handleChange}
                disabled={loading}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <FormControlLabel
                control={
                  <SwitchControl
                    checked={noShipping}
                    name="noShipping"
                    onChange={this.handleChange}
                    disabled={loading}
                  />
                }
                label="Somente FOB"
              />
            </Grid>
          </Grid>

          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              type="submit"
            >
              Salvar
            </Button>
          </div>
        </form>

        <MeasureList where={this.where}>
          <Divider className={classes.divider} />
        </MeasureList>

        <InputList where={this.where} match={match}>
          <Divider className={classes.divider} />
        </InputList>

        <VariableList where={this.where} match={match} history={history} location={location}>
          <Divider className={classes.divider} />
        </VariableList>

        <ViewList where={this.where} match={match}>
          <Divider className={classes.divider} />
        </ViewList>

        <SideNavigation open={!match.isExact} onClose={this.handleClose}>
          <Switch>
            <Route path={`${match.path}/entradas/:inputId`} component={InputForm} />
            <Route path={`${match.path}/resultados/:viewId`} component={ViewForm} />
            <Route path={`${match.path}/variaveis/:parentId/:variableId`} exact component={VariableForm} />
            <Route path={`${match.path}/variaveis/:variableId`} component={VariableForm} />
          </Switch>
        </SideNavigation>
      </Fragment>
    );
  }
};

const mapStateToProps = ({ inputs, views })=>{
  return { inputs, views };
};

export default connect(mapStateToProps)(CategoryForm);