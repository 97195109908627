import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import { getUserMe, updateUserMe, showSnackbar } from '../../redux/actions';
import { connect } from 'react-redux';

const styles = theme=>({
  paper: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit*2,
    paddingBottom: theme.spacing.unit*2,
    '& + &': {
      marginTop: theme.spacing.unit*3,
    }
  },
  buttons: {
    marginTop: theme.spacing.unit*4,
  },
});

class Profile extends Component {
  state = {
    email: '',
    password: '',
    currentPassword: '',
    passwordConfirmation: '',
    firstName: '',
    lastName: '',
  };


  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps(props) {
    const { showSnackbar } = this.props;
    const { loading, item, action, error, errorMessage } = props.users;
    if (!loading) {
      if (error) {
        showSnackbar('error', errorMessage);
      } else {
        if (action === 'UPDATED') {
          showSnackbar('success', `Usuário atualizado com sucesso`);
        }
        if (item) {
          const { email, Profile } = item;
          const { firstName, lastName } = Profile;
          this.setState({
            email,
            firstName,
            lastName
          });
        }
      }
    }
  }

  fetchData = ()=>{
    const { getUserMe } = this.props;
    getUserMe();
  };

  updateUser = event=>{
    event.preventDefault();
    const { updateUserMe } = this.props;
    const { email, firstName, lastName } = this.state;
    updateUserMe({ email, firstName, lastName });
  };

  updatePassword = event=>{
    event.preventDefault();
    const { updateUserMe, showSnackbar } = this.props;
    const { password, passwordConfirmation, currentPassword } = this.state;
    if (passwordConfirmation !== password) {
      return showSnackbar('error', `Os campos de senha e confirmação de senha não conferem.`);
    }
    updateUserMe({ password, currentPassword });
  };

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { classes, users } = this.props;
    const { loading } = users;
    const { email, firstName, lastName, password, currentPassword, passwordConfirmation } = this.state;

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <form
            className={classes.form}
            autoComplete="off"
            onSubmit={this.updateUser}
          >
            <Grid container spacing={16}>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <TextField
                  label="E-mail"
                  type="email"
                  name="email"
                  value={email}
                  onChange={this.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <TextField
                  label="Nome"
                  name="firstName"
                  value={firstName}
                  onChange={this.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <TextField
                  label="Sobrenome"
                  name="lastName"
                  value={lastName}
                  onChange={this.handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>

            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={loading}
              >
                Atualizar perfil
              </Button>
            </div>
          </form>
        </Paper>

        <Paper className={classes.paper}>
          <form
            className={classes.form}
            autoComplete="off"
            onSubmit={this.updatePassword}
          >
            <Grid container spacing={16}>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <TextField
                  label="Senha atual"
                  type="password"
                  name="currentPassword"
                  value={currentPassword}
                  onChange={this.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <TextField
                  label="Nova senha"
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.handleChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <TextField
                  label="Confirmar nova senha"
                  type="password"
                  name="passwordConfirmation"
                  value={passwordConfirmation}
                  onChange={this.handleChange}
                  fullWidth
                />
              </Grid>
            </Grid>

            <div className={classes.buttons}>
              <Button
                variant="contained"
                color="secondary"
                type="submit"
                disabled={loading}
              >
                Alterar senha
              </Button>
            </div>
          </form>
        </Paper>
      </div>
    );
  }
}

Profile.propTypes = {
  classes: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  getUserMe: PropTypes.func.isRequired,
  updateUserMe: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = ({ users })=>{
  return { users };
};

export default withStyles(styles)(connect(mapStateToProps, { getUserMe, updateUserMe, showSnackbar })(Profile));