import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MainNavigation from '../routes';
import AuthView from '../routes/auth';
import AppSnackbar from '../components/AppSnackbar';

import { checkSession } from '../redux/actions';

class App extends Component {
  constructor(props) {
    super(props);
    props.checkSession();
  }

  render() {
    const { currentUser, ready } = this.props;

    if (!ready) {
      return null;
    }

    return (
      <Fragment>
        {!!currentUser
          ? <MainNavigation />
          : <AuthView />
        }
        <AppSnackbar autoHide={6000} />
      </Fragment>
    );
  }
};

const mapStateToProps = ({ auth })=>{
  const { currentUser, ready } = auth;
  return { currentUser, ready };
};

App.propTypes = {
  checkSession: PropTypes.func.isRequired,
  ready: PropTypes.bool.isRequired,
  currentUser: PropTypes.object,
};

export default connect(mapStateToProps, { checkSession })(App);