const parseError = error=>(
  error.message || error.statusMessage || error.status || "Erro ao executar sua requisição"
);

export const invalidateListItem = item=>({
  type: "INVALIDATE_LIST_ITEM",
  payload: { item }
});

export const getListItem = (model, offset, limit, filter = {}, params)=>({
  type: "GET_LIST_ITEM",
  payload: { model, offset, limit, where: filter, params }
});

export const getListItemSuccess = data=>({
  type: "GET_LIST_ITEM_SUCCESS",
  payload: { data }
});

export const getListItemFailed = error=>({
  type: "GET_LIST_ITEM_FAILED",
  payload: { message: parseError(error) }
});

export const deleteListItem = (model, id)=>({
  type: "DELETE_LIST_ITEM",
  payload: { model, id }
});

export const deleteListItemSuccess = ()=>({
  type: "DELETE_LIST_ITEM_SUCCESS",
  payload: {}
});

export const deleteListItemFailed = error=>({
  type: "DELETE_LIST_ITEM_FAILED",
  payload: { message: parseError(error) }
});