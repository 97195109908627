const INIT_STATE = {
  loading: false,
  error: false,
  errorMessage: null,
  list: [],
  item: null,
  action: null
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case 'GET_ORDER_VARIABLES':
      return { ...state, loading: true, error: false, errorMessage: null, list: [], action: null };

    case 'GET_ORDER_VARIABLES_SUCCESS':
      return { ...state, loading: false, error: false, errorMessage: null, list: action.payload.data, action: null };

    case 'GET_ORDER_VARIABLES_FAILED':
      return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

    case 'GET_ORDER_VARIABLE':
      return { ...state, loading: true, error: false, action: null };

    case 'GET_ORDER_VARIABLE_SUCCESS':
      return { ...state, loading: false, error: false, item: action.payload.data, action: null };

    case 'GET_ORDER_VARIABLE_FAILED':
      return { ...state, loading: false, error: true, errorMessage: action.payload.message, action: null };

    default:
      return state;
  }
};

export default reducer;