import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import { createCompare, updateCompare, getCompare } from '../../redux/actions';
import { connect } from 'react-redux';

import { get } from 'lodash';

import CompareVariableList from './compareVariables';

const styles = theme=>({
  buttons: {
    marginTop: theme.spacing.unit*3,
  },
  divider: {
    marginTop: theme.spacing.unit*3,
    marginBottom: theme.spacing.unit*3
  },
});

class CompareForm extends Component {
  state = {
    name: '',
    factoryId: 0
  };
  where = null;

  constructor(props) {
    super(props);
    this.id = this.props.match.params.compareId;
  }

  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps({ compares }) {
    const { loading, error, item } = compares;
    if (!loading && !error && item && this.id !== 'novo') {
      if (item && get(this.where, 'compareId') !== item.id) {
        this.where = { compareId: item.id };
      } else if (!item) {
        this.where = null
      }
      this.setState({ name: item.name, factoryId: item.factoryId || 0, compareId: item.compareId });
    } if (item && item.id && item !== this.props.compares.item) {
      this.id = item.id;
      this.where = { compareId: item.id };
    }
  }

  fetchData = ()=>{
    if (this.id !== 'novo') {
      this.props.getCompare(this.id);
    }
  };

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { createCompare, updateCompare, match } = this.props;
    let { name, factoryId } = this.state;
    if (!factoryId) {
      factoryId = null;
    }
    const { parentId: compareId, variableId, id: categoryId } = match.params;
    if (this.id !== 'novo') {
      updateCompare(this.id, { name, factoryId });
    } else {
      createCompare({
        name,
        factoryId,
        compareId,
        categoryId,
        variableId
      });
    }
  };

  render() {
    const { classes, compares: { loading }, match, history, factories } = this.props;
    const { parentId } = match.params;
    const { name, factoryId, compareId } = this.state;

    return (
      <div className={classes.root}>
        <Typography variant="h4" component="h3" gutterBottom>
          {this.id !== 'novo' ? 'Editar' : 'Adicionar' } Comparação
        </Typography>
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Nome"
                name="name"
                value={name}
                onChange={this.handleChange}
                fullWidth
              />
            </Grid>
            {!parentId&&!compareId&&(
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                <TextField
                  label="Fábrica"
                  name="factoryId"
                  value={factoryId}
                  onChange={this.handleChange}
                  fullWidth
                  select
                >
                  <MenuItem value={0}>Todas</MenuItem>
                  {factories.list.map(fa=>(
                    <MenuItem value={fa.id} key={fa.id}>{fa.name}</MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </Grid>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              type="submit"
            >
              Salvar
            </Button>
          </div>
        </form>

        <CompareVariableList where={this.where} match={match} history={history}>
          <Divider className={classes.divider} />
        </CompareVariableList>
      </div>
    );
  }
}

CompareForm.propTypes = {
  classes: PropTypes.object.isRequired,
  compares: PropTypes.object.isRequired,
  factories: PropTypes.object.isRequired,
  createCompare: PropTypes.func.isRequired,
  updateCompare: PropTypes.func.isRequired,
  getCompare: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = ({ compares, factories })=>{
  return { compares, factories };
};

export default withStyles(styles)(connect(mapStateToProps, { createCompare, updateCompare, getCompare })(CompareForm));