import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import { createVariable, updateVariable, getVariable } from '../../redux/actions';
import { connect } from 'react-redux';

import { get } from 'lodash';

import CategoryValues from './values';
import CompareList from './compares';
import VariableList from './variables';

const styles = theme=>({
  buttons: {
    marginTop: theme.spacing.unit*3,
  },
  divider: {
    marginTop: theme.spacing.unit*3,
    marginBottom: theme.spacing.unit*3
  },
  button: {
    marginLeft: theme.spacing.unit*2,
  },
});

class VariableForm extends Component {
  state = {
    name: '',
    formula: '',
    position: '',
    checklist: false
  };
  where = null;

  constructor(props) {
    super(props);
    this.id = this.props.match.params.variableId;
  }

  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps({ variables, match, history }) {
    const { loading, error, item } = variables;
    const { parentId, variableId } = match.params;
    if (parentId !== this.props.match.params.parentId) {
      this.id = variableId;
      this.where = parentId ? null : { variableId };
      this.fetchData();
    } else if (!loading && !error && item && this.id !== 'novo') {
      if (item && get(this.where, 'variableId') !== item.id) {
        this.where = { variableId: item.id };
      } else if (!item) {
        this.where = null
      }
      this.setState({ name: item.name, position: item.position, formula: item.formula, checklist: item.checklist });
    } else if (item && item.id && item !== this.props.variables.item) {
      if (parentId) {
        history.goBack();
      } else {
        this.id = item.id;
        this.where = { variableId: item.id };
      }
    }
  }

  fetchData = ()=>{
    if (this.id !== 'novo') {
      this.props.getVariable(this.id);
    } else {
      this.setState({name: '', formula: '', position: '', checklist: false});
    }
  };

  handleChange = event=>{
    const { name, value, checked, type } = event.target;
    this.setState({
      [name]: type === 'checkbox' ? checked : value
    });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { createVariable, updateVariable, match } = this.props;
    const { id: categoryId, parentId: variableId } = match.params;
    const { name, formula, checklist, position } = this.state;
    if (this.id !== 'novo') {
      updateVariable(this.id, { name, formula, checklist, position });
    } else {
      createVariable({ name, formula, checklist, position, categoryId, variableId });
    }
  };

  handleCancel = event=>{
    const { history } = this.props;
    history.goBack();
  }

  render() {
    const { classes, variables: { loading }, match, history, location } = this.props;
    const { parentId } = match.params;
    const { name, formula, checklist, position } = this.state;

    const isNew = this.id === 'novo';

    return (
      <div className={classes.root}>
        <Typography variant="h4" component="h3" gutterBottom>
          {!isNew ? 'Editar' : 'Adicionar' } Variável
        </Typography>
        <CategoryValues />
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Nome"
                name="name"
                value={name}
                onChange={this.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Posição"
                name="position"
                value={position}
                onChange={this.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={8} lg={6} xl={4}>
              <TextField
                label="Fórmula"
                name="formula"
                value={formula}
                onChange={this.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={checklist}
                    name="checklist"
                    onChange={this.handleChange}
                    disabled={loading}
                  />
                }
                label="Checklist"
              />
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              type="submit"
            >
              Salvar
            </Button>

            <Button
              disabled={loading}
              onClick={this.handleCancel}
              className={classes.button}
            >
              Cancelar
            </Button>
          </div>
        </form>

        {!isNew&&!parentId&&(
          <VariableList match={match} history={history} location={location} variableId={parseInt(this.id, 10)}>
            <Divider className={classes.divider} />
          </VariableList>
        )}

        {!parentId&&(
          <CompareList where={this.where} match={match} history={history} location={location}>
            <Divider className={classes.divider} />
          </CompareList>
        )}
      </div>
    );
  }
}

VariableForm.propTypes = {
  classes: PropTypes.object.isRequired,
  variables: PropTypes.object.isRequired,
  createVariable: PropTypes.func.isRequired,
  updateVariable: PropTypes.func.isRequired,
  getVariable: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = ({ variables })=>{
  return { variables };
};

export default withStyles(styles)(connect(mapStateToProps, { createVariable, updateVariable, getVariable })(VariableForm));