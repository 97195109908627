import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { createView, updateView, getView } from '../../redux/actions';
import { connect } from 'react-redux';

import CategoryValues from './values';

const styles = theme=>({
  buttons: {
    marginTop: theme.spacing.unit*3,
  },
});

class ViewForm extends Component {
  state = {
    name: '',
    fob: '',
    cif: ''
  };

  constructor(props) {
    super(props);
    this.id = this.props.match.params.viewId;
  }

  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps({ views }) {
    const { loading, error, item } = views;
    if (!loading && !error && item && this.id !== 'novo') {
      this.setState({ name: item.name, fob: item.fob, cif: item.cif });
    }
  }

  fetchData = ()=>{
    if (this.id !== 'novo') {
      this.props.getView(this.id);
    }
  };

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { createView, updateView, match } = this.props;
    const { name, fob, cif } = this.state;
    if (this.id !== 'novo') {
      updateView(this.id, { name, fob, cif });
    } else {
      createView({ name, fob, cif, categoryId: match.params.id });
    }
  };

  render() {
    const { classes, views: { loading } } = this.props;
    const { name, fob, cif } = this.state;
    
    return (
      <div className={classes.root}>
        <Typography variant="h4" component="h3" gutterBottom>
          {this.id !== 'novo' ? 'Editar' : 'Adicionar' } Resultado
        </Typography>
        <CategoryValues />
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Nome"
                name="name"
                value={name}
                onChange={this.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={8} lg={6} xl={4}>
              <TextField
                label="Fórmula FOB"
                name="fob"
                value={fob}
                onChange={this.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={8} lg={6} xl={4}>
              <TextField
                label="Fórmula CIF"
                name="cif"
                value={cif}
                onChange={this.handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              type="submit"
            >
              Salvar
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

ViewForm.propTypes = {
  classes: PropTypes.object.isRequired,
  views: PropTypes.object.isRequired,
  createView: PropTypes.func.isRequired,
  updateView: PropTypes.func.isRequired,
  getView: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = ({ views })=>{
  return { views };
};

export default withStyles(styles)(connect(mapStateToProps, { createView, updateView, getView })(ViewForm));