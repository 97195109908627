import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import { createShipping, updateShipping, getShipping } from '../../redux/actions';
import { connect } from 'react-redux';

const styles = theme=>({
  buttons: {
    marginTop: theme.spacing.unit*3,
  },
});

class ShippingForm extends Component {
  state = {
    name: '',
    cost: ''
  };

  constructor(props) {
    super(props);
    this.id = this.props.match.params.shippingId;
  }

  componentWillMount() {
    this.fetchData();
  }

  componentWillReceiveProps({ shippings }) {
    const { loading, error, item } = shippings;
    if (!loading && !error && item && this.id !== 'novo') {
      this.setState({ name: item.name, cost: item.cost });
    }
  }

  fetchData = ()=>{
    if (this.id !== 'novo') {
      this.props.getShipping(this.id);
    }
  };

  handleChange = event=>{
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = event=>{
    event.preventDefault();
    const { createShipping, updateShipping, match } = this.props;
    const { name, cost } = this.state;
    if (this.id !== 'novo') {
      updateShipping(this.id, { name, cost });
    } else {
      createShipping({ name, cost, factoryId: match.params.id });
    }
  };

  render() {
    const { classes, shippings: { loading } } = this.props;
    const { name, cost } = this.state;
    
    return (
      <div className={classes.root}>
        <Typography variant="h4" component="h3" gutterBottom>
          {this.id !== 'novo' ? 'Editar' : 'Adicionar' } Frete
        </Typography>
        <form autoComplete="off" onSubmit={this.handleSubmit}>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Nome"
                name="name"
                value={name}
                onChange={this.handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
              <TextField
                label="Preço"
                value={cost}
                onChange={this.handleChange}
                name="cost"
                type="number"
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
          <div className={classes.buttons}>
            <Button
              variant="contained"
              color="primary"
              disabled={loading}
              type="submit"
            >
              Salvar
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

ShippingForm.propTypes = {
  classes: PropTypes.object.isRequired,
  shippings: PropTypes.object.isRequired,
  createShipping: PropTypes.func.isRequired,
  updateShipping: PropTypes.func.isRequired,
  getShipping: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

const mapStateToProps = ({ shippings })=>{
  return { shippings };
};

export default withStyles(styles)(connect(mapStateToProps, { createShipping, updateShipping, getShipping })(ShippingForm));