export const getShippings = (offset, limit, filter = {}, params)=>({
  type: "GET_SHIPPINGS",
  payload: { offset, limit, where: filter, params }
});

export const getShippingsSuccess = data=>({
  type: "GET_SHIPPINGS_SUCCESS",
  payload: { data }
});

export const getShippingsFailed = error=>({
  type: "GET_SHIPPINGS_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter entregas" }
});

export const getShipping = id=>({
  type: "GET_SHIPPING",
  payload: { id }
});

export const getShippingSuccess = data=>({
  type: "GET_SHIPPING_SUCCESS",
  payload: { data }
});

export const getShippingFailed = error=>({
  type: "GET_SHIPPING_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar obter entrega" }
});

export const createShipping = shipping=>({
  type: "CREATE_SHIPPING",
  payload: { shipping }
});

export const createShippingSuccess = ()=>({
  type: "CREATE_SHIPPING_SUCCESS",
  payload: {}
});

export const createShippingFailed = error=>({
  type: "CREATE_SHIPPING_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar criar entrega" }
});

export const updateShipping = (id, shipping)=>({
  type: "UPDATE_SHIPPING",
  payload: { id, shipping }
});

export const updateShippingSuccess = ()=>({
  type: "UPDATE_SHIPPING_SUCCESS",
  payload: {}
});

export const updateShippingFailed = error=>({
  type: "UPDATE_SHIPPING_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar atualizar entrega" }
});

export const deleteShipping = id=>({
  type: "DELETE_SHIPPING",
  payload: { id }
});

export const deleteShippingSuccess = ()=>({
  type: "DELETE_SHIPPING_SUCCESS",
  payload: {}
});

export const deleteShippingFailed = error=>({
  type: "DELETE_SHIPPING_FAILED",
  payload: { message: error.message || error.statusMessage || error.status || "Erro inesperado ao tentar deletar entrega" }
});